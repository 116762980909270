<template>
  <div class="error-message">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "errorMsg"
}
</script>

<style lang="scss" scoped>
.error-message{
  font-family: 'Manrope';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #EE4C4C;
  margin: 4px 0 0;
}
</style>