import historyService from "@/services/historyService";
import axios from "axios";
import store from "@/store/index";
import moment from "moment/moment";

export default {
    namespaced: true,
    state: {
        histories: [],
        messenger: "",
        uuid: "",
        projectId: "",
        hasMode: false,
        pageActive: 1,
        loaderActive: false,
        sortTypeDate: 'desc',
        filters: {
            attachmentType: [],
            status: [],
            destination: [],
            date: null,
            searchHistory: "",
            searchHistoryText: "",
        },
        numberOfRow: "",
        numberOfRows: [
            {id: 1, text: "5", value: "five"},
            {id: 2, text: "10", value: "ten"},
            {id: 3, text: "50", value: "fifty"},
            {id: 4, text: "100", value: "onehundred"},
        ],
    },

    mutations: {
        setMessenger(state, messenger) {
            state.messenger = messenger;
        },
        setHistories(state, histories) {
            state.histories = histories
        },
        setUuid(state, uuid) {
            state.uuid = uuid
        },
        setProjectId(state, projectId) {
            state.projectId = projectId
        },
        setHasMode(state, hasMode) {
            state.hasMode = hasMode
        },
        setPageActive(state, pageActive) {
            state.pageActive = pageActive
        },
        setPageActivePrev(state) {
            if (state.pageActive > 1) {
                state.pageActive = Number(state.pageActive)
                state.pageActive -= 1;
            }
        },
        setPageActiveNext(state) {
            if (state.hasMode) {
                state.pageActive = Number(state.pageActive)
                state.pageActive += 1;
            }
        },
        setLoaderActive(state, loaderActive) {
            state.loaderActive = loaderActive
        },
        setSortTypeDate(state) {
            if (state.sortTypeDate === "desc") {
                state.sortTypeDate = "asc";
            } else {
                state.sortTypeDate = "desc";
            }
        },
        setSortTypeDateDefault(state, sortTypeDate) {
            state.sortTypeDate = "desc";
        },
        setNumberOfRow(state, numberOfRow) {
            state.numberOfRow = numberOfRow;
        },
        setFilters(state, filters) {
            state.filters.date = filters.date
            state.filters.searchHistory = filters.searchHistory
            state.filters.searchHistoryText = filters.searchHistoryText

            if(state.filters.destination.length || filters.arrayDestination.length) state.filters.destination = filters.arrayDestination
            if(state.filters.status.length || filters.arrayStatuses.length) state.filters.status = filters.arrayStatuses
            if(state.filters.attachmentType.length || filters.arrayAttachmentsTypes.length)state.filters.attachmentType = filters.arrayAttachmentsTypes
        },
    },

    actions: {
        async fetchData({ commit, state }, { action, filterKey, additionalParams }) {
            if (typeof action !== 'function') {
                throw new Error('Invalid action provided in fetchData');
            }

            commit("setLoaderActive", true);

            const keysFilters = {
                searchHistory: `${filterKey}`,
                searchHistoryText: 'filter[search]',
                attachmentType: 'filter[type][]',
                status: 'filter[status][]',
                destination: 'filter[destination][]',
                date: 'filter[date]'
            }

            let filtersStr = ''
            for (const [key, value] of Object.entries(state.filters)) {
                if (key && value) {
                    if(key !== 'date'){
                        if (Array.isArray(value)) {
                            value.forEach(val => {
                                filtersStr += `&${keysFilters[key]}=${val.value}`;
                            });
                        } else {
                            const specialChars = ['!', '"', '$', "'", '(', ')', '-', '/', '<', '@', '\\', '^', '|', '~'];
                            const regex = new RegExp(`[${specialChars.join('\\')}]`, 'g');
                            const v = value.replace(regex, (match) => `\\${match}`);
                            filtersStr += `&${keysFilters[key]}=${v}`;
                        }
                    } else {

                        const dateObj = new Date(value);
                        const isoString = dateObj.toISOString();
                        const date = moment.utc(isoString).format('l LT');
                        filtersStr += `&${keysFilters.date}=${date}`
                    }
                }
            }

            if (!state.numberOfRow) {
                const data = localStorage.getItem('numberRow');

                if (data) {
                    commit("setNumberOfRow", data);
                } else {
                    commit("setNumberOfRow", "five");
                }
            }

            localStorage.setItem('numberRow', state.numberOfRow);

            const numberRows = state.numberOfRows.find(elem => elem.value === state.numberOfRow);

            if (state.pageActive && state.pageActive > 0 && additionalParams) {
                state.pageActive = Number(state.pageActive);
                await action(state.projectId, additionalParams, state.pageActive, Number(numberRows.text), state.sortTypeDate, filtersStr);
            } else {
                store.commit('history/setLoaderActive', false);
                store.commit('history/setHistories', []);
            }
        },

        async getHistoryFilters({ commit, state, dispatch }) {
            await dispatch('fetchData', { action: historyService.getHistoryFilters, filterKey: 'search_user', additionalParams: state.uuid });
        },

        async getLostHistory({ commit, state, dispatch }) {
            await dispatch('fetchData', { action: historyService.getLostHistory, filterKey: 'filter[phone]', additionalParams: state.messenger });
        },
    },
}