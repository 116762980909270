import Api from '@/api/index.js'
import store from "@/store";
import { requestHandler, basePath } from '@/utils/apiHelpers';

class TemplatesService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        //waba
        this.getTemplatesWaba = async function (projectId, uuid, queryStr) {
            return requestHandler(async () => {
                const response = await api.templates.getTemplatesWaba(projectId, uuid, queryStr);
                store.state.templates.waba.lastPage = response.last_page
                const data = {
                    template: 'waba',
                    templates: response.data
                }
                store.commit('templates/setTemplates', data);

                return response
            })
        }
        this.getTemplateWaba = async function (projectId, uuid, id) {
            return requestHandler(async () => {
                const response = await api.templates.getTemplateWaba(projectId, uuid, id);
                store.commit('templates/setTemplateWaba', response.data);
                return response.data
            })
        }
        this.deleteTemplateWaba = async function (projectId, uuid, id) {
            return requestHandler(async () => {
                await api.templates.deleteTemplateWaba(projectId, uuid, id);
            })
        }
        this.createTemplateWaba = async function (projectId, uuid, template, format) {
            return requestHandler(async () => {
                await api.templates.createTemplateWaba(projectId, uuid, template, format);
            })
        }
        this.editTemplateWaba = async function (projectId, uuid, id, title) {
            return requestHandler(async () => {
                await api.templates.editTemplateWaba(projectId, uuid, id, title);
            })
        }
        this.getTemplatesForImportWaba = async function (projectId, uuid) {
            return requestHandler(async () => {
                const response = await api.templates.getTemplatesForImportWaba(projectId, uuid);
                store.commit('templates/setTemplatesImportWaba', response.data);
            })
        }
        this.importTemplateWaba = async function (projectId, uuid, template) {
            return requestHandler(async () => {
                await api.templates.importTemplateWaba(projectId, uuid, template);
            })
        }
        this.getTemplatesWithType = async function (integration, type, queryStr) {
            return requestHandler(async () => {
                const response =  await api.templates.getTemplatesWithType(integration, type, queryStr);
                store.state.templates.yc.lastPage = response.last_page
                const data = {
                    template: 'yc',
                    templates: response.data
                }
                store.commit('templates/setTemplates', data);

                return response
            })
        }



        //menu
        this.getTemplatesMenu = async function (projectId, uuid, queryStr) {
            return requestHandler(async () => {
                const response = await api.templates.getTemplatesMenu(projectId, uuid, queryStr);
                store.state.templates.menu.lastPage = response.last_page
                const data = {
                    template: 'menu',
                    templates: response.data
                }
                store.commit('templates/setTemplates', data);
                return response.data;
            })
        }
        this.getTemplatesMenuTree = async function (projectId, uuid, parent_id) {
            return requestHandler(async () => {
                const response = await api.templates.getTemplatesMenuTree(projectId, uuid, parent_id);
                return response.data
            })
        }
        this.getTemplateMenu = async function (projectId, uuid, id) {
            return requestHandler(async () => {
                const {data} = await api.templates.getTemplateMenu(projectId, uuid, id);
                return data
            })
        }
        this.getFunctionsMenu = async function (projectId, uuid) {
            return requestHandler(async () => {
                const {data} = await api.templates.getFunctionsMenu(projectId, uuid);
                return data
            })
        }
        this.getParentsMenu = async function (projectId, uuid) {
            return requestHandler(async () => {
                const {data} = await api.templates.getParentsMenu(projectId, uuid);
                return data
            })
        }
        this.deleteTemplateMenu = async function (projectId, uuid, id) {
            return requestHandler(async () => {
                await api.templates.deleteTemplateMenu(projectId, uuid, id);
            })
        }
        this.createTemplateMenu = async function (projectId, uuid, template) {
            return requestHandler(async () => {
                const res = await api.templates.createTemplateMenu(projectId, uuid, template);
                return res.data
            })
        }
        this.editTemplateMenu = async function (projectId, uuid, id, template) {
            return requestHandler(async () => {
                await api.templates.editTemplateMenu(projectId, uuid, id, template);
            })
        }
        this.deleteFileTemplateMenu = async function (projectId, uuid, id, data) {
            return requestHandler(async () => {
                await api.templates.deleteFileTemplateMenu(projectId, uuid, id, data);
            })
        }
        this.addFileTemplateMenu = async function (projectId, uuid, id, data) {
            return requestHandler(async () => {
                const res = await api.templates.addFileTemplateMenu(projectId, uuid, id, data);
                return res.data
            })
        }

        //sber
        this.getTemplateWithType = async function (data) {
            return requestHandler(async () => {
                return await api.templates.getTemplateWithType(data);
            })
        }
        this.deleteTemplateWithType = async function (integration, type, id) {
            return requestHandler(async () => {
                return await api.templates.deleteTemplateWithType(integration, type, id);
            })
        }
        this.createTemplateWithType = async function (integration, type, data, projectId) {
            return requestHandler(async () => {
                return await api.templates.createTemplateWithType(integration, type, data, projectId);
            })
        }
        this.editTemplateWithType = async function (integration, type, id, data, projectId) {
            return requestHandler(async () => {
                return await api.templates.editTemplateWithType(integration, type, id, data, projectId);
            })
        }
        this.addFileToTemplateWithType = async function (integration, type, id, data, projectId) {
            return requestHandler(async () => {
                return await api.templates.addFileToTemplateWithType(integration, type, id, data, projectId);
            })
        }
        this.removeFileFromTemplateWithType = async function (data) {
            return requestHandler(async () => {
                return await api.templates.removeFileFromTemplateWithType(data);
            })
        }
        this.getStaffFromTemplateWithType = async function (data) {
            return requestHandler(async () => {
                return await api.templates.getStaffFromTemplateWithType(data);
            })
        }
        this.getClientCategoriesFromTemplateWithType = async function (data) {
            return requestHandler(async () => {
                return await api.templates.getClientCategoriesFromTemplateWithType(data);
            })
        }
        this.getServicesFromTemplateWithType = async function (data) {
            return requestHandler(async () => {
                return await api.templates.getServicesFromTemplateWithType(data);
            })
        }
        this.sendTestMessage = async function (projectId, data) {
            return requestHandler(async () => {
                return await api.templates.sendTestMessage(projectId, data);
            })
        }

        //Cross sales
        this.getCrossSalesList = async function (projectId, filters) {
            return requestHandler(async () => {
                const response = await api.templates.getCrossSalesList(projectId, filters);
                store.state.templates.yc.lastPage = response.last_page
                store.state.templates.yc.hasMore = response.has_more
                store.commit('templates/setTemplates', {
                    template: 'yc',
                    templates: response.data
                });
            })
        }
        this.getCrossSaleTemplate = async function (projectId, id) {
            return requestHandler(async () => {
                const {data} = await api.templates.getCrossSaleTemplate(projectId, id);
                return data;
            })
        }
        this.getCrossSaleTemplateConfig = async function (id, index, condition) {
            return requestHandler(async () => {
                const {data} = await api.templates.getCrossSaleTemplateConfig(id, index, condition);
                return data;
            })
        }
        this.createCrossSale = async function (templateData) {
            return requestHandler(async () => {
                const {data} = await api.templates.createCrossSale(templateData);
                return data;
            })
        }
        this.editCrossSaleTemplate = async function (id, templateData) {
            return requestHandler(async () => {
                const {data} = await api.templates.editCrossSaleTemplate(id, templateData);
                return data;
            })
        }
        this.previewCrossSaleTemplate = async function (id) {
            return requestHandler(async () => {
                const {data} = await api.templates.previewCrossSaleTemplate(id);
                return data;
            })
        }
        this.deleteCrossSale = async function (id) {
            return requestHandler(async () => {
                const {data} = await api.templates.deleteCrossSale(id);
                return data;
            })
        }
        this.startCrossSale = async function (id) {
            return requestHandler(async () => {
                const {data} = await api.templates.startCrossSale(id);
                return data;
            })
        }
        this.stopCrossSale = async function (id) {
            return requestHandler(async () => {
                const {data} = await api.templates.stopCrossSale(id);
                return data;
            })
        }

        this.restartCrossSale = async function (id) {
            return requestHandler(async () => {
                const {data} = await api.templates.restartCrossSale(id);
                return data;
            })
        }

        //Vk
        this.getTemplatesVkNotify = async function (channelUuid, queryStr = '') {
            return requestHandler(async () => {
                const response = await api.templates.getTemplatesVkNotify(channelUuid, queryStr);

                store.state.templates.vk.lastPage = response.last_page
                const data = {
                    template: 'vk',
                    templates: response.data
                }
                store.commit('templates/setTemplates', data);

                return response
            })
        }
        this.getTemplateVkNotify = async function (channelUuid, id) {
            return requestHandler(async () => {
                const {data} = await api.templates.getTemplateVkNotify(channelUuid, id);

                return data
            })
        }
        this.deleteTemplateVkNotify = async function (channelUuid, id) {
            return requestHandler(async () => {
                await api.templates.deleteTemplateVkNotify(channelUuid, id);
            })
        }
        this.createTemplateVkNotify = async function (channelUuid, data) {
            return requestHandler(async () => {
                await api.templates.createTemplateVkNotify(channelUuid, data);
            })
        }
        this.editTemplateVkNotify = async function (channelUuid, id, data) {
            return requestHandler(async () => {
                await api.templates.editTemplateVkNotify(channelUuid, id, data);
            })
        }

        // copy templates
        this.getFullTemplates = async function (project, type) {
            return requestHandler(async () => {
                return await api.templates.getFullTemplates(project, type);
            })
        }

        this.getFullCrossTemplates = async function (project) {
            return requestHandler(async () => {
                return await api.templates.getFullCrossTemplates(project);
            })
        }

        this.copyTemplates = async function (data) {
            return requestHandler(async () => {
                return await api.templates.copyTemplates(data);
            })
        }

        this.copyTemplatesChannels = async function (data) {
            return requestHandler(async () => {
                return await api.templates.copyTemplatesChannels(data);
            })
        }
    }
}

export default new TemplatesService()