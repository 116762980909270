import axios from "axios";
import VueCookies from 'vue-cookies';
import router from "@/router";
import globalService from "@/services/globalService";
import store from "@/store/index";

export default {
    namespaced: true,
    state: {
        currentProject: { name: '' },
        currentProjectId: localStorage.Project,
        isSelected: false,
        allProjects: [],
        isTasksApp: false,
        newProject: {
            name: '',
            timezone: '',
            language: 'en',
            integration: '',
            channels: []
        },
        steps: {
            createProject: {component: 'CreateProjectMain', type: 'create-project'},
            channels: {component: 'CreateProjectIntegrationsChannels', type: 'channels'},
            chooseIntegration: {component: 'CreateProjectIntegrations', type: 'choose-integration'},
            connectIntegration: {component: 'CreateProjectIntegrationsConnectYclients', type: 'connect-integration'},
        },
        currentStep: {component: 'CreateProjectMain', type: 'create-project'},
    },
    actions: {
        async getProject(context){
            if (context.state.currentProjectId) {
                await axios.get("/api/app/projects/" + context.state.currentProjectId).then(response => {
                    store.state.projects.isTasksApp = response.data.data.is_tasks_app
                    context.currentProject = response.data.data
                    context.commit("setProject", response.data.data);
                }).catch(() => {
                    context.commit("setIsSelectedProject", false);
                })
            }
        },
        async getProjectConfig(context){
            if (context.state.currentProjectId) {
                const res = await axios.get("/adm/chat/getChat/", {
                    headers: {
                        'X-Project': context.state.currentProjectId
                    }
                });

                return res.data;
            }
        },
        async selectProject(context,uuid) {
            await context.dispatch('getProject',uuid)

            //для совместимости со старой админкой, метод устанавливает проект в сессию
            // await axios.post("/deprecated/projects/select/", {uuid: uuid},{ baseURL: import.meta.env.VITE_WAHELP_BASE_URL }).then(response => {
            // context.commit("setProject", response.data.data);
            // })
        },
        async getAllProjects({commit, state, dispatch}) {
            const response = await axios.get("/api/app/projects/sidebar")
            commit("setProjects", response.data.data.projects);
            if (response.data.data.projects.length === 1) {
                localStorage.setItem('Project', response.data.data.projects[0].id)
            }
            return response.data.data.projects;
        },
    },
    mutations: {
        setProject(state, project) {
            state.currentProject = project;
            state.currentProjectId = project.id;
            localStorage.Project  = project.id;
            store.state.global.sideMenuItems = []
            globalService.getSideMenu();
        },
        clearProject(state) {
            state.currentProject = { name: '' };
            state.currentProjectId = null;
            localStorage.removeItem('Project');
        },
        addProject(state, project) {
          state.allProjects.push(project);
        },
        setProjects(state, projects) {
            state.allProjects = projects;
        },
        clearAllProjects(state) {
            state.allProjects = [];
        },
        setIsSelectedProject(state, bool) {
            state.isSelected = bool;
        }
    },
    getters: {
        hasPermissionProject: (state, rootState) => permission => {
            return rootState.hasPermission(permission) //проверка на permission для пользователя
                &&
                state.isSelected
                &&
                (//проверка permission для проекта
                    state.currentProject.isOwner ||//владелец
                    rootState.hasPermission('adm_all_projects') ||//сотрудник ТП, доступны все проекты
                    (//или выданы нужные права
                        state.currentProject.permissions &&
                        (
                            state.currentProject.permissions.indexOf(permission) != -1
                            || state.currentProject.permissions.indexOf("_ALL_") != -1
                        )
                    )
                );
        },
        getCurrentProject(state) {
            return state.isSelected ? state.currentProject.id : null;
        },
    },
}