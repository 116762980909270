import Api from '@/api/index.js'
import store from "@/store";
import { requestHandler, basePath } from '@/utils/apiHelpers';

class HistoryService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getHistoryFilters = async function (project_id, uuid, pageActive, numberOfRow, sortTypeDate, filtersStr) {
            return requestHandler(async () => {
                let response = await api.history.getHistoryFilters(project_id, uuid, pageActive, numberOfRow, sortTypeDate, filtersStr);
                store.commit('history/setHistories', response.data);
                store.commit('history/setHasMode', response.has_mode);
                store.commit('history/setLoaderActive', false);
                return response;
            })
        }

        this.getLostHistory = async function (project_id, messenger, pageActive, numberOfRow, sortTypeDate, filtersStr) {
            return requestHandler(async () => {
                let response = await api.history.getLostHistory(project_id, messenger, pageActive, numberOfRow, sortTypeDate, filtersStr);
                store.commit('history/setHistories', response.data);
                store.commit('history/setHasMode', response.has_mode);
                store.commit('history/setLoaderActive', false);
                return response;
            })
        }
    }
}

export default new HistoryService()