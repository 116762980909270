import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getManagersAmo: async (integration) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/link_managers/integration_users`,
            { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getListLinksManagers: async (integration) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/link_managers`,
            { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    refreshListLinksManagers: async (integration) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/link_managers/refresh`,
            { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    updateLinkManager: async (integration, id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/link_managers/${id}`,
            { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}