<template>
  <div class="banner" v-if="isActive">
    <button class="banner__close" @click="close">
      <img src="@/assets/img/icons/close-black.svg" alt="close">
    </button>
    <div class="banner__content">
      <span class="banner__content__text">
        {{ $store.state.global.appConfig?.header_info.title }}
      </span>

      <div v-html="$store.state.global.appConfig?.header_info.link"></div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapState } from "vuex";

export default {
  data() {
    return {
      bannerStatus: true
    };
  },
  methods: {
    close() {
      const dateNow = new Date().getTime();
      const data = {
        value: "hidden",
        timestamp: dateNow,
        editedAt: this.appConfig?.header_info?.edited_at,
      };
      localStorage.setItem("amoBannerHide", JSON.stringify(data));
      this.bannerStatus = false;
    },
    checkBannerStatus() {
      const amoBannerHide = localStorage.getItem("amoBannerHide");
      const currentEditedAt = this.appConfig?.header_info?.edited_at;

      if (amoBannerHide) {
        const { value, timestamp, editedAt } = JSON.parse(amoBannerHide);
        const dateNow = new Date().getTime();
        const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

        // Проверка изменения конфигурации по полю edited_at
        if (currentEditedAt !== editedAt) {
          localStorage.removeItem("amoBannerHide");
          this.bannerStatus = true;
        } else if (dateNow - timestamp > threeDaysInMs) {
          localStorage.removeItem("amoBannerHide");
          this.bannerStatus = true;
        } else {
          this.bannerStatus = false;
        }
      }
    },
  },
  computed: {
    ...mapState("global", ["appConfig"]),
    isActive() {
      return (
          this.bannerStatus &&
          this.appConfig &&
          this.appConfig.header_info.title &&
          this.appConfig.header_info.active &&
          this.appConfig.header_info.active != 0
      );
    }
  },
  mounted() {
    this.checkBannerStatus();

    if(!this.$store.state.global.isMessageHelp && location.hostname !== 'localhost'){
      const button = document.querySelector('.news__btns_btn')
      if (button) {
        button.addEventListener('click', () => {
          ym(54891508, "reachGoal", "plashka")
        })
      }
    }
  },
  beforeDestroy() {
    if(!this.$store.state.global.isMessageHelp && location.hostname !== 'localhost'){
      const button = document.querySelector('.news__btns_btn')
      if (button) {
        button.removeEventListener('click', () => {
          ym(54891508, "reachGoal", "plashka")
        })
      }
    }
  }
};
</script>

<style lang="scss">
.news__btns_btn{
  border-radius: 999px;
  background: #FFF;
  border: none;
  font-family: "RF Dewi";
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  padding: 7px 12px;
  display: block;

  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
}
</style>
<style lang="scss" scoped>
.banner {
  width: calc(100% - 24px);
  border-radius: 12px;
  background: #94FFBF;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
  padding: 14px 14px 14px 17px;

  &__close {
    padding: 0;
    margin: 0;
    width: 16px;
    height: 16px;
    border: none;
    background: transparent;

    img{
      width: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

    &__text {
      color: #0E2230;
      font-family: "RF Dewi";
      font-size: 16px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1200px) {
    width: calc(100% - 78px);
    border-radius: 16px;
    margin-top: 0;
    margin-left: 54px;
    align-items: flex-start;
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: space-between;
    padding: 12px;

    &__content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &__text {
        line-height: 130%;
      }
    }
  }

  @media screen and (max-width: 700px) {
    width: calc(100% - 76px);
    margin-left: 60px;

    &__content {

      &__text {
        font-size: 12px;
      }
    }
  }
}
</style>