
import settingsService from "@/services/settingsService";
import store from '@/store';

export default {
    namespaced: true,
    state: {
        settings: {
            general: {},
            scripts: {},
            template_vars: {},
            cascade: {},
            chat: {},
            translation_vars: {},
            yclients: {},
            bitrix: {},
            bitrixSms: {},
            amo: {},
            tasks: {
                config: {
                    record: {},
                    review: {},
                },
                regularTasks: []
            },
        },
        users: [],
        departments: [],
        currentEmployee: {
            name: '',
            position: '',
            config: {}
        },
        titles: {},
        config: {}
    },
    actions: {
        async editSettings(state, { section, field }) {
            const res = await settingsService.editSettings({ section, field });

            store.commit('global/showToast',{ text: store.state.global.language === 'ru' ? 'Настройки успешно сохранены' : 'Settings saved successfully' });
        },
        async addCascadeChannel({ state }, { channel, value, fieldKey }){
            if (state.settings.cascade[fieldKey]) {
                if (value) {
                    state.settings.cascade[fieldKey].push(`${channel.id}`);
                } else {
                    const idx = state.settings.cascade[fieldKey].findIndex(cId => cId == channel.id);
                    state.settings.cascade[fieldKey].splice(idx, 1);
                }

            } else {
                if (value) {
                    state.settings.cascade[fieldKey] = [ `${channel.id}` ];
                }
            }

            await store.dispatch('settings/editSettings', { section: 'cascade', field: { ...state.settings.cascade } });
        }
    },
    mutations: {
        setCurrentEmployee(state, employee) {
            state.currentEmployee = employee;
        },
        setDefaultCurrentEmployee(state) {
            state.currentEmployee = {
                name: '',
                position: '',
                config: {
                    whatsapp: state.config.whatsapp,
                    telegram_bot: state.config.telegram_bot,
                    email: state.config.email,
                    viber: state.config.viber,
                }
            }
        },
        setTitles(state, titles) {
            state.titles = titles;
        },
        setConfig(state, config) {
            state.config = config;
        },
    },
    getters: {
        isAdmin(state) {
            const userInProject = state.users.find(u => u.id === store.state.auth.user.id);
            const isProjectHasAdmins = state.users.some(u => u.project_role === 'admin');

            return (
              store.state.auth.user.permissions['_BOTS_'] ||
              store.state.projects.currentProject?.owner?.id === store.state.auth.user.id ||
              userInProject?.project_role === 'admin' ||
              !isProjectHasAdmins
            )
        },
        managedDepartments(state) {
            const userInProject = state.users.find(u => u.id === store.state.auth.user.id);
            const departments = {};

            if (userInProject) {
                userInProject.departments.forEach(d => {
                    if (d.is_superviser) {
                        departments[d.id] = d;
                    }
                })
            }

            return departments;
        },
        unattachedChannels(state) {
            const channels = [];

            if (store.state.projects.currentProject && store.state.projects.currentProject?.channels) {
                store.state.projects.currentProject?.channels.forEach(c => {
                    const userWithChannel = state.users.find(u => u.channels_ids.includes(c.id));

                    if (!userWithChannel) {
                        channels.push(c);
                    }
                })
            }

            return channels;
        }
    },
}