import { createStore } from 'vuex'
import global from "@/store/global"
import tools from "@/store/tools"
import auth from "@/store/auth"
import projects from "@/store/projects";
import channels from "@/store/channels";
import tariff from "@/store/tariff";
import mailings from '@/store/mailings';
import partners from "@/store/partners";
import history from '@/store/history';
import clients from '@/store/clients';
import settings from "@/store/settings";
import shares from "@/store/shares"
import internalKnowledge from "@/store/internalKnowledge";
import templates from "@/store/templates";
import users from "@/store/users"
import analytics from "@/store/analytics";

const store = createStore({
  store: {
    domain: "wahelp.ru",

  },
  modules: {
    global, tools, auth, projects,
    channels, tariff, mailings, partners,
    history, clients, settings, shares,
    internalKnowledge, templates, users,
    analytics,
  }
})

export default store;