import Api from '@/api/index.js'
import store from "@/store";
import {basePath, requestHandler} from '@/utils/apiHelpers';

class ClientsService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getClients = async function (projectId, uuid, pageActive, numberOfRow, sortTypeDate, filtersStr) {
            return requestHandler(async () => {
                let response = await api.clients.getClients(projectId, uuid, pageActive, numberOfRow, sortTypeDate, filtersStr);

                store.state.clients.isEditManagers = response.meta.can_edit_managers
                store.commit('clients/setClients', response.data);
                store.commit('clients/setHasMode', response.has_mode);
                store.commit('clients/setLoaderActive', false);
                return response;
            })
        }

        this.getClient = async function (projectId, uuid, clientId) {
            return requestHandler(async () => {

                let response = await api.clients.getClient(projectId, uuid, clientId);
                store.state.clients.isEditManagers = response.meta?.can_edit_managers
                store.commit('clients/setActiveClient', response.data);
                return response;
            })
        }

        this.putCommentClient = async function (projectId, uuid, client) {
            return requestHandler(async () => {

                return await api.clients.putCommentClient(projectId, uuid, client);
            })
        }
    }
}

export default new ClientsService()