import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";
import Promise from "lodash/_Promise";

export default {

    //Получение полных привязанных к пользователю проектов
    getFullProjects: async (data) =>
        messCall(`/projects?onlyLinkedUsers=true`, { method: 'get', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Получение инфы о проекте
    getProjectInfo: async (id) =>
        messCall(`/projects/${id}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Получение тарифа проекта
    getProjectTariff: async (id) =>
        messCall(`/projects/${id}/tarif`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getProjectTariffConnected: async (id) =>
        messCall(`/projects/${id}/prices`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Получение статусов проектов
    getChannelStatuses: async (id) =>
        messCall(`/projects/${id}/channels/status`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Создание канала
    createChannel: async (id, data) =>
        messCall(`/projects/${id}/channels/`, { method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Удалить канал
    deleteChannel: async (id, uuid) =>
        messCall(`/projects/${id}/channels/${uuid}`, { method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Создание услуги
    createService: async (id, data) =>
        messCall(`/projects/${id}/services/`, { method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),


    //Удаление услуги
    deleteService: async (id, service) =>
        messCall(`/projects/${id}/services/${service}`, { method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),


    //Создание интеграции
    createIntegration: async (id, data) =>
        messCall(`/projects/${id}/integrations/`, { method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    deleteIntegration: async (id, integration) =>
        messCall(`/projects/${id}/integrations/${integration}`, { method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Оплатить проект
    payProject: async (id, data) =>
        messCall(`/partners/projects/${id}/pay`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Пополнить баланс
    replenishBalance: async (data) =>
        messCall(`/user/balance/add`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Оплатить проекты с баланса
    payProjectsFromBalance: async (data) =>
        messCall(`/projects/pay/balance`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Оплатить партнёрские проекты с баланса
    payProjectsFromBalancePartner: async (data, id) =>
        messCall(`/partners/projects/${id}/pay/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),


    //Оплатить проекты с карты
    payProjectsFromCard: async (data) =>
        messCall(`/projects/pay/card`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Получение списка автоплатежей
    getAutoPayments: async (data) =>
        messCall(`/user/balance/payments/recurring`, { method: 'get', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    deleteAutoPayment: async (id) =>
        messCall(`/user/balance/payments/recurring/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Доверительный платеж
    postTrustPayment: async (id) =>
        messCall(`/projects/${id}/trust`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Список транзакций
    getTransactions: async (page, filtersQuery) =>
        messCall(`/user/transactions/?page=${page}&count=20${filtersQuery}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Получить контрагента
    getCounterParty: async () =>
        messCall(`/counterparty/${store.state.projects.currentProjectId}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/accounting_new'),

    //Изменить контрагента
    putCounterParty: async (data) =>
        messCall(`/counterparty/${store.state.projects.currentProjectId}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/accounting_new'),

    //Запросить счет
    getBillLink: async (data) =>
        messCall(`/get_bill_link/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/accounting_new'),

    //Отправить отбивку о смене тарифа
    changeTariff: async (id, data) =>
        messCall(`/projects/${id}/tarif`, { method: 'post', data }, {'X-Project': id}, '/api/app'),

    //Получение информации при подключение канала/интеграции/услуги без перерасчета даты окончания
    createServiceWithoutTariffRecalculation: async (id, data) =>
        messCall(`/projects/${id}/buy_service/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Получение информации при отключении канала/интеграции/услуги без перерасчета даты окончания
    deleteServiceWithoutTariffRecalculation: async (id, data) =>
        messCall(`/projects/${id}/remove_service/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //Оплата канала/интеграции/услуги с баланса без перерасчета даты окончания
    payFromBalanceWithoutTariffRecalculation: async (id, order_id) =>
        messCall(`/projects/${id}/buy_service/from_balance/${order_id}`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    sberCrmConnectionLink: async () =>
        messCall(`/integrations/sbercrm/link`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    callRequest: async (data) =>
      messCall(`/ajax/`, { method: 'post', data, format: 'form' }, {'X-Project': store.state.projects.currentProjectId}, ''),
}