<template>
  <div class="toast-wrapper" :class="$store.state.global.positionToast">
    <div class="toast">
      <template v-if="!$store.state.global.hideToastImg">
        <img v-if="!$store.state.global.isErrorToast" :src="require(`@/assets/img/icons/circle-check-${$store.state.global.isMessageHelp ? 'blue' : 'green'}.svg`)" alt="check">
        <img v-else class="error-img" src="@/assets/img/icons/circle-close-red.png" alt="check">
      </template>

      {{ $store.state.global.toastText }}
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$store.commit('global/closeToast');
    }, 3500)
  }
}
</script>

<style lang="scss" scoped>
.toast-wrapper {
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: 10px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  animation: move 0.5s;
  z-index: 10000;

  &.bottom{
    top: auto;
    right: 32px;
    left: auto;
    bottom: 32px;
    margin: 0;
    animation: bottom 0.5s;
  }
}

.toast {
  padding: 12px 18px;
  color: var(--default-text);
  font-size: 14px;
  font-weight: 500;
  background: var(--main-background);
  border-radius: 12px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.20);
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  white-space: pre-line;

  img {
    width: 16px;
    margin-right: 10px;
    margin-top: 2px;

    &.error-img {
      width: 18px;
    }
  }
}

@keyframes move {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>