<template>
  <div v-if="showModal" class="background-fixed" :style="`z-index: ${zIndex}`">
    <div class="modal-wrapper" :id="id"
         :class="{'message-help': $store.state.global.isMessageHelp}"
         :style="{alignItems: startAlignItems ? startAlignItems : alignItems}"
    >
      <div class="modal-overlay"
           @click.stop="$emit('close-modal')"
           :style="{alignItems: alignItems}">
      </div>

      <div class="modal-container"
           :style="width && !$store.state.global.isMobile ? `width: ${width}; max-width: ${width}` : ''">

        <div class="modal-header">
          <h2 class="header__title" v-if="title">{{ title }}</h2>
          <button class="header__btn" v-if="!hideCloseButton" @click="$emit('close-modal')">
            <img src="@/assets/img/icons/close.svg" alt="close">
          </button>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <div class="modal-footer" >
          <slot name="footer"></slot>
          <btn v-if="closeButton" @click="$emit('close-modal')">{{ $t('UI.modal.close') }}</btn>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width:{
      type: String
    },
    showModal:{
      type: Boolean
    },
    title: {
      type: String
    },
    closeButton:{
      type: Boolean
    },
    hideCloseButton:{
      type: Boolean
    },
    id: {
      type: String,
      required: true
    },
    startAlignItems: String,
    zIndex: String
  },
  emits:['close-modal'],
  data() {
    return {
      resizeObserver: null,
      alignItems: 'center'
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const modal = document.querySelector(`#${this.id} .modal-container`)
          this.initResizeObserver(modal)
        })
      } else {
        this.disconnectObserver()
      }
    }
  },
  methods: {
    disconnectObserver(){
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
      }
    },

    initResizeObserver(modal) {
      this.resizeObserver = new ResizeObserver(entries => {
        const entry = entries[0]  // Получаем modal элемент
        const heightModal = entry.target.clientHeight
        const heightDisplay = document.documentElement.clientHeight - 50
        if (this.startAlignItems) {
          this.alignItems = this.startAlignItems
        } else if (heightDisplay < heightModal) {
          this.alignItems = 'flex-start'
        } else {
          this.alignItems = 'center'
        }
      })
      this.resizeObserver.observe(modal)
    }
  },
};
</script>

<style  lang="scss" scoped>
.background-fixed {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: visible;
  background: rgba(5, 16, 27, 0.4);
  z-index: 9999;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1046;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  @media (max-width: 500px) {
    padding-bottom: 70px !important;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  overflow-y: auto;
  z-index: 999;
}

.modal-container{
  min-width: 375px;
  max-width: 1050px;
  width: auto;
  padding: 25px;
  margin: 10px;
  border-radius: 24px;
  background: var(--main-background);
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 1000;
  position: relative;

  @media(max-width: 750px){
    max-width: 350px;
    min-width: calc(100vw - 40px);
    padding: 15px;
    border-radius: 32px;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .header__title {
    line-height: 33px;
    color: var(--default-text);
    font-family: 'RF Dewi';
    font-size: 24px;
    font-weight: 700;
  }

  .header__btn {
    margin: 8px 0 0 16px;

    img{
      width: 18px;
    }
  }

  @media(max-width: 750px){
    .header__title {
      font-size: 20px;
    }

    .header__btn {
      img{
        width: 16px;
      }
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
</style>