import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getChannels: async (projectId) =>
        messCall(`/projects/${projectId ? projectId : store.state.projects.currentProjectId}/channels/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getChannelByUuid: async (uuid) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getWhatsappQrCode: async (uuid) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/settings/whatsapp/qr`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getChannelLink: async (uuid) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/setup`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postChannelSetup: async (uuid, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/setup`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putChannel: async (uuid, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}