export default {
    mounted(el,   binding  ) {
         let options = {
          rootMargin: '20px',
          threshold: 0.1
        }
        const callback = (entries, observer) => {
            binding.value(entries[0].isIntersecting)

            // if (entries[0].isIntersecting) {
          // }
        };
        const observer = new IntersectionObserver(callback, options);
        observer.observe(el);
    },
    name: 'intersection'
}
