import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    login: async (data) =>
        messCall(`/user/login/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    restorePassword: async (data) =>
        messCall(`/user/forgot_password/`, { method: 'post', data, format: 'form' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    register: async (data) =>
        messCall(`/user/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    logout: async () =>
        messCall(`/user/logout/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    confirmPhoneOrEmail: async (key, val) =>
        messCall(`/user/code?${key}=${val}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    checkCode: async (data) =>
        messCall(`/user/code/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    autoRegister: async (data) =>
        messCall(`/user/autoregister/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    changePassword: async (data) =>
        messCall(`/user/password/`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getUserDataFromMarketplace: async (data) =>
        messCall(`/integrations/${data.marketplace}/?${data.query}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    connectBranchToProject: async (data) =>
        messCall(`/integrations/${data.marketplace}/?${data.query}`, { method: 'post', data: { ...data.project } }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getUser: async () =>
        messCall(`/user/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    changeUser: async (data) =>
        messCall(`/user/`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    changeUserLocation: async (data) =>
        messCall(`/user/geo/`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getUserLocation: async () =>
        messCall(`/user/geo/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    changePopupStatus: async () =>
      messCall(`/user/popup_status`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getUserSessions: async (data) =>
        messCall(`/user/sessions/`, { method: 'get', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteUserSession: async (id) =>
        messCall(`/user/sessions/session/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getOpenIdUrl: async (data) =>
        messCall(`/user/openid/url/?provider=google`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    openIdRegistration: async (data) =>
        messCall(`/user/openid/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    openIdLogin: async (data) =>
        messCall(`/user/openid/login/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getOpenIdProviders: async () =>
        messCall(`/user/openid/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteOpenIdProvider: async (id) =>
        messCall(`/user/openid/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}