import store from "@/store/index";
import moment from "moment/moment";
import sharesService from "@/services/sharesService";

export default {
  namespaced: true,
  state: {
    shares: [],
    config: null,
    activeShare: {},
    isActiveLoader: false,
    isUnlimitedShares: false,
    filters:{
      radio: 'all',
      date: null,
      search: ''
    },
  },

  mutations: {
    pushNewShare(state, share){
      if(state.filters.radio === 'all' || state.filters.radio === share.status){
        state.shares.push(share)
      }
    },

    changeShare(state, share){
      const index = state.shares.findIndex(s => s.id === share.id)
      if(index !== -1){
        if(state.filters.radio === 'all' || state.filters.radio === share.status){
          state.shares.splice(index, 1, share)
        }else{
          state.shares.splice(index, 1)
        }
      }
    },

    deleteShare(state){
      const index = state.shares.findIndex(s => s.id === state.activeShare.id)
      if(index !== -1){
        state.shares.splice(index, 1)
      }
    },

    setShares(state, shares) {
      state.shares = shares
    },

    setConfig(state, config) {
      state.config = config
    },

    setIsActiveLoader(state, isActiveLoader) {
      state.isActiveLoader = isActiveLoader
    },

    setFilters(state, filters) {
      state.filters.radio = filters.radio
      state.filters.date = filters.date
      state.filters.search = filters.search
    },
  },

  actions: {
    async getShares({commit, state}) {

      commit("setIsActiveLoader", true)

      const keysFilters = {
        search: 'search',
        date_from: 'date_start',
        date_to: 'date_end',
        radio: 'status[]'
      }

      let filtersStr = ''

      for (const [key, value] of Object.entries(state.filters)) {
        if (key && value) {
          if(key !== 'date') {
            if(key === 'radio' && value === 'all'){
              ['active', 'pause', 'finished', 'archived'].forEach(el => {
                filtersStr += `&${keysFilters[key]}=${el}`
              })
            }else{
              filtersStr += `&${keysFilters[key]}=${value}`
            }
          } else {
            const dateObjFrom = new Date(value[0]);
            const isoStringFrom = dateObjFrom.toISOString();
            const dateFrom = moment(isoStringFrom).format('YYYY-MM-DD');

            let dateTo = ''
            if(!state.isUnlimitedShares){
              const dateObjTo = new Date(value[1]);
              const isoStringTo = dateObjTo.toISOString();
              dateTo = moment(isoStringTo).format('YYYY-MM-DD');
            }

            filtersStr += `&${keysFilters.date_from}=${dateFrom}&${keysFilters.date_to}=${dateTo}`
          }
        }
      }

      if(filtersStr.length){
        filtersStr = filtersStr.slice(1)
      }

      await sharesService.getShares(filtersStr);
    },

    async getConfig(){
      await sharesService.getConfig();
    },

    async createShare({commit, state}){
      await sharesService.createShare(state.activeShare);
    },

    async editShare({commit, state}){
      await sharesService.editShare(state.activeShare);
    },

    async deleteShare({commit, state}){
      await sharesService.deleteShare(state.activeShare.id);
    }
  },
}