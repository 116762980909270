import { messCall } from "@/utils/apiHelpers";

export default {
    getTariffs: async (paramsQuery) =>
      messCall(`/tariffs/${paramsQuery}`, { method: 'get' }, {}, '/api/adm'),
    getTariffsOptions: async () =>
      messCall(`/tariffs/options`, { method: 'get' }, {}, '/api/adm'),
    getTariffById: async (id) =>
      messCall(`/tariffs/${id}`, { method: 'get' }, {}, '/api/adm'),
    postTariff: async (data) =>
      messCall(`/tariffs`, { method: 'post', data }, {}, '/api/adm'),
    putTariff: async (id, data) =>
      messCall(`/tariffs/${id}`, { method: 'put', data }, {}, '/api/adm'),
    deleteTariff: async (id) =>
      messCall(`/tariffs/${id}`, { method: 'delete' }, {}, '/api/adm'),
    compareTariff: async (id) =>
      messCall(`/tariffs/${id}/compare`, { method: 'get' }, {}, '/api/adm'),
    activateTariff: async (id) =>
      messCall(`/tariffs/${id}/activate`, { method: 'patch' }, {}, '/api/adm'),
}