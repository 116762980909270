import TemplatesService from "@/services/templatesService";
import store from "@/store/index";
export default {
    namespaced: true,
    state: {
        isLoaderActive: false,

        //WABA
        waba: {
            templates: [],
            template: null,
            uuid: '',
            templatesImport: [],
            lastPage: 0,
            filters: {
                count: 5,
                page: 1
            }
        },

        //MENU
        menu:{
            templates: [],
            lastPage: 0,
            channelType: '',
            template: {
                alternative_codes: [],
                function_settings: {
                    file_text_request: {},
                    file_bad_review: {}
                },
                files: {}
            },
            filters:{
                filter: '',
                sort: '',
                sort_type: '',
                count: 5,
                page: 1
            },
            uuid: '',
            functions: null
        },

        //Vk
        vk: {
            templates: [],
            template: {},
            uuid: '',
            lastPage: 0,
            filters: {
                count: 5,
                page: 1
            }
        },

        //YC/AG, SBER
        yc:{
            fixtime: '',
            editCreateFiles: [],
            channelsMenu: [],
            templates: [],
            template: {},
            services: [],
            masters: [],
            clients: [],
            lastPage: 0,
            filters: {
                count: 5,
                page: 1
            }
        },
        createMasters: {
            checkboxMain: {id: 'master-0', name: 'Выбрать всех мастеров', value: false},
            checkboxList: []
        },
        createServices: {
            checkboxMain: {id: 'service-0', name: 'Выбрать все услуги', value: false},
            checkboxList: []
        },
        createCategory: {
            checkboxMain: {id: 'category-0', name: 'Выбрать все категории', value: false},
            createServices: []
        },
        createClients: {
            checkboxMain: {id: 'client-0', name: 'Выбрать все категории', value: false},
            checkboxList: []
        },
    },

    mutations: {
        setTemplates(state, data) {
            state[data.template].templates = data.templates;
        },
        setTemplatesImportWaba(state, templatesImport) {
            state.waba.templatesImport = templatesImport;
        },
        setTemplateWaba(state, template) {
            if(template && template.id){
                const t = state.waba.templates.find(item => item.id === template.id)
                template.data.status = t.status
                template.data.rejected_reason = t.rejected_reason
            }

            state.waba.template = template;
        },
        setUuid(state, data) {
            state[data.template].uuid = data.uuid
        },
        setLoaderActive(state, isLoaderActive){
            state.isLoaderActive = isLoaderActive
        },
        deleteTemplate(state, data){
            state[data.template].templates.splice(data.index, 1)
        },
        editTemplateWaba(state, index){
            state.waba.templates.forEach((el, ind) => {
                if(ind === index){
                    el.title = state.waba.template.title
                }
            })
        },
        setDefaultCreateTemplate(state) {
            state.yc.fixtime = ''
            state.yc.editCreateFiles = [];
            state.yc.channelsMenu = []
            state.yc.template = {}

            state.createMasters = {
                checkboxMain: {id: 'master-0', name: 'Выбрать всех мастеров', value: false},
                checkboxList: []
            }
            state.createServices = {
                checkboxMain: {id: 'category-0', name: 'Выбрать все категории', value: false},
                checkboxList: []
            }
            state.createCategory = {
                checkboxMain: {id: 'all', name: 'Выбрать все категории', value: false},
                createServices: [],
            }
            state.createClients = {
                checkboxMain: {id: 'client-0', name: 'Выбрать все категории', value: false},
                checkboxList: []
            }
        },

        updateCreateMasters(state, payload) {
            state.createMasters = { ...state.createMasters, ...payload };
        },
        updateCreateClients(state, payload) {
            state.createClients = { ...state.createMasters, ...payload };
        },
        updateCreateServices(state, payload) {
            state.createServices = { ...state.createServices, ...payload };
        }
    },
    actions: {
        //waba
        async getTemplatesWaba({commit, state, rootState}) {
            commit("setLoaderActive", true)
            localStorage.setItem('numberRowTemplateMenu', state.waba.filters.count)


            let queryStr = ''
            Object.entries(state.waba.filters).forEach(f => {
                if(f[1]) queryStr = queryStr + `${f[0]}=${f[1]}&`
            })
            if(queryStr){
                queryStr = '?' + queryStr
                queryStr = queryStr.slice(0, -1)
            }

            await TemplatesService.getTemplatesWaba(rootState.projects.currentProject.id, state.waba.uuid, queryStr);
            commit("setLoaderActive", false)
        },
        async getTemplateWaba({commit, state, rootState}, activeTemplate) {
            return await TemplatesService.getTemplateWaba(rootState.projects.currentProject.id, state.waba.uuid, activeTemplate.id);
        },
        async deleteTemplateWaba({commit, state, rootState}, activeTemplate) {
            await TemplatesService.deleteTemplateWaba(rootState.projects.currentProject.id, state.waba.uuid, activeTemplate.id);
            const data = {
                index: activeTemplate.index,
                template: 'waba'
            }
            commit("deleteTemplate", data)
        },
        async createTemplateWaba({state, rootState}, activeTemplate) {
            let format = state.waba.template.header_type === 'TEXT' ? '' : 'form'
            let template = { ...state.waba.template }
            if (format === 'form') {
                // Преобразуем массив buttons в свойства с нужными ключами
                if (template.buttons && Array.isArray(template.buttons)) {
                    template.buttons.forEach((button, index) => {
                        template[`buttons[${index}][text]`] = button.text
                        template[`buttons[${index}][value]`] = button.value
                    });

                    delete template.buttons
                }
            }

            await TemplatesService.createTemplateWaba(rootState.projects.currentProject.id, state.waba.uuid, template, format)
        },
        async editTemplateWaba({commit, state, rootState}, activeTemplate) {
            const data = {
                title: state.waba.template.title
            }
            await TemplatesService.editTemplateWaba(rootState.projects.currentProject.id, state.waba.uuid, activeTemplate.id, data);
            commit("editTemplateWaba", activeTemplate.index)
        },
        async getTemplatesForImportWaba({state, rootState}) {
            await TemplatesService.getTemplatesForImportWaba(rootState.projects.currentProject.id, state.waba.uuid);
        },
        async importTemplateWaba({state, rootState}, data) {
            await TemplatesService.importTemplateWaba(rootState.projects.currentProject.id, state.waba.uuid, data);
        },

        //menu
        async getTemplatesMenu({commit, state, rootState}) {
            localStorage.setItem('numberRowTemplateMenu', state.menu.filters.count)
            commit("setLoaderActive", true)

            let queryStr = ''
            Object.entries(state.menu.filters).forEach(f => {
                if(f[1]) queryStr = queryStr + `${f[0]}=${f[1]}&`
            })

            if(queryStr){
                queryStr = '?' + queryStr
                queryStr = queryStr.slice(0, -1)
            }

            await TemplatesService.getTemplatesMenu(rootState.projects.currentProject.id, state.menu.uuid, queryStr);
            commit("setLoaderActive", false)
        },
        async getTemplatesMenuTree({commit, state, rootState}, parent_id) {
            return await TemplatesService.getTemplatesMenuTree(rootState.projects.currentProject.id, state.menu.uuid, parent_id);
        },
        async getTemplateMenu({commit, state}, data) {
            return await TemplatesService.getTemplateMenu(data.projectId, data.uuid, data.id);
        },
        async getFunctionsMenu({commit, state}, data) {
            return await TemplatesService.getFunctionsMenu(data.projectId, data.uuid);
        },
        async getParentsMenu({commit, state}, data) {
            return await TemplatesService.getParentsMenu(data.projectId, data.uuid);
        },
        async deleteTemplateMenu({commit, state, rootState}, id) {
            await TemplatesService.deleteTemplateMenu(rootState.projects.currentProject.id, state.menu.uuid, id);
        },
        async createTemplateMenu({state}, data) {
            delete state.menu.template.files
            if(state.menu.template.function_settings){
                delete state.menu.template.function_settings.file_bad_review
                delete state.menu.template.function_settings.file_text_request
            }
            return await TemplatesService.createTemplateMenu(data.projectId, data.uuid, state.menu.template)
        },
        async editTemplateMenu({state}, data) {
            delete state.menu.template.files
            if(state.menu.template.function_settings){
                delete state.menu.template.function_settings.file_bad_review
                delete state.menu.template.function_settings.file_text_request
            }
            await TemplatesService.editTemplateMenu(data.projectId, data.uuid, data.id, state.menu.template)
        },
        async addFileTemplateMenu({state}, data) {
            return await TemplatesService.addFileTemplateMenu(data.projectId, data.uuid, data.id, data.data)
        },
        async deleteFileTemplateMenu({state}, data) {
            await TemplatesService.deleteFileTemplateMenu(data.projectId, data.uuid, data.id, data.data)
        },

        //YC/AG, SBER
        async getTemplatesYc({commit, state}, data) {
            localStorage.setItem('numberRowTemplateMenu', state.yc.filters.count)
            commit("setLoaderActive", true)

            let queryStr = ''
            Object.entries(state.yc.filters).forEach(f => {
                if(f[1]) queryStr = queryStr + `${f[0]}=${f[1]}&`
            })

            if(queryStr){
                queryStr = '?' + queryStr
                queryStr = queryStr.slice(0, -1)
            }

            if (!data.isCross) await TemplatesService.getTemplatesWithType(data.integration, data.currentTab, queryStr);
            else await TemplatesService.getCrossSalesList(localStorage.Project, queryStr)

            commit("setLoaderActive", false)
        },

        //Vk
        async getTemplatesVk({commit, state}) {
            commit("setLoaderActive", true)
            localStorage.setItem('numberRowTemplateMenu', state.vk.filters.count)

            let queryStr = ''
            Object.entries(state.vk.filters).forEach(f => {
                if(f[1]) queryStr = queryStr + `${f[0]}=${f[1]}&`
            })
            if(queryStr){
                queryStr = '?' + queryStr
                queryStr = queryStr.slice(0, -1)
            }

            await TemplatesService.getTemplatesVkNotify(state.vk.uuid, queryStr);
            commit("setLoaderActive", false)
        },
    },
}