<template>
  <a href="/" class="main-logo">
    <div class="logo" :style="$store.state.global.isMessageHelp ? 'width: 140px;' : 'width: 100px;'">
      <img :src="`${$store.state.global.publicPath}logo/logo-big${$store.state.global.isMessageHelp ? '-help' : '-wahelp'}.svg`" alt="logo">
    </div>
  </a>
</template>

<script>
export default { }
</script>

<style lang="scss" scoped>
.main-logo {
  .logo {

    img {
      width: 100%;
    }
  }
}
</style>