import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getMailings: async (type, params, usersList = false) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}${params}&append[]=stats${usersList ? '&append[]=users_lists' : ''}&sort=created_at`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postMailing: async (type, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}`, { method: 'post', data, format: 'form' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getMailingById: async (type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putMailing: async (type, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${data.id}/update`, { method: 'post', data, format: 'form' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteWhatsappMailingFile: async (type, id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/delete_file`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteMailingFile: async (type, id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/file`, { method: 'delete', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getMailingStatsById: async (type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/stats`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getMailingUsers: async (uuid) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/users`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteMailing: async (type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    exportMailing: async (type, id, statusesQuery) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/export${statusesQuery}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    exportMailingClients: async (type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/export/users`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    copyMailing: async (type, id) =>
      messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/copy`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    testSend: async (type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/test`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    sendMailing: async (type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/send`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    stopMailing: async (type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/stop`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    sendMailingToTestPhone: async (type, id) =>
      messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/${id}/test`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getUsersLists: async (page = 1, count = 100, filter = '') =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/user_list?page=${page}&count=${count}&filter=${filter}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getUsersListsConfig: async (type) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/user_list/config?type=${type}${type === 'yclients' ? '&with_filial=1' : ''}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getUsersListsExampleFile: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/user_list/example`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postUsersList: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/user_list`, { method: 'post', data, format: data.file ? 'form' : 'json' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteUsersList: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/user_list/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    exportUsersList: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/user_list/${id}/export`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    editUsersList: async (id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/user_list/${id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getVariables: async (type) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/${type}/variables`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}