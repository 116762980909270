export default {
    namespaced: true,
    state: {
        users: [
            {id: '24457', email: 'nsv.orel57@gmail.com', phone: '79398474849', name: 'Елена', organization: '', dateReg: '2024-09-04 15:51:35', additionalRights: '', inn: '', projects: 'Концерт', partnerCode: 'nsv.orel57', refCode: 'nsv.orel57', balance: '0.00', manager: ''},
            {id: '24457', email: 'nsv.orel57@gmail.com', phone: '79398474849', name: 'Елена', organization: 'Организация', dateReg: '2024-09-04 15:51:35', additionalRights: '', inn: '465987456', projects: 'Концерт', partnerCode: '', refCode: '', balance: '0.00', manager: ''},
            {id: '24457', email: 'nsv.orel57@gmail.com', phone: '79398474849', name: 'Елена', organization: '', dateReg: '2024-09-04 15:51:35', additionalRights: 'Права', inn: '', projects: 'Концерт', partnerCode: 'nsv.orel57', refCode: 'nsv.orel57', balance: '2659.00', manager: ''},
            {id: '24457', email: 'nsv.orel57@gmail.com', phone: '79398474849', name: 'Елена', organization: '', dateReg: '2024-09-04 15:51:35', additionalRights: '', inn: '', projects: 'Концерт', partnerCode: 'nsv.orel57', refCode: 'nsv.orel57', balance: '0.00', manager: 'Юля'}
        ],
        filters: {
            id: '',
            email: '',
            phone: '',
            name: '',
            organization: '',
            dateReg: null,
            inn: '',
            refCode: '',
            balance: '',
            project: null
        },
        currentTd: [
            {id: 'id', name: 'id'},
            {id: 'email', name: 'Email пользователя '},
            {id: 'phone', name: 'Телефон'},
            {id: 'name', name: 'Имя '},
            {id: 'organization', name: 'Название организации (ООО, ИП)'},
            {id: 'dateReg', name: 'Дата регистрации на сайте'},
            {id: 'additionalRights', name: 'Дополнительные права'},
            {id: 'inn', name: 'ИНН'},
            {id: 'projects', name: 'Проекты'},
            {id: 'partnerCode', name: 'Партнерский код'},
            {id: 'refCode', name: 'Реферальный код'},
            {id: 'balance', name: 'Баланс пользователя'},
            {id: 'manager', name: 'Ответственный менеджер'},
        ],
        tdTable: [
            {id: 'id', name: 'id'},
            {id: 'email', name: 'Email пользователя '},
            {id: 'phone', name: 'Телефон'},
            {id: 'name', name: 'Имя '},
            {id: 'organization', name: 'Название организации (ООО, ИП)'},
            {id: 'dateReg', name: 'Дата регистрации на сайте'},
            {id: 'additionalRights', name: 'Дополнительные права'},
            {id: 'inn', name: 'ИНН'},
            {id: 'projects', name: 'Проекты'},
            {id: 'partnerCode', name: 'Партнерский код'},
            {id: 'refCode', name: 'Реферальный код'},
            {id: 'balance', name: 'Баланс пользователя'},
            {id: 'manager', name: 'Ответственный менеджер'},
        ]
    },

    mutations: {
        setFilters(state, filters){
            state.filters = filters
        },
    },

    actions: {},
}