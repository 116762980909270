<template>
  <div class="wrap-switch">
    <input
        :disabled="disabled"
        class="form-check-input checkbox"
        :class="size"
        type="checkbox"
        @change="onChange"
        :id="checkboxId"
        :value="normalizedValue"
        :checked="normalizedValue"
    >
    <label class="form-check-label" :for="checkboxId"></label>
    <div :class="size">
      <span v-show="state" :class="[normalizedValue ? 'grey-color' : 'red-color']">
        {{ normalizedValue ? $t('UI.switch.on') : $t('UI.switch.off') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxSwitch",
  props: {
    disabled: {
      type: Boolean
    },
    modelValue: {
      required: false,
    },
    value: {
      required: false,
    },
    checkboxId: {
      type: [Number, String],
      required: true,
    },
    activeTheme: {
      type: String,
      required: false
    },
    numeric: {
      type: Boolean,
      required: false
    },
    //size: small, medium, large
    size: {
      type: String,
      default: "medium"
    },
    state: {
      type: Boolean,
      required: false
    }
  },
  emits: ['update:modelValue', 'on-change'],
  computed: {
    normalizedValue() {
      return this.normalizeValue(this.modelValue || this.value);
    }
  },
  methods: {
    normalizeValue(value) {
      if (value === "1" || value === 1 || value === true) return true;
      if (value === "0" || value === 0 || value === false) return false;
      return !!value;
    },
    onChange($event) {
      const isChecked = $event.target.checked;
      let emittedValue;

      if (this.numeric) {
        emittedValue = isChecked ? 1 : 0;
      } else if (typeof this.modelValue === "string") {
        emittedValue = isChecked ? "1" : "0";
      } else {
        emittedValue = isChecked;
      }

      this.$emit('update:modelValue', emittedValue);
      this.$emit('on-change', emittedValue);
    }
  },
}
</script>

<style scoped>
.wrap-switch{
  display: flex;
  align-items: center;
  width: fit-content;

  .grey-color {
    color: var(--grey-text);
  }

  .red-color {
    color: #F84B4B;
  }

  .grey-color,
  .red-color {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .small{
    height: 22px;
  }

  .medium{
    height: 26px;
  }

  .large{
    height: 32px;
  }

  .form-check-input.checkbox {
    //position: absolute;
    //z-index: -1;
    //opacity: 0;
    //margin: 10px 0 0 20px;
  }

  .form-check-input.checkbox + label {
    position: relative;
    padding: 0 0 0 25px;
    cursor: pointer;
  }

  .form-check-input.checkbox + label:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -22px;
    width: 40px;
    height: 22px;
    background: var(--dark-grey-background);
    border-radius: 24px;
    transition: .2s;
    cursor: pointer;
  }

  .form-check-input.checkbox + label:after {
    content: '';
    position: absolute;
    top: -8px;
    left: -19px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--main-background);
    transition: .2s;
  }

  .form-check-input.checkbox:checked + label:before {
    background: var(--brand);
  }

  .form-check-input.checkbox:checked + label:after {
    left: -3px;
  }

  .small{
    & + label:before {
      top: -10px;
      left: -22px;
      width: 40px;
      height: 22px;
    }

    & + label:after {
      top: -8px;
      left: -18px;
      width: 18px;
      height: 18px;
    }
  }

  .medium{
    & + label:before {
      top: -14px;
      left: -26px;
      width: 44px;
      height: 26px;
    }

    & + label:after {
      top: -11px;
      left: -22px;
      width: 20px;
      height: 20px;
    }

    &:checked + label:after {
      left: -5px;
    }
  }

  .large{
    & + label:before {
      top: -20px;
      left: -34px;
      width: 52px;
      height: 32px;
    }

    & + label:after {
      top: -17px;
      left: -30px;
      width: 26px;
      height: 26px;
    }

    &:checked + label:after {
      left: -12px;
    }
  }

  .form-check-input.checkbox[disabled] + label,
  .form-check-input.checkbox[disabled] + label:before {
      cursor: not-allowed;
      background: var(--svg-outline);
  }

  .form-check-input.checkbox[disabled] + label,
  .form-check-input.checkbox:checked[disabled] + label:before {
      cursor: not-allowed;
      background: #A9EBC1;
  }
}

</style>