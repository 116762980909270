<template>
<!--  <div v-if="isSideOpen" @click="isSideOpen = false" class="dark-background"></div>-->
  <div class="side-menu"
       :class="[{opened : isSideOpen}, {mini : $store.state.global.miniSb}]"
       @click="isActiveMiniMenu && !isSideOpen ? openSideMenu() : false"
  >
    <div style="max-width: 228px">
      <dropdown
        v-model="$store.state.projects.currentProject"
        :items="projects"
        :placeholder="$t('sideMenu.chooseProject')"
        :itemText="'name'"
        :width="isActiveMiniMenu && !isSideOpen ? '45px' : '100%'"
        :class="{'events-none': !isSideOpen && isActiveMiniMenu }"
        @on-change="setProject"
      >
        <template #top-menu>
          <vue-input v-model="searchProject" search :size="'small'" :placeholder="$t('UI.placeholder.search')"/>
        </template>
      </dropdown>

      <div class="current-user" v-if="isSideOpen || !isActiveMiniMenu">
        <span>{{ $t('sideMenu.user') }}: </span>{{ $store.state.auth.user.referral_abbreviation }}{{ $store.state.auth.user.id }}
      </div>

      <div @click="$router.push('/create-project')"
           class="create-btn"
           :class="{'mobile': !isSideOpen && isActiveMiniMenu }"
      >
        <img :src="`${$store.state.global.publicPath}side-menu-icons/side-menu-icon-plus.svg`" alt="plus">
        <template v-if="isSideOpen || !isActiveMiniMenu">
          <div class="text">{{ $t('sideMenu.newProject') }}</div>
        </template>
      </div>

      <side-menu-create-project-modal :showModal="isOpenModal" @closeModal="isOpenModal = false"/>

      <div>
        <side-menu-list
          :isSideOpen="isSideOpen"
          @open-side="openSideMenu()"
          @close-side="closeSide()"
        />

<!--        <div @click="toggleTheme" class="night-mode"-->
<!--             :class="{'events-none': !isSideOpen && isActiveMiniMenu }">-->
<!--          <img :src="`${$store.state.global.publicPath}side-menu-icons/side-menu-icon-night.svg`" alt="">-->

<!--          <div v-if="!isActiveMiniMenu || this.isSideOpen"-->
<!--            class="checkbox-wrapper">-->
<!--            <div class="text" >-->
<!--              {{$t("sideMenu.nightMode")}}-->
<!--            </div>-->

<!--            <SwitchCheckbox v-if="$store.state.global.miniSb === false"-->
<!--                      :activeTheme="activeTheme"-->
<!--                      @on-change="toggleTheme"-->
<!--                      :checkboxId="checkboxId"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="logos"
         v-if="!isActiveMiniMenu || this.isSideOpen">
      <side-menu-logo />

      <div class="subtitle">
        © {{$store.state.global.isMessageHelp ? 'Message.help' : 'Wahelp'}}, 2024
      </div>
    </div>
  </div>
  <div class="close-backdrop" v-if="isActiveMiniMenu && isSideOpen" @click.prevent.stop="openSideMenu"/>
</template>

<script>
import TariffService from "@/services/tariffService";
import SideMenuList from "@/components/SideMenu/SideMenuList.vue";
import SideMenuLogo from "@/components/SideMenu/SideMenuLogo.vue";
import Dropdown from "@/components/UI/Dropdown.vue";
import SideMenuCreateProjectModal from "@/components/SideMenu/SideMenuCreateProjectModal.vue";
import {mapMutations, mapState} from "vuex";
import VueInput from "@/components/UI/VueInput.vue";
import store from "@/store";
import resolutionMixin from "@/mixins/resolutionMixin.js";
export default {
  name: "SideMenu",
  components: {
    VueInput, SideMenuCreateProjectModal, Dropdown, SideMenuLogo, SideMenuList
  },
  mixins: [ resolutionMixin ],
  data() {
    return{
      checkboxId: 'checkbox-side',
      userTheme: "light-theme",
      lightTheme: localStorage.getItem("user-theme") === 'light-theme',
      filterProject:"",
      chooseOpen: false,
      menu_items: [],
      isSideOpen: false,
      activeTheme: '',
      searchProject: '',
      isOpenModal: false
    }
  },
  computed: {
    ...mapState('projects', ['allProjects']),
    ...mapState('global', ['isMessageHelp']),

    isActiveMiniMenu(){
      return this.resolution.width <= 1200
    },

    projects() {
      if (this.searchProject.length) {
        return this.allProjects.filter(project => project.name.toLowerCase().includes(this.searchProject.toLowerCase()));
      }

      return this.allProjects;
    }
  },
  methods: {
    closeSide() {
      setTimeout(()=>{
        this.isSideOpen = false;
      },200)
    },
    async setProject(project) {
      this.$store.state.projects.currentProjectId = project.id
      this.$store.dispatch('projects/getProject', project.id)

      if(this.isMessageHelp){
        await TariffService.getProjectTariff(this.$store.state.projects.currentProjectId);
      }
    },
    openSideMenu() {
      this.isSideOpen = !this.isSideOpen;
    },
    //Если нужно определять тему по предпочтениям пользователя
    // getMediaPreference() {
    //   const hasDarkPreference = window.matchMedia(
    //       "(prefers-color-scheme: dark)"
    //   ).matches;
    //   if (hasDarkPreference) {
    //     return "dark-theme";
    //   } else {
    //     return "light-theme";
    //   }
    // },
    getTheme() {
      return localStorage.getItem("user-theme");
    },
    // selectProject(option) {
    //   this.$store.dispatch('projects/selectProject', option.id).catch(()=>{})
    //
    // },
  },
  async created(){
    // этот лоудер используется на главноем экране
    this.$store.state.tariff.isActiveLoaderLoadingProjects = true
    if(localStorage.getItem("Project") || this.$store.state.projects.currentProjectId){
      await this.$store.dispatch('projects/getProject')
    }
    await this.$store.dispatch('projects/getAllProjects')

    if(!localStorage.getItem("Project") || localStorage.getItem("Project") === null) {
      if(this.$store.state.projects?.allProjects && this.$store.state.projects.allProjects.length){
        const projectWhatbot = this.$store.state.projects.allProjects.find( p => p.name.includes("Whatbot"))
        if(projectWhatbot){
          this.$store.state.projects.currentProjectId = projectWhatbot.id;
          this.$store.state.tariff.chosenProject.id = projectWhatbot.id;
        }else{
          this.$store.state.projects.currentProjectId = this.$store.state.projects.allProjects[0].id;
          this.$store.state.tariff.chosenProject.id = this.$store.state.projects.allProjects[0].id;
        }
      }

      await this.$store.dispatch('projects/getProject')
    }

    if(this.isMessageHelp){
      await TariffService.getProjectTariff(this.$store.state.projects.currentProjectId);
    }

    this.$store.state.tariff.isActiveLoaderLoadingProjects = false
  }
}
</script>

<style lang="scss" scoped>
.current-user {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}
.create-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  padding: 8px 16px !important;
  width: 100%;
  background: var(--grey-background);
  border-radius: 10px;

  .text{
    width: fit-content;
  }

  &.mobile {
    width: 44px !important;
    img {
      margin-right: 0 !important;
    }
    .text {
      display: none;
    }
  }
  img {
    margin-right: 8px;
  }
}

.events-none {
  pointer-events: none;
}

  *{
    font-size: 14px;
    line-height: 20px;
    color: var(--default-text);
  }
  .logos {
    padding-left: 10px;
    margin-top: 20px;
  }
  .side-menu{
    width: 228px;
    max-width: 228px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px;

    .select {
      margin-top: 32px;
    }

    &.mini {
      min-width: auto;
      .night-mode{
        padding: 14px 12px;
        width: 44px;
        height: 44px;
        .text{
          display: none;
        }
      }
      .subtitle{
        display: none;
      }
      .new-project{
        padding: 14px 12px;
        width: 44px;
        height: 44px;
        .text{
          display: none;
        }
      }

    }
  }
  .night-mode{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 14px 0 14px 44px;
    width: 100%;
    height: 48px;
    margin-top: 4px;
    cursor: pointer;
    border-radius: 12px;

    .checkbox-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text {
        margin-right: 5px;
      }
    }

    &:hover{
      background: var(--grey-background);
    }

    img{
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .subtitle{
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: var(--grey-text);
    margin-top: 8px;
  }
  .main-logo{
    .logo{
      display: flex;
      align-items: center;
      svg{

        width: 32.05px;
        height: 32px;
        margin-right: 9.32px;

      }
      .text{
        font-weight: 800;
        font-size: 22px;
      }
    }
  }
  .hamburger-btn{
    display: none;
  }
  .dark-background{
    display: none;
  }
  @media (max-width: 1200px) {
    .dark-background{
      display: flex;
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.7;
    }
    .side-menu{
      top: 0;
      transition: 0.3s all ease;
      background: var(--main-background);
      z-index: 601;
      padding: 10px 0 0;
      width: 60px;
      min-width: 40px;
      max-width: 40px;
      margin-left: 0;
      position: fixed;
      cursor: pointer;
      &.opened{
        height: 100vh;
        overflow: scroll;
        width: 272px;
        min-width: 272px;
        position: fixed;
        top: 0;
        padding-top: 10px;
        margin-left: -10px;
        padding-left: 10px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

.close-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.6;
  z-index: 600;
}
</style>