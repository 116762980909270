<script setup>
import {useI18n} from "vue-i18n";
import Toast from "@/components/UI/Toast.vue";
import BannerAmo from "@/components/Tariff/BannerAmo.vue";
import store from "@/store";
import PartnersBalanceAmountWithdrawModal
  from "@/components/Partners/PartnersBalance/PartnersBalanceAmountWithdrawModal.vue";
const { t } = useI18n({useScope: 'global'});
</script>

<template>
    <div class="container-app"
         :class="{
              'container-messenger': $route.name === 'messenger' || $route.name === 'messenger_iframe',
              'message-help': isMessageHelp,
              'no-padding': isRouteWithoutAuth || !$store.state.auth.isLoggedIn,
          }"
    >

      <BannerAmo v-if="appConfig && !isRouteWithoutAuth && $store.state.auth.isLoggedIn"/>

      <div class="app-wrapper" :class="{'column': isActiveHeaderAndFooter}">
        <div
            class="header-app__wrapper"
            v-html="$store.state.global.headerFooter.header"
            v-if="isActiveHeaderAndFooter && headerFooter"
        ></div>


        <SideMenu v-if="$store.state.auth.isLoggedIn && !$store.state.global.isFramed && !isRouteWithoutAuth"/>
        <div class="content-wrapper"
             v-if="$store.state.global.appConfig"
             :style="isActiveHeaderAndFooter || !($store.state.auth.isLoggedIn && !$store.state.global.isFramed && !isRouteWithoutAuth) ? {width: '100%'} : {}"
            :class="{'no-padding': isRouteWithoutAuth || !$store.state.auth.isLoggedIn, 'column': isActiveHeaderAndFooter}">


          <router-view/>
        </div>

        <div
            class="footer-app__wrapper"
            v-html="$store.state.global.headerFooter.footer"
            v-if="isActiveHeaderAndFooter && headerFooter"
        ></div>
      </div>

    </div>

  <toast v-if="$store.state.global.isShowToast"/>

  <notifications position="bottom right" />

  <MiniChatComponent v-if="!isMobile && !isActiveMiniChat && existingSocket" :existing-socket="existingSocket"/>

</template>
<script>
import SideMenu from "@/components/SideMenu/SideMenu.vue";
import globalService from "@/services/globalService";
import {mapState} from "vuex";
import MiniChatComponent from "wahelp-chat"
import moment from "moment";
import { ws } from "@/services/webSocketService";
import IncreaseTariffsModal from "@/components/IncreaseTariffsModal.vue";
import { setCookie } from "@/utils/setCookie";

export default {
  data(){
    return{
      windowWidth: window.innerWidth,
      errorConnectIntegration: [],
      isMobile: document.documentElement.clientWidth < 900,
      existingSocket: null,
    }
  },
  components:{
    SideMenu,
    MiniChatComponent,
    IncreaseTariffsModal
  },
  watch:{
    isActiveHeaderAndFooter:{
      async handler(newVal){
        if(newVal){
          await this.$nextTick(() => {
            this.addDesktopListeners();
            this.addMobileListeners();
          });
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('global', ['isMessageHelp', 'appConfig', 'headerFooter']),
    ...mapState('projects', ['currentProject', 'allProjects']),

    isActiveHeaderAndFooter() {
      return this.headerFooter && (
          this.$route.name === 'Login' || this.$route.name === 'LoginOpenId' ||
          (this.$route.name === 'help' && !this.$store.state.auth.isLoggedIn)
      )
    },
    isRouteWithoutAuth() {
      return (this.$route.name === 'site' ||
          this.$route.name === 'Login' ||
          this.$route.name === 'create-project' ||
          this.$route.name === 'marketplace' ||
          this.$route.name === 'marketplace-setting' ||
          this.$route.name === 'marketplace-whatsapp' ||
          this.$route.name === 'LoginOpenId'
      );
    },
    isActiveMiniChat() {
      return (this.$route.name === 'site' ||
          this.$route.name === 'Login' ||
          (this.$route.name === 'create-project' && (this.isMessageHelp || (this.allProjects && this.allProjects.length))) ||
          this.$route.name === 'marketplace' ||
          this.$route.name === 'marketplace-setting' ||
          this.$route.name === 'marketplace-whatsapp' ||
          this.$route.name === 'LoginOpenId');
    },
  },
  created() {
    if (process.env.NODE_ENV === 'production') {
      // Не трогать - бой!
      this.$store.state.projects.currentProjectId = window.bot_id !== undefined ? window.bot_id : localStorage.getItem('Project')
    } else {
      // Меняем проект тут.
      // Whatbot -- 1
      // Bitrix24 testing -- 11941
      this.$store.state.projects.currentProjectId = localStorage.getItem('Project')
    }
  },
  methods: {
    // Добавление обработчиков для desktop
    addDesktopListeners() {
      const langItems = document.querySelectorAll('#langList .lang-list__item');

      langItems.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          const lang = e.target.textContent.trim();
          this.handleLanguageChange(lang);
          document.getElementById('currentLang').setAttribute('data-lang', lang);
          document.getElementById('currentLang').className = lang;
          document.getElementById('currentLang').textContent = lang;
        });
      });
    },

    // Добавление обработчиков для mobile
    addMobileListeners() {
      const mobLangItems = document.querySelectorAll('#mobLangList .lang__item');
      mobLangItems.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          const lang = e.target.textContent.trim();
          this.handleLanguageChange(lang);
          document.getElementById('mobLangList').setAttribute('data-lang', lang);
          mobLangItems.forEach((el) => el.classList.remove('lang__item_active'));
          item.classList.add('lang__item_active');
        });
      });
    },

    // Обработка изменения языка
    handleLanguageChange(lang) {
      const date = new Date(Date.now() + 30 * 86400e3).toUTCString();

      document.cookie = `site_lang=${lang}; path=/; expires=${date}`;
      document.cookie = `site_lang=${lang}; path=/; expires=${date}`;

      location.reload();
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    setFavicon() {
      const hostname = window.location.hostname;

      if (hostname === 'message.help') {
        let faviconPath = `${this.$store.state.global.publicPath}favicon-help.png`;
        const link = document.querySelector("link[rel~='icon']");
        if (!link) {
          const newLink = document.createElement('link');
          newLink.rel = 'icon';
          newLink.href = faviconPath;
          document.head.appendChild(newLink);
        } else {
          link.href = faviconPath;
        }
      }
    },
    supportsEmoji() {
      const canvas = document.createElement("canvas");
      document.body.appendChild(canvas);

      const ctx = canvas.getContext("2d");
      canvas.width = canvas.height = 1;
      ctx.fillText("😗", -4, 4);
      const supports = ctx.getImageData(0, 0, 1, 1).data[3] > 0;

      document.body.removeChild(canvas);

      return supports;
    }
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('LANG');

    if (lang) {
      setCookie('site_lang', lang, 365);

      this.$i18n.locale = lang;
      this.$store.state.global.language = lang;
    }

    if (localStorage.getItem('SupportsEmoji')) {
      this.$store.state.global.isSupportEmoji = localStorage.getItem('SupportsEmoji') === "true";
    } else {
      const isSupportEmoji = this.supportsEmoji()
      localStorage.setItem('SupportsEmoji', isSupportEmoji.toString());
      this.$store.state.global.isSupportEmoji = isSupportEmoji;
    }
    console.log(localStorage.getItem('SupportsEmoji'))
    window.addEventListener('resize', this.updateWindowWidth);

    moment.locale(this.$store.state.global.language);

    this.setFavicon();

    try {
      this.$store.state.global.isFramed = window != window.top || document != top.document || self.location != top.location;
    } catch (e) {
      this.$store.state.global.isFramed = true;
    }

    if(this.$store.state.global.isFramed){
      await this.$store.dispatch('projects/getProject')
    }

    if (!this.$store.state.global.appConfig) {
      await this.$store.dispatch("global/getAppConfig");
    }

    if (!this.$store.state.global.headerFooter) {
      const {data: headerFooter} = await globalService.getHeaderFooter()
      this.$store.state.global.headerFooter = headerFooter
    }

    await globalService.getSideMenu();
    this.$webSocketsConnect();
    this.existingSocket = ws;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
}
</script>

<style v-if="isActiveHeaderAndFooter">
@import url('~@/assets/css/site-common.css');
</style>

<style lang="scss">
#app {
  width: 100% !important;
}
html{

}
@import "~@/assets/fonts/fonts.scss" ;
@import "@/styles/typography.scss";
*{
  box-sizing: border-box;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  color: var(--default-text);

  b {
    font-weight: bold !important;
  }

  i {
    font-style: italic !important;
  }

  .vc-colorpicker{
    background-color: var(--grey-background) !important;
  }
  .vc-fk-colorPicker__inner{
    background-color: var(--grey-background) !important;
  }
  .vc-display .vc-alpha-input>input{
    color: var(--default-text) !important;
  }
  .vc-display .vc-color-input input{
    background-color: var(--main-background) !important;
    color: var(--default-text) !important;
  }
  .vc-display .vc-alpha-input{
    background-color: var(--main-background) !important;
  }
  body{
    margin: 0;
    background: var(--main-background);
    overflow: auto;
  }
  ul{
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }

  button{
    background: var(--main-background);
    color: var(--default-text);
    border: none;
    cursor: pointer;
  }
  a{
    text-decoration: none;
    color: var(--default-text);
  }
  h1, h2, h3, h4, h5{
    padding: 0;
    margin: 0;
  }
  h1{
    font-family: 'RF Dewi Expanded';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--default-text);
  }
  h4{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}
.container-app{
  width: 100%;
  padding-left: 24px;
  padding-top: 0;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;

  .header-app__wrapper,
  .footer-app__wrapper{
    width: 100%;
  }

  @media (max-width: 1200px) {
    padding: 10px!important;
  }

  &.no-padding {
    padding: 0 !important;
  }
}
.app-wrapper{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;

  &.column {
    flex-direction: column;
    justify-content: space-between;
  }
}
.content-wrapper{
  padding-left: 32px;
  width: calc(100% - 228px);
  margin-top: -5px;
  height: 100vh;
  @media(max-width: 1200px){
    width: calc(100% - 35px);
    margin: 0 0 0 42px;
    padding-left: 10px;
    padding-top: 0;
  }

  &.column {
    height: auto;
  }

  &.no-padding {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
.title{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--default-text);
  margin-bottom: 15px
}
.created-in{
  .logo{
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    font-weight: 700;
    svg{
      margin-right: 4.8px;
      width: 16.5px;
      height: 16.47px;
      //path{
      //  fill: var(--default-text);
      //}
    }
  }

}
.container-app.container-messenger{
  overflow: hidden;
  padding: 0!important;
  .side-menu.mini{
    padding: 24px 20px;
  }
  .contacts-wrapper{
    height: auto;
  }
  .content-wrapper{
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
  }
  .messenger {
    height: 100%;
  }
  @media (max-width: 400px) {

  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.fade {
  transition: opacity .15s linear;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
@media (min-width: 576px){
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--main-background);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 10px;
  outline: 0;
  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    .modal-title {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
    }
    .modal-header .btn-close {
      padding: 0.5rem;
      margin: -0.5rem -0.5rem -0.5rem auto;
    }
    .btn-close {
      box-sizing: content-box;
      width: 1em;
      height: 1em;
      padding: 0.25em;
      color: var(--default-text);
      background: transparent url("~@/assets/img/icons/close.svg") center/1em auto no-repeat;
      border: 0;
      border-radius: 0.25rem;
      opacity: .5;
    }
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    padding-top: 0;
  }
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
  }
  .table>:not(:first-child) {
    border-top: 2px solid currentColor;
  }
  .table>:not(caption)>*>* {
    padding: 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
}

.mt-4 {
  margin-top: 1.5rem!important;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: .5;
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: var(--main-background);
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
}
.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0,0,0,.2);
  transform: translate(100%);
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.offcanvas-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.show {
  opacity: .5;
}
.offcanvas.show {
  transform: none;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  &.short {
    margin: 10px 0;
    min-height: auto;
  }
  .loading__container  {
    width: auto;
  }
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
//[data-title] {position: relative}
//[data-title]:after {
//  content: attr(data-title);
//  opacity: 0;
//  visibility: hidden;
//  z-index: 1000;
//  position: absolute;
//  top: 2em;
//  left: 30%;
//  padding: 8px 18px;
//  font-size: 14px;
//  font-weight: 500;
//  white-space: nowrap;
//  color: #444;
//  background: var(--main-background);
//  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
//  border-radius: 10px;
//}
//[data-title]:hover:after {
//  opacity: 1;
//  visibility: visible;
//  -moz-transition: all 0.2s ease-out 0.5s;
//  -webkit-transition: all 0.2s ease-out 0.5s;
//  -o-transition: all 0.2s ease-out 0.5s;
//  -ms-transition: all 0.2s ease-out 0.5s;
//  transition: all 0.2s ease-out 0.5s;
//}
.tab-content>.tab-pane {
  display: none;
}
.tab-content>.active {
  display: block;
}
.btn-red{
  background: #d55757 !important;
  color: white !important;
  font-weight: 500;
  font-size: 13.3333px;
  border-radius: 14px;
  padding: 14px 24px;
  &:hover{
    background: #a94343 !important;
  }
}
.tabs-content{
  padding: 0 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
:root {
  --help: #3C73FF;
  --help-hover: #2557D6;
  --help-focus: #0035BD;
  --help-disabled: #B3C8FF;
}
.message-help {
  .effect-1::before{
    background-color: #20BDFF;
  }

  .accent_btn{
    background: radial-gradient(250.76% 250.74% at 50.15% -54.41%, #20BDFF 0%, #5433FF 100%) !important;
    color: white !important;

    &:hover{
      box-shadow: 0px 10px 25px 0px rgba(61, 111, 255, 0.50) !important;
    }
  }

  .reports-audit-archive__row{
    color: var(--help) !important;
  }

  .footer__docs_links,
  .footer__contacts_link{
    color: var(--help) !important;
  }

  .banner{
    background: var(--help) !important;

    &__close {
      img{
        filter: brightness(0) invert(1) !important;
      }
    }

    &__content {
      &__text {
        color: #FFFFFF !important;
      }
    }
  }

  .replenishment__amount__cards__item:hover{
    border: 1px solid var(--help) !important;
  }

  textarea:hover, textarea:focus{
    border: 1px solid var(--help) !important;
  }

  .transparent{
    &:hover {
      border: 1px solid var(--help) !important;
    }

    &.show {
      border: 1px solid var(--help) !important;
    }
  }

  .select-window {
    &:hover {
      border: 1px solid var(--help) !important;
    }

    &.show {
      border: 1px solid var(--help) !important;
    }
  }

  .btn-dropdown {
    &:hover {
      border: 1px solid var(--help) !important;
    }

    &.show{
      border: 1px solid var(--help) !important;
    }
  }

  .dp__today {
    border: 1px solid var(--help) !important;
  }

  .dp__range_end, .dp__range_start, .dp__active_date {
    background: var(--help) !important;
    color: white !important;
  }

  .dp__input {
    &:hover {
      border: 1px solid var(--help) !important;
    }

    &:focus {
      outline: none !important;
      border: 1px solid var(--help) !important;
    }
  }

  .date-picker .dp__input_focus {
    border: 1px solid var(--help) !important;
  }

  .dp__theme_light {
    --dp-primary-color: var(--help) !important;
    --dp-border-color-hover: var(--help) !important;
    --dp-success-color: var(--help) !important;
    --dp-success-color-disabled: var(--help-disabled) !important;
  }

  .create-project__unpaid-projects p a {
    color: #0086F9 !important;
  }

  .footer__docs_links,
  .footer__contacts_link{
    color: var(--help);
  }

  .main-header__item:before{
    background-color: var(--help);
  }

  .create-project__integrations .create-project__card.chosen {
    outline: 3px solid var(--help) !important;
  }

  .create-project__channels .create-project__card.chosen{
    border: 3px solid var(--help) !important;
  }

  .input__wrap__submit,
  .options__navig__item__active,
  .green {
    color: white !important;
    background: var(--help) !important;

    .btn__content {
      color: white !important;
    }

    a{
      color: white !important;
    }

    &:hover {
      background: var(--help-hover) !important;
      color: white !important;

      .btn__content {
        color: white !important;
      }
    }
    &:active {
      background: var(--help-focus) !important;
      color: white !important;

      .btn__content {
        color: white !important;
      }
    }
    &[disabled]{
      background: var(--help-disabled) !important;
      color: white !important;

      .btn__content {
        color: white !important;
      }
    }
  }

  .input__wrap__input {
    &:focus {
      border: 1px solid var(--help) !important;
    }
  }
  .input__wrap__info__green,
  .input__wrap__accept a {
    color: var(--help) !important;
  }

  .auth__gray__btn:hover {
    background: var(--help-hover) !important;
    color: white !important;
  }

  .form-check-input.checkbox:checked + label:before,
  .checkbox:checked + label:before{
    background: var(--help) !important;
  }

  .form-check-input.checkbox[disabled] + label,
  .form-check-input.checkbox[disabled] + label:before{
    background: #A3C1FF !important;
  }

  .radio__wrapper .radio__content .radio__item .radio:checked + label::before {
    background-image: url('@/assets/img/icons/radio-active-blue.svg') !important;
  }

  .wrapper .check_box__wrapper .check_box:checked + label::before {
    background-image: url('@/assets/img/icons/checkbox-active-blue.svg') !important;
  }

  .wrapper .check_box__wrapper .main + label::before {
    background-image: url('@/assets/img/icons/checkbox-active-main-blue.svg') !important;
  }

  .lds-ring div{
    border: 2px solid var(--help) !important;
    border-color: var(--help) transparent transparent transparent !important;
  }

  .auth__brand {
    width: 150px;
    height: 40px;
  }

  .dropdown{
    &.focus,
    &:hover{
      border: 1px solid var(--help) !important;
    }

    &.highlight {
      background: var(--help) !important;
      color: white !important;

      .dropdown__text {
        color: white !important;
      }
    }

    &__li{
      .selected {
        color: var(--help) !important;
      }
    }
  }

  .date-picker .dp__input_focus{
    border: 1px solid var(--help) !important;
  }

  input{
    &:hover, &:focus{
      border: 1px solid var(--help) !important;
    }

    &.error{
      border: 1px solid #F29C9C !important;
    }

    &[disabled] {
      border: 1px solid #DBDBDB !important;
    }
  }

  .top-navig__item--active {
    border-bottom: 2px solid var(--help) !important;
  }

  .tariff_wrapper .project__header__status span.active{
    color: var(--help) !important;
  }

  .progress .progress-expired {
    border-bottom: 3px solid var(--help) !important;
  }

  .tariff-payment__discount {
    color: var(--help) !important;
  }

  .payment-method__radio input[type=radio]:checked + label,
  .payment-method__radio label:hover {
    border: 1px solid var(--help) !important;
  }

  .projects {
    .active {
      border: 1px solid var(--help) !important;
    }
  }
}
.em {
  display: inline-block;
  margin: 0 2px;
  color: transparent;
  caret-color: black;
  &::selection {
    color: transparent;
    background: var(--brand);
  }
}
</style>