import Api from '@/api/index.js'
import {basePath, requestHandler} from '@/utils/apiHelpers';
import store from "@/store";
import PartnersService from "@/services/partnersService";


class TariffService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getFullProjects = async function () {
            return requestHandler(async () => {
                let response = await api.tariff.getFullProjects( {
                    total: 100
                });
                if (!response.data.length) {
                    store.state.tariff.fullProjects = null
                } else {
                    store.state.tariff.fullProjects = response.data;
                    if (store.state.auth.user.is_partner) {
                        let page = 1
                        let hasMorePages = true
                        let observer = false
                        while (hasMorePages) {
                            const res = await PartnersService.getPartnersUsers(page, observer, 100, true)

                            if (res.last_page > page) {
                                page += 1
                                observer = true
                            } else {
                                hasMorePages = false
                            }
                        }

                        store.state.tariff.partnerProjects = store.state.partners.clients.flatMap(client => client.projects);
                        store.state.tariff.fullProjects.forEach(fullProject => {
                            const partnerProject = store.state.tariff.partnerProjects.find(partnerProject => partnerProject.id === fullProject.id);
                            if (partnerProject) {
                                fullProject.price_partner = partnerProject.price_partner;
                            }
                        });
                    }
                }

            })
        }

        this.getProjectInfo = async function (id) {
            return requestHandler(async () => {
                let response = await api.tariff.getProjectInfo(id);

                if(+id === +store.state.projects.currentProjectId){
                    store.state.projects.currentProject = response.data
                }

                return response.data
            })
        }

        this.getChannelStatuses = async function (id) {
            return requestHandler(async () => {
                let response = await api.tariff.getChannelStatuses(id);
                response.data.forEach(status => {
                    let index = store.state.tariff.connectedServices.findIndex(service => service.uuid === status.uuid);
                    if(index !== -1) {
                        store.state.tariff.connectedServices[index] = {...store.state.tariff.connectedServices[index], needStatus: true, status: status.status};
                    }
                })
            })
        }

        this.getProjectTariff = async function (id, isAssignValues = true) {
            return requestHandler(async () => {
                let tariffs = await api.tariff.getProjectTariff(id);
                if(isAssignValues){
                    let tariffsConnected = await api.tariff.getProjectTariffConnected(id);
                    store.state.tariff.connectedServicesTariffs = tariffsConnected.data
                    store.state.tariff.projectTariff = tariffs.data
                }

                return tariffs
            })
        }


        this.createChannel = function (data) {
            return requestHandler(async () => {
                let response = await api.tariff.createChannel(store.state.tariff.chosenProject.id, data);
                return response.data
            })
        }

        this.deleteChannel = async function (uuid) {
            return requestHandler(async () => {
                let response = await api.tariff.deleteChannel(store.state.tariff.chosenProject.id, uuid);

                return response.data
            })
        }

        this.createService = function (data) {
            return requestHandler(async () => {
                let response = await api.tariff.createService(store.state.tariff.chosenProject.id, data);
                return response.data
            })
        }

        this.deleteService = function (service) {
            return requestHandler(async () => {
                let response = await api.tariff.deleteService(store.state.tariff.chosenProject.id, service);
                return response.data
            })
        }

        this.createIntegration = function (data) {
            return requestHandler(async () => {
                let response = await api.tariff.createIntegration(store.state.tariff.chosenProject.id, data);
                return response.data
            })
        }

        this.deleteIntegration = function (service) {
            return requestHandler(async () => {
                let response = await api.tariff.deleteIntegration(store.state.tariff.chosenProject.id, service);
                return response.data
            })
        }

        this.payProject = async function (data) {
            return requestHandler(async () => {
                let response = await api.tariff.payProject(store.state.tariff.chosenProject.id, data);

                return response.data
            })
        }

        this.replenishBalance = async function (data) {
            return requestHandler(async () => {
                let response = await api.tariff.replenishBalance(data);

                return response.data
            })
        }

        this.payProjectsFromBalance = async function (data) {
            return requestHandler(async () => {
                let response = await api.tariff.payProjectsFromBalance(data)

                if (response.success) {
                    store.state.auth.user.balance = store.state.auth.user.balance - store.state.tariff.paymentSum;
                }

                return response.data
            })
        }
        this.payProjectsFromBalancePartner = async function (data, id) {
            return requestHandler(async () => {
                let response
                for (const itemId of id) {
                    const needleProject = store.state.tariff.chosenProjects.find(cp => cp.id === itemId)
                    if( needleProject.price_partner !== 0 ) {
                        response = await api.tariff.payProjectsFromBalancePartner(data, itemId)
                    }

                }

                if (response.success) {
                    store.state.auth.user.balance = store.state.auth.user.balance - store.state.tariff.paymentSum;
                }

                return response.data
            })
        }

        this.payProjectsFromCard = async function (data) {
            return requestHandler(async () => {
                let response = await api.tariff.payProjectsFromCard(data);

                return response.data
            })
        }

        this.postTrustPayment = async function (id) {
            return requestHandler(async () => {
                let response = await api.tariff.postTrustPayment(id);

                return response.data
            })
        }

        this.getTransactions = async function (page, isFromObserver, filters) {
            return requestHandler(async () => {
                let filtersQuery = '';

                if (filters) {
                    filtersQuery = Object.keys(filters).map(key => filters[key] ? `&${key}=${filters[key]}` : '').join('');
                }

                let response = await api.tariff.getTransactions(page, filtersQuery);

                if (isFromObserver) {
                    store.commit('tariff/setTransactions', [ ...store.state.tariff.transactions, ...response.data ]);
                } else {
                    store.commit('tariff/setTransactions', response.data);
                }

                return response
            })
        }

        this.getCounterParty = async function () {
            return requestHandler(async () => {
                return await api.tariff.getCounterParty()
            })
        }

        this.editCounterParty = async function (data) {
            return requestHandler(async () => {
                return await api.tariff.putCounterParty(data)
            })
        }

        this.getBillLink = async function (data) {
            return requestHandler(async () => {
                return await api.tariff.getBillLink(data)
            })
        }

        this.getAutoPayments = async function () {
            return requestHandler(async () => {
                let response = await api.tariff.getAutoPayments();
                return response.data
            })
        }

        this.deleteAutoPayment = async function (id) {
            return requestHandler(async () => {
                await api.tariff.deleteAutoPayment(id);
            })
        }

        this.changeTariff = async function (id, data) {
            return requestHandler(async () => {
                await api.tariff.changeTariff(id, data);
            })
        }

        this.createServiceWithoutTariffRecalculation = async function (id, data) {
            return requestHandler(async () => {
                const response = await api.tariff.createServiceWithoutTariffRecalculation(id, data);
                return response.data
            })
        }

        this.deleteServiceWithoutTariffRecalculation = async function (id, data) {
            return requestHandler(async () => {
                const response = await api.tariff.deleteServiceWithoutTariffRecalculation(id, data);
                return response.data
            })
        }

        this.payFromBalanceWithoutTariffRecalculation = async function (id, order_id) {
            return requestHandler(async () => {
                await api.tariff.payFromBalanceWithoutTariffRecalculation(id, order_id);
            })
        }
        this.sberCrmConnectionLink = async function () {
            return requestHandler(async () => {
                return await api.tariff.sberCrmConnectionLink();
            })
        }

        this.callRequest = async function (data) {
            return requestHandler(async () => {
                return await api.tariff.callRequest({
                    action: 'telephone_application',
                    ...data,
                });
            })
        }
    }
}

export default new TariffService()