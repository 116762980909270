import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getHistoryFilters: async (project_id, uuid, pageActive, numberOfRow, sortTypeDate, filtersStr) =>
        messCall(`/projects/${project_id}/channels/${uuid}/messages?with[]=user&with[]=record_link&with[]=template&page=${pageActive}&count=${numberOfRow}&sort=created_at&sort_type=${sortTypeDate}${filtersStr}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getLostHistory: async (project_id, messenger, pageActive, numberOfRow, sortTypeDate, filtersStr) =>
        messCall(`/projects/${project_id}/${messenger}?page=${pageActive}&count=${numberOfRow}&sort=created_at&sort_type=${sortTypeDate}${filtersStr}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app')

}
