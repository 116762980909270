import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getCategories: async () =>
        messCall(`/knowledge_base/category/`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    getCategoryById: async (id) =>
        messCall(`/knowledge_base/category/${id}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    postCategory: async (data) =>
        messCall(`/knowledge_base/category`, { method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    putCategory: async (data) =>
        messCall(`/knowledge_base/category/${data.id}`, { method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    deleteCategory: async (id) =>
        messCall(`/knowledge_base/category/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),

    searchArticles: async (search) =>
        messCall(`/knowledge_base/article/?filter=${search}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    getArticleById: async (id) =>
        messCall(`/knowledge_base/article/${id}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    postArticle: async (data) =>
        messCall(`/knowledge_base/article/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    putArticle: async (data) =>
        messCall(`/knowledge_base/article/${data.id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    deleteArticle: async (id) =>
        messCall(`/knowledge_base/article/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    postArticleFile: async (id, data) =>
        messCall(`/knowledge_base/article/${id}/files`, { method: 'post', data, format: 'form' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    deleteArticleFile: async (id, data) =>
        messCall(`/knowledge_base/article/${id}/files`, { method: 'delete', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),

    putArticleRating: async (id, data) =>
        messCall(`/knowledge_base/article/${id}/rating`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    getArticleComments: async (id) =>
        messCall(`/knowledge_base/article/${id}/comments`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    postArticleComment: async (id, data) =>
        messCall(`/knowledge_base/article/${id}/comments`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
}