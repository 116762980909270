<template>
  <div class="mini-radio-wrapper" :style="{width: width}">

    <div class="mini-radio__title-wrapper" v-if="title || explanation">
      <div class="mini-radio__title" v-if="title">
        {{ title }}
      </div>

      <tooltip-btn v-if="explanation" :width="widthExplanation" :text="explanation" hover>
        <img :style="{width: '16px', height: '16px', padding: '0'}" src="@/assets/img/icons/question-outlined.svg" alt="question mark">
      </tooltip-btn>
    </div>


    <div class="mini-radio" :class="size">

      <template v-for="item in items">
        <input
            type="radio"
            :name="radioName"
            :id="`${radioName}-${item.id}`"
            :checked="modelValue && typeof modelValue === 'object' ? modelValue.id === item.id : modelValue === item.id"
        >

        <label
            :class="{ disabled: disabled }"
            :style="{ width: itemWidth, color: item?.color }"
            @click="updateValue(item)"
            :for="`${radioName}-${item.id}`"
        >

          <img v-if="item?.imgName" :src="require(`@/assets/img/${item.imgName}`)" alt="img">
          {{ item?.title }}

        </label>
      </template>
    </div>
  </div>
</template>

<script>
import TooltipBtn from "@/components/UI/TooltipBtn.vue";

export default {
  name: "Radio",
  components: {TooltipBtn},
  emits: ['update:modelValue', 'on-change'],
  props: {
    modelValue: {
      required: true
    },
    title:{
      type: String
    },
    explanation:{
      type: String
    },
    widthExplanation:{
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    radioName: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false
    },
    //size: small, medium, large, flexible
    size: {
      type: String,
      default: "medium"
    },
    disabled: {
      type: Boolean,
      required: false
    },
  },
  computed: {
    itemWidth() {
      if (this.width) {
        const unit = this.width.replace(/[\d]/g, '');
        const value = +this.width.replace(/[^\d]/g, '');

        return `${unit === '%' ? 100 : value / this.items.length}${unit}`;
      }

      return 'auto';
    }
  },
  methods: {
    updateValue(item) {
      this.$emit('update:modelValue', item);
      this.$emit('on-change', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.mini-radio{
  &-wrapper{
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 4px;
  }

  &__title-wrapper{
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__title{
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  &__img-mark{
    margin-top: 3px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &{
    width: 100%;
    background: var(--grey-background);
    display: flex;
    align-items: center;
    padding: 4px;
    user-select: none;

    input + label{
      background: transparent;
      padding: 4px 14px;
      font-family: 'Manrope';
      color: #676767;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
      white-space: break-spaces;
      width: auto;
      height: 100%;
      transition: color 0.2s ease-out, background 0.2s ease-out;
      text-align: center;
      &.disabled {
        pointer-events: none;
      }
      img{
        transition: filter 0.2s ease-out;
      }
    }

    input{
      display: none;

      & + label:hover{
        color: var(--default-text);

        img{
          filter: brightness(60%)
        }
      }

      &:checked + label{
        color: var(--default-text);
        background: var(--main-background);

        img{
          filter: brightness(60%)
        }
      }
    }

    @media (max-width: 600px) {
      input + label{
        font-size: 12px;
        padding: 4px;
      }
    }
  }
}

.small {
  border-radius: 10px;
  input + label {
    padding: 4px 14px;
    border-radius: 8px;
  }
}

.medium {
  border-radius: 10px;
  input + label {
    padding: 6px 14px;
    border-radius: 8px;
  }
}

.large {
  border-radius: 14px;
  input + label {
    padding: 10px 14px;
    border-radius: 12px;
  }
}

.flexible{
  border-radius: 12px;
  input + label {
    padding: 4px 14px;
    border-radius: 8px;
  }
}
</style>