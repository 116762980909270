import axios from "axios";

export default {
    namespaced: true,
    state: {
        //Настройка мини сайта пользователем
        siteUploadedFiles: {
          logo: null,
          file: null
        },
        sitePreferences: {
            config: {
                background: 'linear-gradient(340deg, #E9DEFA 0%, #FBFCDB 100%)',
                logo: '',
                sizeLogo: { id: 'size-1', size: '50px', top: '-25px' },
                companyName: '',
                file: 'default',
                title: '',
                subtitle: '',
                text: '',
                color: 'black',
                fontFamily: { id: 'font-family-1', title: 'Montserrat', value: 'Montserrat' },
                messengers: [],
                url: `https://${document.domain}/card/example`,
                button: {
                    text: '',
                    link: '',
                    background: ''
                },
                additionalLinks: [
                    {
                        text: '',
                        link: ''
                    }
                ],
                yandexMetricId: '',
                facebookPixelId: '',
            },
            link: ''
        },
        //Настройка виджета с чатом
        widgetProfilePic: null,
        widgetPreferencesMessenger: {
            title: '',
            subtitle: '',
            startMessage: '',
            profilePic: '',
            messengers: [],
        },
        //Настройка виджета со ссылками
        widgetPreferencesLinkTitles: {
            title: '',
            subtitle: '',
        },
        widgetPreferencesLink: [],
        widgetToken: '',
        //Настройка ссылки
        linkPreferences: {
            title: '',
            alias: '',
            message: '',
            phone: '',
        },
        //Ошибки
        uploadErrors: {
            logo: '',
            file: '',
            profilePic: '',
        },
        tools: {
            sites: [],
            widget: {
                messenger: {
                    title: '',
                    subtitle: '',
                    startMessage: '',
                    profilePic: '',
                    messengers: [],
                },
                links: []
            },
            links: []
        }
    },
    getters: {
    },
    mutations: {
        widgetEdit(state, needleId) {
            state.toolsStore.widgets.forEach(item => {
                if (item.id == needleId) {
                    if (item.widgetPreferencesMessenger) {
                        for (let key in state.widgetPreferencesMessenger) {
                            state.widgetPreferencesMessenger[key] = item.widgetPreferencesMessenger[key];
                        }
                        for (let key in state.widgetRenderSettingsMessenger) {
                            state.widgetRenderSettingsMessenger[key] = item.widgetRenderSettingsMessenger[key];
                        }
                    } else if (item.widgetPreferencesLink) {
                        for (let key in state.widgetPreferencesLink) {
                            state.widgetPreferencesLink[key] = item.widgetPreferencesLink[key];
                        }
                        for (let key in state.widgetPreferencesLinkTitles) {
                            state.widgetPreferencesLinkTitles[key] = item.widgetPreferencesLinkTitles[key];
                        }
                    }
                }
            });
        },
        chooseSiteBg(state, color) {
            state.sitePreferences.config.background = color;
        },
        removeTool(state, id) {
            for (let key in state.toolsStore) {
                state.toolsStore[key] = state.toolsStore[key].filter(item => {
                    if (item.id !== id) return item;
                })
            }
        },
        mineSocial(state, text) {
            state.sitePreferences.social.forEach(item => {
                if (item.mine) {
                    item.btnText = text;
                }
            })
        },
    },
    actions: {
    }
}