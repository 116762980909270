import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getChannels: async (integration) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/channels`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    disableChannel: async (integration, channelId) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/channels/${channelId}/disconnect`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getPipelines: async (integration) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/pipelines`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    connectChannel: async (integration, channelId, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/channels/${channelId}/connect`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    setDefaultChannel: async (integration, channelId, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/channels/${channelId}/default`, { method: 'patch', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    deleteSource: async (integration, data) =>
      messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/delete-source`, { method: 'delete', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

}