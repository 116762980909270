<template>
  <div class="dropdown__wrapper"
       :style="{ width: width, 'max-width': width }"
  >
    <div class="dropdown__title-wrapper" v-if="label || explanation">
      <label v-if="label" class="label" :class="{disabled}">
        {{ label }}
      </label>

      <tooltip-btn v-if="explanation" :text="explanation" :width="widthExplanation" hover>
        <img :style="{width: '16px', height: '16px', padding: '0'}" src="@/assets/img/icons/question-outlined.svg" alt="question mark">
      </tooltip-btn>
    </div>

    <div
        class="dropdown"
        :class="[size, { focus: showDropdownContent, highlight: isHighlight, disabled: disabled, 'toggle-btn': $slots['toggle-btn'] }]"
        :id="id"
        @click.stop.prevent="toggleDropdown"
    >
      <div
          v-if="!$slots['toggle-btn']"
          class="dropdown__placeholder"
          :class="{'no-placeholder': !placeholder}"
          :style="{ width: width - 20 + 'px' }"
      >
      <span class="dropdown__text">
        <div v-if="detailContact && modelValue" class="messenger-types">
          <template v-if="modelValue.users">
            <img v-for="user in modelValue.users" :src="`${$store.state.global.publicPath}messenger-icons/mess-type/${user.channel.type}.svg`" alt="">
            <unread color="red" v-if="detailContact && modelValue?.users.some(u => u.for_check_admin)"/>
          </template>
          <template v-else-if="!modelValue.users && modelValue.channel">
            <img :src="`${$store.state.global.publicPath}messenger-icons/mess-type/${modelValue.channel.type}.svg`" alt="">
            <unread color="red" v-if="detailContact && modelValue?.for_check_admin"/>
          </template>
          <template v-else>
            <img :src="`${$store.state.global.publicPath}messenger-icons/mess-type/${modelValue.type}.svg`" alt="">
          </template>
        </div>
        <div class="dropdown-placeholder" v-if="!hideItemText">
          {{ getPlaceholderText() }}
        </div>

      </span>

        <img v-if="!disabled && !showDropdownContent && (!clearable || isModelValueEmpty) && !imgNamePlaceholder" class="dropdown__arrow" src="@/assets/img/icons/arrow-down.svg" alt="down arrow">
        <img v-else-if="!disabled && showDropdownContent && !imgNamePlaceholder" class="dropdown__arrow" src="@/assets/img/icons/arrow-up.svg" alt="up arrow">
        <img v-else-if="!disabled && !showDropdownContent && !isModelValueEmpty && clearable && !imgNamePlaceholder" class="dropdown__clear" src="@/assets/img/icons/close.svg" alt="clear" @click.stop.prevent="clearModelValue"/>
        <img v-else-if="!disabled && imgNamePlaceholder" class="dropdown__img-placeholder" :src="require(`@/assets/img/icons/${imgNamePlaceholder}`)" alt="down up arrow">
      </div>

      <slot name="toggle-btn"></slot>

      <div
          class="dropdown__content__wrapper"
          :class="[position, horizontalPosition]"
          :style="{ width: menuWidth, height: menuHeight, 'max-height': menuHeight }"
          v-show="showDropdownContent && !disabled"
          ref="dropdownContent"
      >
        <div class="dropdown__content" >
          <ul class="dropdown__ul">
            <slot name="top-menu"></slot>

            <slot name="menu">
              <li
                  v-for="(item, idx) in items"
                  :key="idx"
                  class="dropdown__li"
                  :class="{'detail-li': detailContact}"
                  @click.stop.prevent="onClickHandler(item, $event)">

                <div v-if="detailContact && item.id" class="messenger-types">
                  <template v-if="item.users">
                    <img v-for="user in item.users" :src="`${$store.state.global.publicPath}messenger-icons/mess-type/${user.channel.type}.svg`" alt="">
                    <unread color="red" v-if="detailContact && item?.users.some(u => u.for_check_admin)"/>
                  </template>
                  <template v-else-if="item.channel">
                    <img :src="`${$store.state.global.publicPath}messenger-icons/mess-type/${item.channel.type}.svg`" alt="">
                    <unread color="red" v-if="detailContact && item?.for_check_admin"/>
                  </template>
                  <template v-else>
                    <img :src="`${$store.state.global.publicPath}messenger-icons/mess-type/${item.type}.svg`" alt="">
                  </template>
                </div>

                <checkbox
                    :position="positionCheckbox"
                    v-if="multiple"
                    :modelValue="isItemSelected(item)"
                    :size="'small'"
                    :label="getItemText(item)"
                    :text="secondItemText ? item[secondItemText] : ''"
                    class="dropdown__checkbox"
                    :id="`checkbox-${id}-${idx}`"
                    @on-change="$emit('update:modelValue')"
                />

                <img v-if="!multiple && imgNameItem" :style="getPlaceholderText() === getItemText(item) ? {opacity: 1} : {opacity: 0}"
                     class="dropdown__img" :src="require(`@/assets/img/icons/${imgNameItem}`)" alt="img">

                <div
                    v-if="!multiple && !hideItemText"
                    class="dropdown__li-text"
                    :class="{ selected: getPlaceholderText() === getItemText(item) && !imgNameItem }"
                >
                  <span> {{ getItemText(item) }} </span>
                  <span
                      v-if="secondItemText"
                      class="dropdown__second-text">
                  {{ getSecondItemText(item) }}
                </span>
                </div>

                <div v-if="detailContact && !multiple && !hideItemText" class="detail-name">
                  {{ item.phone || $t('messenger.messengerWindow.headerChat.withoutPhone') }}
                </div>

                <div v-if="countable && item.type !== 'service'" class="dropdown__count">
                  <button @click.stop.prevent="setCount(item, getItemCount(item) - 1)">
                    <img src="@/assets/img/icons/minus.svg" alt="minus">
                  </button>

                  <span>
                  {{ getItemCount(item) }}
                </span>

                  <button @click.stop.prevent="setCount(item, getItemCount(item) + 1)">
                    <img src="@/assets/img/icons/plus.svg" alt="plus">
                  </button>
                </div>
              </li>
            </slot>
            <slot name="menu-bottom"></slot>
          </ul>
        </div>
      </div>

    </div>

    <span v-if="error && typeof error !== 'boolean'" class="str-error">{{ error }}</span>
  </div>
</template>

<script>
import Checkbox from "@/components/UI/Checkbox.vue";
import { EventBus } from '@/utils/event-bus';
import Unread from "@/components/UI/Unread.vue";
import {isString, toString} from "lodash";
import TooltipBtn from "@/components/UI/TooltipBtn.vue";
export default {
  name: 'Dropdown',
  components: {TooltipBtn, Unread, Checkbox },
  props: {
    modelValue: [Array, Object, String, Number],
    placeholder: String,
    items: { type: Array, default: () => [] },
    value: String,
    itemText: String,
    secondItemText: String,
    multipleItemText: Boolean,
    width: String,
    id: String,
    size: {type: String, default: 'medium'}, //small, medium, large
    isHighlight: Boolean,
    multiple: Boolean,
    menuWidth: String,
    clearable: Boolean,
    position: { type: String, default: 'bottom' },
    positionCheckbox: { type: String, default: 'center' }, //start, center
    horizontalPosition: String,
    disabled: Boolean,
    countable: Boolean,
    menuHeight: String,
    detailContact: { type: Boolean, default: false },
    isNeedAutoPosition: { type: Boolean, default: true },
    observer: Boolean,
    imgNamePlaceholder: String,
    imgNameItem: String,
    hideItemText: { type: Boolean, default: false },
    itemTextByLang: { type: Boolean, default: false },
    itemTextByTemplate: {type: String},// Ждёт шаблон в формате (${perem} text ${perem} и.т.д.)
    multipleValue: Boolean,// Использовать ли this.value в multiple дропдауне
    label:  String,
    explanation:  String,
    widthExplanation: String,
    error: {
      type: [String, Boolean],
    },
  },
  data() {
    return {
      showDropdownContent: false,
      localId: this.id || `dropdown-${Math.random().toString(36).substr(2, 9)}`,
    }
  },
  computed: {
    isModelValueEmpty() {
      return !this.modelValue || (Array.isArray(this.modelValue) && !this.modelValue.length);
    }
  },
  methods: {
    // getIsItemChecked(item) {
    //   if (this.multiple && Array.isArray(this.modelValue)) {
    //     return this.modelValue.some(i => i.id === item.id);  // Сравнение по полю id
    //   }
    //   return this.modelValue === item;  // Это сравнение работает, если modelValue не массив
    // },
    toggleDropdown() {
      this.showDropdownContent = !this.showDropdownContent;
      if (this.showDropdownContent) {
        EventBus.emit('dropdown-toggle', this.localId);
        this.adjustDropdownPosition();
      }
    },
    onClickOutside(event) {
      if (this.$el) {
        const isClickInside = this.$el.contains(event.target);
        if (!isClickInside) {
          this.showDropdownContent = false;
        }
      }
    },
    adjustDropdownPosition() {
      if (this.isNeedAutoPosition) {
        this.$nextTick(() => {
          const dropdown = this.$refs.dropdownContent;
          if (!dropdown) return;

          const rect = dropdown.getBoundingClientRect();
          const spaceBelow = window.innerHeight - rect.bottom;
          const spaceAbove = rect.top;

          if (spaceBelow < dropdown.offsetHeight && spaceAbove > dropdown.offsetHeight) {
            dropdown.style.top = 'auto';
            dropdown.style.bottom = 'calc(100% + 8px)';
          } else {
            dropdown.style.bottom = 'auto';
            dropdown.style.top = 'calc(100% + 8px)';
          }
        });
      }
    },
    getPlaceholderText() {
      if (this.modelValue?.hasOwnProperty('name') && typeof this.modelValue === 'object' && Object.keys(this.modelValue).length === 1 && this.modelValue.name === '') return this.placeholder || ''
      if (!this.isModelValueEmpty) {
        return Array.isArray(this.modelValue) ? this.modelValue.map(val => this.getItemText(val)).join(', ') : this.getItemText(this.modelValue);
      }
      return this.placeholder || '';
    },

    getItemText(item) {
      if (this.itemTextByTemplate) {
        return this.itemTextByTemplate.replace(/\$\{(.*?)\}/g, (match, key) => item[key] || '');
      }

      if (this.multiple && this.itemText && this.itemTextByLang) {
        if (isString(item[this.itemText])) return item[this.itemText];
        return `${item[this.itemText] ? item[this.itemText][this.$store.state.global.language]?item[this.itemText][this.$store.state.global.language]:item[this.itemText]['ru'] : '-'}`
      }
      if (this.detailContact) {
        if (!item.name && !item.phone && !item.value) return this.$t('messenger.contactWindow.noName')
        return item.name ? item.name : item.phone ? item.phone : item.value
      }
      if (this.multipleItemText) {
        return `${item[this.itemText]? item[this.itemText] : ''} ${item[this.secondItemText] ? item[this.secondItemText] : ''}`
      }
      if (this.itemText) {
        if (this.value && !item[this.itemText]) {
          const valueInItems = this.items.find(i => i[this.value] == item);
          return valueInItems && valueInItems[this.itemText] || '';
        }

        return item[this.itemText] || item[this.value] || '-';
      }

      return item[this.value] || item;
    },
    getSecondItemText(item) {
      return item[this.secondItemText];
    },
    isItemSelected(item) {
      if (this.multiple && Array.isArray(this.modelValue)) {
        return this.modelValue.some(val => val.id == item.id || val === item || val == item[this.value]);
      }
      return this.modelValue === item;
    },
    onClickHandler(item) {
      if (this.multiple) {
        let index = -1
        if(this.modelValue){
          index = this.modelValue.findIndex(i => i.id === item.id);  // Находим индекс элемента в массиве
        }

        let newValue = this.modelValue ? [...this.modelValue] : [];
        if (this.multipleValue) index = this.modelValue.findIndex(i => i === item[this.value])
        if (index > -1) {

          newValue.splice(index, 1);  // Удаляем элемент, если он уже есть в массиве
        } else {
          if (this.multipleValue) {
            newValue.push(item[this.value])
          } else {
            newValue.push(item);
          }
        }

        this.$emit('update:modelValue', newValue);
        this.$emit('on-change', newValue);
      } else {
        this.$emit('update:modelValue', this.value ? item[this.value] : item);
        this.$emit('on-change', this.value ? item[this.value] : item);

        this.showDropdownContent = false;
      }
    },
    clearModelValue() {
      this.$emit('update:modelValue', null);
      this.$emit('on-change', null);
    },
    getItemCount(item) {
      const itemInModelValue = this.modelValue?.find(i => i.id === item.id);

      if (itemInModelValue) {
        if (!item.pivot) item.pivot = {};
        return itemInModelValue.pivot.count;
      }

      return 0;
    },
    setCount(item, count) {
      if (count < 0) return;

      let newValue = [...this.modelValue];
      const index = newValue.findIndex(i => i.id === item.id);

      if (index > -1) {
        newValue[index]['pivot'].count = count;
      } else {
        newValue.push({ ...item, pivot: {count} });
      }

      this.$emit('update:modelValue', newValue);
      this.$emit('on-change', newValue);
    },
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside);
  },
  created() {
    this.handleToggle = (id) => {
      if (id !== this.localId && this.showDropdownContent) {
        this.showDropdownContent = false;
      }
    };

    EventBus.on('dropdown-toggle', this.handleToggle);
    document.addEventListener('click', this.onClickOutside);
  },
  beforeUnmount() {
    EventBus.off('dropdown-toggle', this.handleToggle);
    document.removeEventListener('click', this.onClickOutside);
  },
}
</script>


<style lang="scss" scoped>
.label, .str-error {
  color: var(--default-text);
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;

  &.disabled {
    color: #969696;
  }
}

.str-error {
  color: #EE4C4C;
}

.messenger-types {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;

  .unread {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.detail-name {
  color: #676767;
  font-size: 12px;
}

.dropdown {
  width: 100%;
  max-height: 50px;
  position: relative;
  display: inline-block;
  border-radius: 14px;
  border: 1px solid #DBDBDB;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: border-color 0.2s ease-out;

  &__wrapper{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: fit-content;
  }

  &__title-wrapper{
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__title{
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  &__img-mark{
    margin-top: 3px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &__img-placeholder{
    height: 20px;
    width: 20px;
  }

  &__img{
    margin-right: 8px;
    height: 16px;
    width: 16px;
  }

  &__arrow {
    height: 16px;
    width: 16px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    background: var(--grey-background);
  }

  &.toggle-btn {
    width: fit-content;
    max-height: none;
    padding: 0;
    display: flex;
    border-radius: 0;
    border: none;

    &:hover{
      border: none !important;
    }

    &.focus{
      border: none !important;
    }
  }

  &.highlight {
    border: 1px solid transparent;
    background: radial-gradient(2218.13% 210.09% at 81.64% 50%, #94FFBF 0%, #5BFFDF 100%);

    .dropdown__text {
      color: #04141F !important;
    }
  }

  &__placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    .unread {
      position: absolute;
      right: -1px;
      top: -1px;
    }

    img {
      margin-left: 8px;
    }

    &.no-placeholder {
      img {
        margin-left: 0;
      }
    }
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  &__li-text {
    text-align: start;
    display: flex;
    flex-direction: column;
  }

  &__second-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--grey-text);
  }

  &__clear {
    width: 11px;
    height: 11px;
    margin-left: 5px;
    opacity: 0.6;
  }

  &__content__wrapper {
    position: absolute;
    padding: 8px;
    top: 50px;
    left: 0;
    z-index: 100000;
    border-radius: 16px;
    background: var(--main-background);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-height: 220px;

    &.top {
      top: auto;
      left: auto;
      right: 0;
      bottom: 45px;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.20) !important;
      padding: 8px !important;
    }

    &.right {
      left: auto !important;
      right: 0;
    }
  }

  &__content {
    width: 100%;
    max-height: 204px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      border-radius: 30px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 30px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #DBDBDB;
      border-radius: 30px;
      border-left: 1px solid var(--main-background);
      border-right: 1px solid var(--main-background);
    }
  }

  &__li {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 10px 12px;
    word-break: break-word;
    white-space: inherit;
    margin-bottom: 4px;

    &.detail-li {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border-radius: 8px;
      background: var(--grey-background);
    }

    &.no-padding {
      padding: 0;
    }

    .selected{
      color: var(--brand);

      span{
        color: var(--brand);
      }
    }
  }

  &__count {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    min-width: 73px;

    button, img {
      padding: 0;
      background: transparent;
      width: 10px;
      height: 20px;
    }

    span {
      font-size: 14px;
      background: #F4F4F4;
      border-radius: 7px;
      padding: 3px 7px;
    }
  }

  &__checkbox {
    //width: fit-content;
    margin: 4px 0 0 0;
  }

  .bottom{
    top: 100%;
    left: 0;
  }

  .messenger-types {
    img {
      margin-left: 0;
      height: 14px;
    }
  }

  &:hover {
    border: 1px solid var(--brand);

    .dropdown__text .dropdown-placeholder {
      color: #969696;
    }
  }

  &.focus {
    border: 1px solid var(--brand);

    .dropdown__text .dropdown-placeholder {
      color: #969696;
    }
  }

  &.small {
    padding: 8px 10px;
    border-radius: 10px;
  }

  &.medium{
    padding: 10px;
    border-radius: 12px;
  }

  &.large{
    padding: 14px 16px;
    border-radius: 14px;
  }
}

.dropdownObserver {
  width: 100%;
  height: 20px;
}

.dropdown__li {
  position: relative;
}

.detail-li {
  width: 100%;
}

.dropdown__ul {
  width: 100%;
}

.dropdown-placeholder {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
