import internalKnowledgeService from "@/services/internalKnowledgeService";
import store from '@/store/index';

export default {
    namespaced: true,
    state: {
        chosenCategory: {},
        categories: [],
        openedCategories: [],
        currentArticle: {},
        currentArticleComments: [],
    },
    actions: {
        async handleOpenedCategories({ state, commit, dispatch }, category) {
            const idx = state.openedCategories.findIndex(cId => cId === category.id);
            if (idx !== -1) {
                state.openedCategories.splice(idx, 1);
            } else {
                state.openedCategories.push(category.id);
            }

            if (!category.categories || category.categories.length <= 1) {
                await dispatch('getChildCategories', category);
            }
        },

        async getChildCategories({ state, commit }, category) {
            const { data } = await internalKnowledgeService.getCategoryById(category.id);

            const changeCategory = (categories, newCategory) => {
                for (let i = 0; i < categories.length; i++) {
                    if (categories[i].id === newCategory.id) {
                        categories[i] = newCategory;
                    }

                    if (categories[i].categories && categories[i].categories.length) {
                        changeCategory(categories[i].categories, newCategory);
                    }
                }
            }

            changeCategory(state.categories, data);
        }
    },
    mutations: {
        deleteCategory(state, id) {
            const deleteCategory = (categories, categoryId) => {
                for (let i = 0; i < categories.length; i++) {
                    if (categories[i].id === categoryId) {
                        categories.splice(i, 1);
                        return;
                    }

                    if (categories[i].categories && categories[i].categories.length) {
                        deleteCategory(categories[i].categories, categoryId);
                    }
                }
            }

            deleteCategory(state.categories, id);
        }
    },
    getters: {
        categoryLevel: (state) => category => {
            const findLevel = (categories, currentCategory, level = 0) => {
                for (let obj of categories) {
                    if (obj.id === currentCategory.id) {
                        return level;
                    }

                    if (obj.categories && obj.categories.length) {
                        let result = findLevel(obj.categories, currentCategory,level + 1);
                        if (result !== -1) {
                            return result;
                        }
                    }
                }

                return -1;
            }

            return findLevel(state.categories, category, 0);
        },
        isCategoryOpened: (state) => category => {
            return state.openedCategories.includes(category.id);
        },
        sortedCategories: (state) => categories => {
            return categories.sort((a, b) => a.position - b.position);
        },
        articlePath: (state) => article => {
            const categoriesPath = [];

            const addCategoryPath = (category) => {
                if (category) {
                    categoriesPath.push(category.title);
                } else {
                    return;
                }

                if (category.category) {
                    addCategoryPath(category.category);
                }
            }

            addCategoryPath(article.category);

            return categoriesPath.reverse().join(' - ');
        },
        isHasEditPermissions() {
            return store.state.auth.user.permissions['adm_knowledge_base:create'] ||
                store.state.auth.user.permissions['adm_knowledge_base:update'] ||
                store.state.auth.user.permissions['adm_knowledge_base_admin'] ||
                store.state.auth.user.permissions['_ALL_'];
        },
        isHasDeletePermissions() {
            return store.state.auth.user.permissions['adm_knowledge_base:delete'] ||
                store.state.auth.user.permissions['adm_knowledge_base_admin'] ||
                store.state.auth.user.permissions['_ALL_'];
        }
    },
}