import globalService from "@/services/globalService";
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export default {
    namespaced: true,
    state: {
        appConfig: null,
        isMobile: document.documentElement.clientWidth < 600,
        miniSb: false,
        publicPath: process.env.NODE_ENV === 'production'
            ? '/app/'
            : '/',
        isFramed: false,
        isShowToast: false,
        toastText: '',
        hideToastImg: false,
        isErrorToast: false,
        sideMenuItems: [],
        isMessageHelp: window.location.hostname === 'message.help',
        currency: window.location.hostname === 'message.help' ? '€' : '₽',
        language: getCookie('site_lang') ? getCookie('site_lang') : process.env.VUE_APP_LOCALE,
        isDarkTheme: false,
        selectedCountryCode: null,
        selectedPhoneCode: null,
        isSupportEmoji: false,
        headerFooter: null
    },
    getters: {},
    mutations: {
        showToast(state, { text, hideImg, isError, position }) {
            state.positionToast = position;
            state.hideToastImg = hideImg;
            state.isErrorToast = isError;

            state.isShowToast = true;
            state.toastText = text;
        },
        closeToast(state) {
            state.isShowToast = false;
            state.toastText = '';
            state.positionToast = '';
            state.hideToastImg = false;
            state.isErrorToast = false;
        },
        setAppConfig(state, appConfig) {
            state.appConfig = appConfig;
        }
    },
    actions: {
        async getAppConfig({commit}) {
            const appConfig = await globalService.getAppConfig();
            commit('setAppConfig', appConfig.data);
        }
    },
}