<template>
  <modal-window
    :showModal="showModal"
    @closeModal="$emit('close-modal')"
    id="increase-tariffs-modal"
    hide-close-button
  >
    <template #body>
      <div class="increase-tariffs-modal">
        <p class="increase-tariffs-modal__header body-header">
          Успейте оплатить тариф до 31 декабря по ценам 2024 года!
        </p>

        <div class="increase-tariffs-modal__card">
          <p class="body-text">
            Уважаемые клиенты!
          </p>

          <p class="body-header-small">
            Обращаем ваше внимание, что с 1 января 2025 года
            стоимость тарифов будет увеличена на ~5-9%
            в зависимости от наполнения проекта
          </p>

          <p class="body-text">
            Повышение связано с вводом НДС и ростом налоговой нагрузки, а также подорожанием используемых нами сервисов
          </p>
        </div>


          <p class="increase-tariffs-modal__text body-text">
            Wahelp заботится о каждом клиенте и стремится сделать переход максимально комфортным
          </p>

          <p class="increase-tariffs-modal__header2 body-header">
            Оплачивайте тариф до 31 декабря по ценам 2024 года и забирайте дополнительные подарки:
          </p>

          <div class="increase-tariffs-modal__wrapper">
            <div class="increase-tariffs-modal__card">
              <p class="body-header">
                6 месяцев
              </p>

              <p class="body-text">
                по текущей стоимости со скидкой 10%
              </p>

              <p class="body-header-small">
                + 1 месяц в подарок
              </p>
            </div>

            <div class="increase-tariffs-modal__card">
              <p class="body-header">
                12 месяцев
              </p>

              <p class="body-text">
                по текущей стоимости со скидкой 20%
              </p>

              <p class="body-header-small">
                + 2 месяца в подарок
              </p>
            </div>

            <div class="increase-tariffs-modal__card">
              <p class="body-header">
                24 месяца
              </p>

              <p class="body-text">
                по текущей стоимости со скидкой 30%
              </p>

              <p class="body-header-small">
                + 3 месяца в подарок
              </p>
            </div>
          </div>
      </div>
    </template>

    <template #footer>
      <div class="increase-tariffs-modal__btns">
        <btn
          class="increase-tariffs-modal__custom-btn"
          size="large"
          @click="setPopupStatus()">
          Ознакомлен(-а)
        </btn>

        <btn
          size="large"
          color="green"
          @click="connectTariff()">
          Подключить тариф со скидкой
        </btn>
      </div>
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "@/components/UI/ModalWindow.vue";
import authService from "@/services/authService";

export default {
  components: {ModalWindow},
  props: {
    showModal: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
    }
  },
  emits: ['close-modal'],
  methods: {
    async connectTariff() {
      await authService.changePopupStatus();

      const url = 'https://links.wahelp.ru/whatbot/wahelp';

      const newTab = window.open(url, '_blank');

      if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
        window.location.href = url;
      }

      this.$emit('close-modal');
    },
    async setPopupStatus() {
      await authService.changePopupStatus();

      this.$emit('close-modal');
    }
  },
}
</script>

<style scoped lang="scss">
.increase-tariffs-modal {
  width: 100%;

  p {
    margin: 0;
    color: white;
  }

  &__card {
    border-radius: 16px;
    background: #FFF;
    padding: 16px;
    margin-bottom: 24px;

    p {
      color: black;
    }

    .body-text {
      margin-bottom: 8px;
    }

    .body-header-small {
      margin-bottom: 16px;
      color: #192530;
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
    }

    .body-text {
      color: #4B6379;
    }

    .body-header {
      color: #3A94E7;
      margin-bottom: 8px !important;
    }
  }

  &__header {
    width: 350px;
    margin-bottom: 24px !important;
  }

  &__header2 {
    margin: 8px 0 16px 0 !important;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .increase-tariffs-modal__card {
      width: 33%;
      height: 170px;
    }
  }

  &__custom-btn {
    background: rgba(244, 244, 244, 0.22) !important;
    color: white !important;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .increase-tariffs-modal {
    &__card, &__header {
      width: 100% !important;
    }

    &__wrapper {
      align-items: flex-start;
      flex-direction: column;

      &:last-child {
        margin-bottom: 16px;
      }

      .increase-tariffs-modal__card {
        height: 100%;
        margin-bottom: 0;

        .body-header-small {
          width: 90px;
        }
      }
    }

    &__btns {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
  }
}
</style>

<style>
#increase-tariffs-modal {
  .modal-container {
    background: #3A94E7;
    gap: 0;
  }
}
</style>