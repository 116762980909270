import clientsService from "@/services/clientsService";
import store from "@/store/index";
import moment from "moment";
export default {
    namespaced: true,
    state: {
        clients: [],
        activeClient: {},
        messenger: "",
        uuid: "",
        projectId: "",
        hasMode: false,
        pageActive: 1,
        loaderActive: false,
        sortTypeDate: 'desc',
        filters: {
            managers: "",
            date: null,
            searchClients: "",
        },
        numberOfRow: 5,
        isEditManagers: false,
    },

    mutations: {
        setMessenger(state, messenger) {
            state.messenger = messenger;
        },
        setClients(state, clients){
            state.clients = clients
        },
        setActiveClient(state, activeClient){
            state.activeClient = activeClient
        },
        setUuid(state, uuid){
            state.uuid = uuid
        },
        setProjectId(state, projectId){
            state.projectId = projectId
        },
        setHasMode(state, hasMode){
            state.hasMode = hasMode
        },
        setPageActive(state, pageActive){
            state.pageActive = pageActive
        },
        setLoaderActive(state, loaderActive){
            state.loaderActive = loaderActive
        },
        setSortTypeDate(state){
            if(state.sortTypeDate === "desc"){
                state.sortTypeDate = "asc";
            }else{
                state.sortTypeDate = "desc";
            }
        },
        setSortTypeDateDefault(state){
            state.sortTypeDate = "desc";
        },
        setFilters(state, filters) {
            state.filters.date = filters.date
            state.filters.searchClients = filters.searchClients
            state.filters.managers = filters.managers
        },
    },
    actions: {
        async getClients({commit, state}) {

            commit("setLoaderActive", true)

            const keysFilters = {
                searchClients: 'search',
                managers: 'filter[manager]',
                date_from: 'created_at_from',
                date_to: 'created_at_to'
            }

            let filtersStr = ''
            for (const [key, value] of Object.entries(state.filters)) {
                if (key && value) {
                    if(key !== 'date'){
                        filtersStr += `&${keysFilters[key]}=${value}`
                    } else {
                        const dateObjFrom = new Date(value[0]);
                        dateObjFrom.setHours(0, 0, 0, 0);
                        const isoStringFrom = dateObjFrom.toISOString();
                        const dateFrom = moment(isoStringFrom).format('l LT');

                        const dateObjTo = new Date(value[1]);
                        dateObjTo.setHours(23, 59, 59, 999);
                        const isoStringTo = dateObjTo.toISOString();
                        const dateTo = moment(isoStringTo).format('l LT');

                        filtersStr += `&${keysFilters.date_from}=${dateFrom}&${keysFilters.date_to}=${dateTo}`
                    }
                }
            }

            if(filtersStr) filtersStr.slice(1)

            localStorage.setItem('numberRowTemplateMenu', state.numberOfRow);

            if(state.pageActive && state.pageActive > 0 && state.uuid){
                await clientsService.getClients(state.projectId, state.uuid, state.pageActive, state.numberOfRow, state.sortTypeDate, filtersStr);
            }else{
                store.commit('clients/setLoaderActive', false);
                store.commit('clients/setClients', [])
            }
        },

        async getClient({commit, state}, clientId) {
            await clientsService.getClient(state.projectId, state.uuid, clientId);
        },

        async putCommentClient({commit, state}){
            await clientsService.putCommentClient(state.projectId, state.uuid, state.activeClient);
        },

    },
}