import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getDataHelp: async () =>
        messCall(`support`, {method: 'get'},
            {'X-Project': store.state.projects.currentProjectId}, '/api/app/'),

    requestCall: async (data) =>
        messCall(`support/request_call`, {method: 'post', data},
            {'X-Project': store.state.projects.currentProjectId}, '/api/app/'),

}
