<template>
  <button
      class="btn"
      :class="[color, size, position, spaces, customBtn ? 'custom' : '', back ? 'back' : '']"
      :disabled="disabled"
      :style="{ width: width }"
  >
    <img
        v-if="imgName"
        :src="require(`@/assets/img/${imgName}`)"
        alt="img"
    >

    <slot :aStyle="aStyle" />
  </button>
</template>

<script>
export default {
  name: "Btn",
  props: {
    back:{
      type: Boolean
    },
    //color: red, grey, green
    color: {
      type: String,
      default: "grey"
    },
    //size: small, medium, large
    size: {
      type: String,
      default: "medium"
    },
    imgName:{
      type: String
    },
    //size: left, right
    position:{
      type: String,
      default: "left"
    },
    spaces: {
      type: String,
    },
    width: {
      type: String,
      required: false,
    },
    customBtn: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    aStyle() {
      return this.disabled ? { 'pointer-events': 'none', 'opacity': '0.8' } : {};
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  padding: 0 16px;
  color: var(--default-text);
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  //overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.custom {
    padding: 0;
    background: transparent;

    &:hover {
      background: transparent;
    }

    &:active {
      background: transparent;
    }
  }
  a {
    width: 100%;
    height: 100%;
    color: #04141F;
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  &[disabled]{
    pointer-events: none;
  }
}

img{
  width: 16px;
  height: 16px;
}

.left{
  flex-direction: row;
}

.right{
  flex-direction: row-reverse;
}

.transparent {
  background: transparent;
  border: 1px solid #DBDBDB;
  transition: border-color 0.2s ease-out;

  img{
    opacity: 0.4;
  }

  &:hover {
    border: 1px solid var(--brand);
    color: #969696;
  }

  &.show {
    border: 1px solid var(--brand);
    color: #969696;
  }
}

.green {
  background: #94FFBF;
  transition: background 0.2s ease-out;

  &:hover {
    background: #6EEEA2;
    transition: background 0.2s ease-out;
  }

  &:active {
    background: #4CDF87;
  }

  &[disabled] {
    background: #D6FFE7;
    cursor: default;
    color: rgba(0, 0, 0, 0.20);

    img{
      filter: invert(1) brightness(0);
      opacity: 0.2;
    }
  }
}

.red {
  background: #FFE0E0;
  transition: background 0.2s ease-out;

  &:hover {
    background: #F4BCBC;
    transition: background 0.2s ease-out;
  }

  &:active {
    background: #F29C9C;
  }

  &[disabled]{
    background: #FFEBEB;
    cursor: default;
    color: rgba(0, 0, 0, 0.20);

    img{
      filter: invert(1) brightness(0);
      opacity: 0.2;
    }
  }
}

.grey {
  background: var(--grey-background);
  transition: background 0.2s ease-out;

  &:hover {
    background: #ECECEC;
    transition: background 0.2s ease-out;
  }

  &:active {
    background: #DBDBDB;
  }

  &[disabled]{
    background: #F4F4F4;
    cursor: default;
    color: rgba(0, 0, 0, 0.20);

    img{
      filter: invert(1) brightness(0);
      opacity: 0.2;
    }
  }
}

.small {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 10px;

  &.transparent {
    padding-top: 6px !important;
  }

  &.back{
    padding: 10px
  }
}

.medium {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px;

  &.transparent {
    padding-top: 8px !important;
  }

  &.back{
    padding: 12px
  }
}

.large {
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 14px;

  &.transparent {
    padding-top: 12px !important;
  }

  &.back{
    padding: 16px
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
</style>