import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import components from '@/components/UI'
import { createI18n, useI18n } from 'vue-i18n'
import { languages } from "@/translate";
import { defaultLocale } from "@/translate";
import Notifications from '@kyvg/vue3-notification'
import moment from "moment/dist/moment"
import ru from "./config/ruMoment.js"
import "@/config/ptMoment.js"
import webSocketService from "@/services/webSocketService";
import directives from '@/directives'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
const messages = Object.assign(languages)
import "@fancyapps/ui/dist/fancybox/fancybox.css";
const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: 'ru',
    messages
})


let app = createApp(App, {
    //Чтобы в будущем мы могли обращаться к переводам с помощью константы "t"
    setup() {
        const {t} = useI18n()
        return {t}
    }
}).use(i18n)
components.forEach(component => {
    app.component(component.name, component);
})

const chat_id=localStorage.getItem('Project')
const socket_port=8080
const socket_host=(location.hostname==='localhost')?'ws://'+location.hostname:'wss://'+location.hostname
// if(location.hostname==='localhost'){
//     console.log(socket_host)
// }
import axios from './config/axios';
directives.forEach(directive => {
    app.directive(directive.name, directive)
})
app.component('VueDatePicker', VueDatePicker);
app.use(store);
app.use(router);
app.use(Notifications);
app.use(webSocketService,{socket_host,socket_port,store,chat_id})
console.log(store.state.global.language)


app.mount('#app');

