import Api from '@/api/index.js'
import store from "@/store";
import { requestHandler, basePath } from '@/utils/apiHelpers';

class SettingsService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getNotifications = async function () {
            return requestHandler(async () => {
                return await api.settings.getNotifications();
            })
        }

        this.getNotificationsConfig = async function () {
            return requestHandler(async () => {
                const res = await api.settings.getNotificationsConfig();

                store.commit('settings/setConfig', res.data);
            })
        }

        this.getNotificationsTitles = async function () {
            return requestHandler(async () => {
                const res = await api.settings.getNotificationsTitles();

                store.commit('settings/setTitles', res.data);
            })
        }

        this.createNotification = async function (data) {
            return requestHandler(async () => {
                return await api.settings.postNotifications(data);
            })
        }

        this.editNotification = async function (data) {
            return requestHandler(async () => {
                return await api.settings.putNotifications(data);
            })
        }

        this.editNotificationSettings = async function (data) {
            return requestHandler(async () => {
                return await api.settings.putNotificationsSettings(data);
            })
        }

        this.getNotificationSettings = async function (data) {
            return requestHandler(async () => {
                return await api.settings.getNotificationsSettings(data);
            })
        }


        this.getGeneralSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getGeneralSettings();
            })
        }

        this.editSettings = async function ({ section, field }) {
            return requestHandler(async () => {
                const methods = {
                    general: api.settings.putGeneralSettings,
                    scripts: api.settings.putScriptsSettings,
                    template_vars: api.settings.putTemplateVars,
                    cascade: api.settings.putCascadeSettings,
                    chat: api.settings.putChatSettings,
                    translation_vars: api.settings.putTranslationsVarsSettings,
                    yclients: api.settings.putYclientsSettings,
                    bitrix: api.settings.putBitrixSettings,
                    amo: api.settings.putAmoSettings,
                    tasks: api.settings.putTasksSettings,
                }

                return await methods[section](field);
            })
        }

        this.getScriptsSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getScriptsSettings();
            })
        }

        this.getTemplateVars = async function (projectId) {
            return requestHandler(async () => {
                return await api.settings.getTemplateVars(projectId);
            })
        }

        this.getCascadeSettings = async function () {
            return requestHandler(async () => {
                const res = await api.settings.getCascadeSettings();

                for (let key in res.data) {
                    if (Array.isArray(res.data[key])) {
                        res.data[key] = res.data[key].filter(cId => store.state.projects.currentProject.channels.find(channel => channel.id == cId));
                    }
                }

                return res;
            })
        }

        this.getChatSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getChatSettings();
            })
        }

        this.getTranslationsVarsSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getTranslationsVarsSettings();
            })
        }

        this.getYclientsSettings = async function (projectId) {
            return requestHandler(async () => {
                return await api.settings.getYclientsSettings(projectId);
            })
        }

        this.getBitrixSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getBitrixSettings();
            })
        }

        this.getBitrixSmsSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getBitrixSmsSettings();
            })
        }

        this.createBitrixSmsSettings = async function (id) {
            return requestHandler(async () => {
                return await api.settings.createBitrixSmsSettings(id);
            })
        }

        this.deleteBitrixSmsSettings = async function (id) {
            return requestHandler(async () => {
                return await api.settings.deleteBitrixSmsSettings(id);
            })
        }

        this.getAmoSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getAmoSettings();
            })
        }

        this.getSberSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getSberSettings();
            })
        }

        this.getTasksSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getTasksSettings();
            })
        }

        this.getTasksRegularSettings = async function () {
            return requestHandler(async () => {
                return await api.settings.getTasksRegularSettings();
            })
        }

        this.createTasksRegularSettings = async function (data) {
            return requestHandler(async () => {
                return await api.settings.createTasksRegularSettings(data);
            })
        }

        this.editTasksRegularSettings = async function (idTask, data) {
            return requestHandler(async () => {
                return await api.settings.editTasksRegularSettings(idTask, data);
            })
        }

        this.deleteTasksRegularSettings = async function (idTask) {
            return requestHandler(async () => {
                return await api.settings.deleteTasksRegularSettings(idTask);
            })
        }

        this.getProjectUsers = async function () {
            return requestHandler(async () => {
                return await api.settings.getProjectUsers();
            })
        }

        this.createProjectUser = async function (data) {
            return requestHandler(async () => {
                return await api.settings.postProjectUser(data);
            })
        }

        this.editProjectUser = async function (id, data) {
            return requestHandler(async () => {
                return await api.settings.putProjectUser(id, data);
            })
        }

        this.editProjectUserRole = async function (id, data) {
            return requestHandler(async () => {
                return await api.settings.putProjectUserRole(id, data);
            })
        }

        this.deleteProjectUser = async function (id) {
            return requestHandler(async () => {
                const res = await api.settings.deleteProjectUser(id);

                const idx = store.state.settings.users.findIndex(u => u.id === id);
                store.state.settings.users.splice(idx, 1);

                return res;
            })
        }

        this.getProjectDepartments = async function () {
            return requestHandler(async () => {
                return await api.settings.getProjectDepartments();
            })
        }

        this.createProjectDepartment = async function (data) {
            return requestHandler(async () => {
                return await api.settings.postProjectDepartment(data);
            })
        }

        this.getYclientsStaff = async function (id) {
            return requestHandler(async () => {
                return await api.settings.getYclientsStaff(store.state.global.isMessageHelp ? 'altegio' : 'yclients');
            })
        }

        this.getClientCategory = async function (integration) {
            return requestHandler(async () => {
                return await api.settings.getClientCategory(integration);
            })
        }
    }
}

export default new SettingsService()