<template>
  <div
      class="wrapper"
      :class="[noPadding ? 'no-padding' : '', position]"
      :style="{width: width}"
  >
    <div class="check_box__wrapper"
         :class="[position]"
    >
      <input
          class="check_box"
          :class="[size, main ? 'main' : '']"
          type="checkbox"
          :id="id"
          v-model="checked"
          :true-value="isNumber ? 1 : !opposite"
          :false-value="isNumber ? 0 : opposite"
          :disabled="disabled"
      >
      <label class="check_box__label" :for="id"></label>
    </div>

    <label :for="id" class="text">
      <span>{{ label }}</span>
      <p v-if="text">{{ text }}</p>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Boolean, Number],
      default: false
    },
    label: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    id: {
      type: [String, Number],
      required: true
    },
    //size: small, medium
    size: {
      type: String,
      default: 'small'
    },
    main: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      required: false
    },
    opposite: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Boolean, String],
      required: false
    },
    //position: start, center
    position: {
      type: String,
      default: 'center'
    },
    isNumber: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checked: {
      get() {
        if (this.value) return this.modelValue || this.value;
        else return this.modelValue
      },
      set(value) {
        const outputValue = this.isNumber ? (value ? 1 : 0) : value;
        this.$emit('update:modelValue', outputValue);
        this.$emit('on-change', outputValue);
      }
    }
  },
  emits: [ 'on-change', 'update:modelValue', ],
}
</script>


<style lang="scss" scoped>
.check_box__wrapper {
  width: 16px;
  height: 16px;

  &.start{
    margin-top: 5px;
  }
}
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  &.start{
    align-items: flex-start;
  }

  &.center{
    align-items: center;
  }
}

.check_box {
  display: none;

  &__wrapper {
    visibility: hidden;
  }

  &__label {
    display: block;
    height: 16px;
  }
}

.check_box + label::before {
  cursor: pointer;
  visibility: visible;
  content: "";
  background-image: url('@/assets/img/icons/checkbox-disabled.svg');
  background-repeat: no-repeat;
}

.check_box:checked + label::before {
  cursor: pointer;
  content: "";
  background-image: url('@/assets/img/icons/checkbox-active-green.svg');
  background-repeat: no-repeat;
}

.check_box:disabled + label::before {
  cursor: default;
  opacity: 0.5;
}


.main + label::before {
  cursor: pointer;
  visibility: visible;
  content: "";
  background-image: url('@/assets/img/icons/checkbox-active-main-green.svg');
  background-repeat: no-repeat;
}

.small {
  & + label::before {
    padding: 0 8px;
    background-size: 16px 16px;
  }

  &:checked + label::before {
    background-size: 16px 16px;
  }
}

.medium {
  & + label {
    height: 20px;
  }

  & + label::before {
    padding: 0 10px;
    background-size: 20px 20px;
  }

  &:checked + label::before {
    background-size: 20px 20px;
  }
}

.text {
  margin-left: 12px;
  height: fit-content;
  margin-bottom: 3px;
  cursor: pointer;

  span {
    color: #04141F;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    margin: 0;
    color: #676767;
    font-size: 14px;
    line-height: 20px;
  }
}

.no-padding {
  padding: 0;
}
</style>