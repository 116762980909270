import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getFunnels: async () =>
        messCall(`/funnel/`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/crm'),
    getStages: async (funnelId) =>
      messCall(`/stage/${funnelId}/`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/crm'),

    getDealCycleGraph: async (query = '') =>
      messCall(`/analytics/deal_cycle/graph/${query}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/crm'),
    getDealCycleStats: async (query = '') =>
      messCall(`/analytics/deal_cycle/stat/${query}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/crm'),

    getEmployees: async (query = '') =>
      messCall(`/analytics/employees/${query}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/crm'),
    getEmployeeById: async (id, query) =>
      messCall(`/analytics/employees/${id}/${query}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/crm'),

    getTasksCategories: async () =>
      messCall(`/tasks/categories/`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}