import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getClients: async (projectId, uuid, pageActive, numberOfRow, sortTypeDate, filtersStr) =>
        messCall(`/projects/${projectId}/channels/${uuid}/users?page=${pageActive}&count=${numberOfRow}&sort=created_at&sort_type=${sortTypeDate}${filtersStr}`,
            {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getClient: async (projectId, uuid, clientId) =>
        messCall(`/projects/${projectId}/channels/${uuid}/users/${clientId}`,
            {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    putCommentClient: async (projectId, uuid, data) =>
        messCall(`/projects/${projectId}/channels/${uuid}/users/${data.id}`, { method: 'put', data },
            {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

}
