import Api from '@/api/index.js'
import {basePath, requestHandler} from '@/utils/apiHelpers';
import store from "@/store";
class GlobalService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getAppConfig = async function () {
            return requestHandler(async () => {
                return await api.global.getAppConfig();
            });
        }

        this.getSideMenu = async function () {
            return requestHandler(async () => {
                const res = await api.global.getSideMenu(store.state.global.language);

                if (res.data) {
                    store.state.global.sideMenuItems = res.data.menu_items;
                }
            });
        }

        this.createProject = async function (project) {
            return requestHandler(async () => {
                return await api.global.postProject(project);
            });
        }

        this.getCountries = async function (data) {
            return requestHandler(async () => {
                return await api.global.getCountries(data);
            });
        }

        this.getCities = async function (data) {
            return requestHandler(async () => {
                return await api.global.getCities(data);
            });
        }

        this.getHeaderFooter = async function () {
            return requestHandler(async () => {
                return await api.global.getHeaderFooter();
            });
        }

        this.postNpsPoll = async function (data) {
            return requestHandler(async () => {
                return await api.global.postNpsPoll(data);
            });
        }
    }
}

export default new GlobalService()