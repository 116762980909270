import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getAutoPayments: async (user_id) =>
        messCall(`user/${user_id}/payments/recurring`,
            {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),

    deleteAutoPayment: async (id, user_id) =>
        messCall(`user/${user_id}/payments/recurring/${id}`,
            { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),

}
