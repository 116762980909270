import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    systemReports: {
        getSystemReports: async () =>
            messCall(`system_reports/config`,
                {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),

        getSystemReport: async (type, page, count, sort, sortType) =>
            messCall(`system_reports/${type}/?page=${page}&count=${count}${sort && sortType ? `&sort=${sort}&sort_type=${sortType}`: ''}`,
                {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),

        getSystemReportById: async (type, id) =>
            messCall(`system_reports/${type}/${id}`,
                {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),

        createSystemReport: async (type, data) =>
            messCall(`system_reports/${type}`,
                {method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),
    },
    ycCompanies: {
        getYcCompanies: async (paramsQuery) =>
          messCall(`reports/yc_companies${paramsQuery}`,
            { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),
        getYcCompaniesStats: async () =>
          messCall(`reports/yc_companies/stats`,
            { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),
    },
    customReports: {
        getReportsNewDialogs: async (paramsQuery) =>
            messCall(`reports/new_dialogs${paramsQuery}`,
                { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app/'),

        getReportsSMS: async (paramsQuery) =>
            messCall(`reports/sms${paramsQuery}`,
                { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app/'),

        getReportsAuditYclients: async () =>
            messCall(`reports/yc_audit`,
                { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app/'),
        postReportsAuditYclients: async (data) =>
            messCall(`reports/yc_audit`,
                { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app/'),

        getReportsTemplates: async (path, paramsQuery) =>
            messCall(`reports/${path}${paramsQuery}`,
                { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app/'),
    }
}