import Btn from "@/components/UI/Btn.vue";
import VueInput from "@/components/UI/VueInput.vue";
import ErrorMsg from "@/components/UI/ErrorMsg.vue";
import Radio from "@/components/UI/Radio.vue";

export default [
    Btn,
    VueInput,
    ErrorMsg,
    Radio
]
