import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store";
import { setCookie } from "@/utils/setCookie";
import authService from "@/services/authService";

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: store.state.global.language === 'ru' ? 'Главная' : 'Main',
    },
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/login/',
    name: 'Login',
    component: () => import(`@/views/Login${store.state.global.isMessageHelp ? 'Me' : ''}View.vue`),
    meta: {
      title: store.state.global.language === 'ru' ? 'Авторизация' : 'Authorization',
      requiresNoAuth: true,
      disableForLoggedIn: true,
    },
  },
  {
    path: '/login/openid/google',
    name: 'LoginOpenId',
    component: () => import(`@/views/Login${store.state.global.isMessageHelp ? 'Me' : ''}View.vue`),
    meta: {
      title: store.state.global.language === 'ru' ? 'Авторизация' : 'Authorization',
      requiresNoAuth: true,
      disableForLoggedIn: true,
    },
  },
  {
    path: '/integrations/:marketplace',
    name: 'Integrations',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Интеграции' : 'Integrations',
    },
  },
  {
    path: '/UI/',
    name: 'UI',
    component: () => import('@/views/UIElementsView.vue'),
    meta: {
      title: 'UI',
    },
  },
  //TEMPLATES
  {
    path: '/templates/',
    name: 'templates',
    meta: {
      title: store.state.global.language === 'ru' ? 'Шаблоны' : 'Templates',
    },
    component: () => import('@/views/TemplatesView.vue'),
    children: [
      {
        path: '',
        name: 'main-templates',
        component: () => import('@/components/Templates/Templates.vue'),
        children:[
          {
            path: 'menu',
            name: 'menu-templates',
            component: () => import('@/components/Templates/TemplatesMenu/TemplatesMenu.vue'),
          },
          {
            path: store.state.global.isMessageHelp ? 'altegio' : 'yclients',
            name: 'yclients-templates',
            component: () => import('@/components/Templates/TemplatesYclients/TemplatesYclients.vue'),
          },
          {
            path: 'sbercrm',
            name: 'sbercrm-templates',
            component: () => import('@/components/Templates/TemplatesYclients/TemplatesYclients.vue'),
          },
          {
            path: 'waba',
            name: 'waba-templates',
            component: () => import('@/components/Templates/TemplatesWaba/TemplatesWaba.vue'),
          },
          {
            path: 'vk',
            name: 'vk-templates',
            component: () => import('@/components/Templates/TemplatesVk/TemplatesVk.vue'),
          }
        ]
      },
      {
        path: 'create-waba-template',
        name: 'create-waba-template',
        component: () => import('@/components/Templates/TemplatesWaba/TemplatesWabaCreate/TemplatesWabaCreate.vue'),
      },
      {
        path: 'create-menu-template/:projectId/:uuid/:channelType/:parentId?',
        name: 'create-menu-template',
        component: () => import('@/components/Templates/TemplatesMenu/TemplatesMenuCreateEdit/TemplatesMenuCreateEdit.vue'),
        props: true,
      },
      {
        path: 'edit-menu-template/:projectId/:uuid/:channelType/:id',
        name: 'edit-menu-template',
        component: () => import('@/components/Templates/TemplatesMenu/TemplatesMenuCreateEdit/TemplatesMenuCreateEdit.vue'),
        props: true,
      },
      {
        path: 'edit-template/:type/:id/:integration/:projectId',
        name: 'edit-yclients-template',
        component: () => import('@/components/Templates/TemplatesYclients/TemplatesYclientsCreateEdit/TemplatesYclientsCreateEdit.vue'),
        props: true,
      },
      {
        path: 'edit-cross-template/:id/',
        name: 'edit-cross-template',
        component: () => import('@/components/Templates/TemplatesYclients/TemplatesYclientsCrossSales/TemplatesYclientsCrossSalesEdit/TemplatesYclientsCrossSalesEdit.vue'),
        props: true,
      },
      {
        path: 'send-info/:id/',
        name: 'send-info',
        component: () => import('@/components/Templates/TemplatesYclients/TemplatesYclientsCrossSales/TemplatesYclientsCrossSalesSendInfo.vue'),
        props: true,
      },
      {
        path: 'create-template/:type/:integration/:projectId',
        name: 'create-yclients-template',
        component: () => import('@/components/Templates/TemplatesYclients/TemplatesYclientsCreateEdit/TemplatesYclientsCreateEdit.vue'),
        props: true,
      },
    ]
  },
  {
    path: '/channels/',
    name: 'channels',
    meta: {
      title: store.state.global.language === 'ru' ? 'Каналы' : 'Channels',
    },
    component: () => import('@/views/ChannelsView.vue'),
    children: [
      {
        path: '',
        name: 'channelsData',
        component: () => import('@/components/Channels/Channels.vue'),
      },
      {
        path: ':uuid/',
        name: 'connection',
        component: () => import('@/components/Channels/ChannelsConnection/ChannelsConnection.vue'),
      },
    ]
  },
  {
    path: '/channels-amo/',
    name: 'channels-amo',
    meta: {
      title: store.state.global.language === 'ru' ? 'Каналы AmoCRM' : 'Channels AmoCRM',
    },
    component: () => import('@/views/ChannelsAmoView.vue')
  },
  {
    path: '/link-managers-amo/',
    name: 'link-managers-amo',
    meta: {
      title: store.state.global.language === 'ru' ? 'Привязка менеджеров в amo' : 'Linking managers in amo',
    },
    component: () => import('@/views/LinksManagersAmoView.vue')
  },
  {
    path: '/clients/',
    name: 'clients',
    meta: {
      title: store.state.global.language === 'ru' ? 'Клиенты' : 'Clients',
    },
    component: () => import('@/views/ClientsView.vue'),
    children: [
      {
        path: '',
        name: 'clientsData',
        component: () => import('@/components/Clients/ClientsDataTable.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Таблица клиентов' : 'Clients table',
        },
      },
      {
        path: ':projectId/:uuid/:messenger/:id/',
        name: 'edit-client',
        component: () => import('@/components/Clients/ClientsDataEdit.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Редактировать клиента' : 'Edit client',
        },
      },
    ]
  },
  {
    path: '/history/',
    name: 'history',
    meta: {
      title: store.state.global.language === 'ru' ? 'История сообщений'  : 'History',
    },
    component: () => import('@/views/HistoryView.vue')
  },
  {
    path: '/help/',
    name: 'help',
    meta: {
      title: store.state.global.language === 'ru' ? 'Помощь'  : 'Help' ,
      requiresNoAuth: true,
    },
    component: () => import('@/views/HelpView.vue')
  },
  //TOOLS
  {
    path: '/tools/',
    name: 'tools',
    meta: {
      title: store.state.global.language === 'ru' ? 'Инструменты роста' : 'Tools',
    },
    component: () => import('@/views/Tools/ToolsView.vue'),
    props: true,
  },
  {
    path: '/tools/create-site/',
    name: 'create-site',
    meta: {
      title: store.state.global.language === 'ru' ? 'Создание сайта' : 'Site creation',
    },
    component: () => import('@/views/Tools/ToolsCreateSiteView.vue'),
  },
  {
    path: '/tools/edit-site/:id/',
    name: 'edit-site',
    meta: {
      title: store.state.global.language === 'ru' ? 'Редактирование сайта' : 'Editing the site',
    },
    component: () => import('@/views/Tools/ToolsCreateSiteView.vue'),
    props: true,
  },
  {
    path: '/tools/create-widget/',
    name: 'create-widget',
    meta: {
      title: store.state.global.language === 'ru' ? 'Создание виджета' : 'Creating a widget',
    },
    component: () => import('@/views/Tools/ToolsCreateWidgetView.vue'),

  },
  {
    path: '/tools/edit-widget/',
    name: 'edit-widget',
    meta: {
      title: store.state.global.language === 'ru' ? 'Редактирование виджета' : 'Editing a widget',
    },
    component: () => import('@/views/Tools/ToolsCreateWidgetView.vue'),
    props: true,
  },
  {
    path: '/sites/:id/',
    name: 'site',
    meta: {
      title: store.state.global.language === 'ru' ? 'Сайт' : 'Site',
      requiresNoAuth: true,
    },
    component: () => import('@/views/Tools/ToolsSiteView.vue'),
    props: true,
  },
  {
    path: '/tariff/',
    name: 'tariff',
    meta: {
      title: store.state.global.language === 'ru' ? 'Мой тариф' : 'Tariff',
    },
    params: true,
    component: () => import('@/views/TariffView.vue'),
    props: true,
  },
  {
    path: '/partners/',
    name: 'partners',
    meta: {
      title: store.state.global.language === 'ru' ? 'Партнёрка' : 'Affilate',
    },
    component: () => import('@/views/PartnersView.vue')
  },
  {
    path: '/mailings-lists/',
    name: 'mailings-lists',
    meta: {
      title: store.state.global.language === 'ru' ? 'Списки пользователей для рассылки' : 'User lists for mailing',
    },
    component: () => import('@/views/MailingsListsView.vue')
  },
  {
    path: '/mailings/',
    name: 'mailings',
    meta: {
      title: store.state.global.language === 'ru' ? 'Массовые рассылки' : 'Sendings',
    },
    component: () => import('@/views/MailingsView.vue'),
    children: [
      {
        path: '',
        name: 'table',
        component: () => import('@/components/Mailings/Mailings.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Массовые рассылки' : 'Sendings',
        },
      },
      {
        path: 'creating/:type',
        name: 'creating',
        component: () => import('@/components/Mailings/Creating/MailingsCreating.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Массовые рассылки' : 'Sendings',
        },
      },
      {
        path: ':type/:id',
        name: 'edit',
        component: () => import('@/components/Mailings/Creating/MailingsCreating.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Массовые рассылки' : 'Sendings',
        },
      },
    ]
  },
  {
    path: '/messenger/',
    name: 'messenger',
    meta: {
      title: store.state.global.language === 'ru' ? 'Мессенджер' : 'Messenger',
    },
  },
  {
    path: '/integration/',
    name: 'integration',
    meta: {
      title: store.state.global.language === 'ru' ? 'Интеграции' : 'Integrations',
    },
  },
  {
    path: '/settings/',
    name: 'settings',
    meta: {
      title: store.state.global.language === 'ru' ? 'Настройки' : 'Settings',
    },
    component: () => import('@/views/SettingsView.vue')
  },
  {
    path: '/create-project/',
    name: 'create-project',
    component: () => import('@/views/CreateProjectView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Создание проекта' : 'Create project',
    },
  },
  {
    path: '/marketplace/',
    name: 'marketplace',
    component: () => import('@/views/MarketplaceView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Маркетплейс' : 'Marketplace',
      requiresNoAuth: true,
    },
  },
  {
    path: '/amo_install/',
    name: 'amo_install',
    component: () => import('@/views/MarketplaceView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Подключение AMO' : 'Connect AMO',
      requiresNoAuth: true,
    },
  },
  {
    path: '/sbercrm_install/',
    name: 'sbercrm_install',
    component: () => import('@/views/MarketplaceView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Подключение Sber' : 'Connect Sber',
      requiresNoAuth: true,
    },
  },
  {
    path: '/marketplace-setting/',
    name: 'marketplace-setting',
    component: () => import('@/components/Marketplace/MarketplaceSettingIntegration/MarketplaceSettingIntegration.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Маркетплейс. Ввод номера' : 'Marketplace. Enter phone',
      requiresNoAuth: true,
    },
  },
  {
    path: '/marketplace-whatsapp/:marketplace',
    name: 'marketplace-whatsapp',
    component: () => import('@/components/Marketplace/MarketplaceWhatsApp/MarketplaceWhatsApp.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Маркетплейс. WhatsApp' : 'Marketplace. WhatsApp',
    },
    props: true,
  },
  {
    path: '/security/',
    name: 'security',
    component: () => import('@/views/SecurityView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Безопасность' : 'Security',
    },
  },
  {
    path: '/analytics/',
    name: 'analytics',
    component: () => import('@/views/AnalyticsView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Аналитика' : 'Analytics',
    },
  },
  {
    path: '/reports/',
    name: 'custom-reports',
    component: () => import('@/views/ReportsView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'Отчеты' : 'Reports',
    },
    children: [
      {
        path: 'templates/',
        name: 'reports-templates',
        component: () => import('@/components/Reports/ReportsTemplates/ReportsTemplates.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Отчеты по шаблонам' : 'Template reports',
        },
      },
      {
        path: 'new-dialogues/',
        name: 'reports-new-dialogues',
        component: () => import('@/components/Reports/ReportsNewDialoguesSMS/ReportsNewDialoguesSMS.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Новые диалоги' : 'New dialogues',
        },
      },
      {
        path: 'sms/',
        name: 'reports-sms',
        component: () => import('@/components/Reports/ReportsNewDialoguesSMS/ReportsNewDialoguesSMS.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'СМС' : 'SMS',
        },
      },
      {
        path: `audit-${store.state.global.isMessageHelp ? 'altegio' : 'yclients'}`,
        name: 'reports-audit-yclients',
        component: () => import('@/components/Reports/ReportsAuditYclients/ReportsAuditYclients.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? `Аудит базы ${store.state.global.isMessageHelp ? 'Altegio' : 'Yclients'}` : `${store.state.global.isMessageHelp ? 'Altegio' : 'Yclients'} database audit`,
        },
      },
    ]
  },
  {
    path: '/changelog_api/',
    name: 'changelog_api',
    component: () => import('@/views/ChangelogView.vue'),
    meta: {
      title: store.state.global.language === 'ru' ? 'История изменений API' : 'API History',
    },
  },
  {
    path: '/integrations_api/',
    name: 'integrations_api',
    component: () => import('@/views/IntegrationsApiView.vue'),
    meta: {
      title: 'API',
    },
  },
  {
    path: '/partners_copy_templates/',
    name: 'partners_copy_templates',
    meta: {
      title: store.state.global.language === 'ru' ? 'Копирование шаблонов' : 'Copy templates',
    },
    component: () => import('@/views/TemplatesCopyView.vue'),
  },
  {
    path: '/partners_clone_templates_new_channels/',
    name: 'partners_clone_templates_new_channels',
    meta: {
      title: store.state.global.language === 'ru' ? 'Копирование шаблонов между проектами и каналами' : 'Copy templates',
    },
    component: () => import('@/views/TemplatesCopyView.vue'),
  },
  {
    path: '/yclients_partners_codes/',
    name: 'yclients_partners_codes',
    meta: {
      title: store.state.global.language === 'ru' ? 'Партнерская программа Yclients' : 'Yclients partners codes',
    },
    component: () => import('@/views/YclientsPartnersCodeView.vue'),
  },
]

if (process.env.VUE_APP_BUILD_TYPE === 'admin') {
  routes.push({
    path: '/adm/',
    children: [
      {
        path: 'tariff_history/:project_id',
        name: 'tariff_history',
        meta: {
          title: store.state.global.language === 'ru' ? 'История тарифа' : 'Tariff history',
        },
        component: () => import('@/views/TariffHistoryView.vue'),
        props: true,
      },
      {
        path: 'copy_templates_new_channels/',
        name: 'copy_templates_new_channels',
        meta: {
          title: store.state.global.language === 'ru' ? 'Копирование шаблонов между проектами и каналами' : 'Copy templates',
        },
        component: () => import('@/views/TemplatesCopyView.vue'),
      },
      {
        path: 'copy_templates/',
        name: 'copy_templates',
        meta: {
          title: store.state.global.language === 'ru' ? 'Копирование шаблонов' : 'Copy templates',
        },
        component: () => import('@/views/TemplatesCopyView.vue'),
      },
      {
        path: 'robots/',
        name: 'robots',
        meta: {
          title: store.state.global.language === 'ru' ? 'Файл robots.txt' : 'File robots.txt',
        },
        component: () => import('@/views/RobotsView.vue'),
      },
      {
        path: 'users/:user_id/payments/recurring/',
        name: 'autoPayments',
        meta: {
          title: store.state.global.language === 'ru' ? 'Отключение автоплатежа' : 'Disabling auto payment',
        },
        component: () => import('@/views/AutoPaymentView.vue'),
        props: true,
      },
      {
        path: 'users-current-project/',
        name: 'users-current-project',
        meta: {
          title: store.state.global.language === 'ru' ? 'Пользователи текущего проекта' : 'Users current project',
        },
        component: () => import('@/views/UsersProjectView.vue'),
        children: [
          {
            path: '',
            name: 'data-users-current-project',
            component: () => import('@/components/Users/UsersProject.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Список пользователей текущего проекта' : 'List users current project',
            },
          },
          {
            path: 'edit-user/',
            name: 'edit-user-current-project',
            component: () => import('@/components/Users/UsersDataEdit/UsersDataCreateEdit.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Редактировать пользователя' : 'Edit user',
            },
          },
          {
            path: 'create-user/:projectId/',
            name: 'create-user-current-project',
            component: () => import('@/components/Users/UsersDataEdit/UsersDataCreateEdit.vue'),
            props: true,
            meta: {
              title: store.state.global.language === 'ru' ? 'Создание пользователя' : 'Create user',
            },
          },
        ]
      },
      {
        path: 'users/',
        name: 'users',
        meta: {
          title: store.state.global.language === 'ru' ? 'Пользователи' : 'Users',
        },
        component: () => import('@/views/UsersView.vue'),
        children: [
          {
            path: '',
            name: 'data-users',
            component: () => import('@/components/Users/Users.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Таблица пользователей' : 'Clients users',
            },
          },
          {
            path: 'edit-user/',
            name: 'edit-user',
            component: () => import('@/components/Users/UsersDataEdit/UsersDataCreateEdit.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Редактировать пользователя' : 'Edit user',
            },
          },
          {
            path: 'create-user/',
            name: 'create-user',
            component: () => import('@/components/Users/UsersDataEdit/UsersDataCreateEdit.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Создание пользователя' : 'Create user',
            },
          },
        ]
      },
      {
        path: 'shares/',
        name: 'shares',
        meta: {
          title: store.state.global.language === 'ru' ? 'Акции' : 'Shares',
        },
        component: () => import('@/views/SharesView.vue')
      },
      {
        path: 'settings-site/',
        name: 'settings-site',
        meta: {
          title: store.state.global.language === 'ru' ? 'Настройки сайта' : 'Settings site',
        },
        component: () => import('@/views/SettingsSiteView.vue'),
        children: [
          {
            path: 'general/',
            name: 'settings-site-general',
            meta: {
              title: store.state.global.language === 'ru' ? 'Общие настройки' : 'General settings',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteGeneral.vue'),
          },
          {
            path: 'discount/',
            name: 'settings-site-discount',
            meta: {
              title: store.state.global.language === 'ru' ? 'Скидка' : 'Discount',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteDiscount.vue'),
          },
          {
            path: 'exchange-rate/',
            name: 'settings-site-exchange-rate',
            meta: {
              title: store.state.global.language === 'ru' ? 'Курсы валют' : 'Exchange rate',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteExchangeRate.vue'),
          },
          {
            path: 'waba/',
            name: 'settings-site-waba',
            meta: {
              title: 'WABA',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteWaba.vue'),
          },
          {
            path: 'header-site/',
            name: 'settings-site-header-site',
            meta: {
              title: store.state.global.language === 'ru' ? 'Информация над шапкой сайта' : 'Information above the site header',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteInfoHeaderSite.vue'),
          },
          {
            path: 'header-admin/',
            name: 'settings-site-header-admin',
            meta: {
              title: store.state.global.language === 'ru' ? 'Информация над шапкой сайта в админке' : 'Information above the site header in the admin',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteInfoHeaderAdmin.vue'),
          },
          {
            path: 'metrics/',
            name: 'settings-site-metrics',
            meta: {
              title: store.state.global.language === 'ru' ? 'Скрипты метрики' : 'Scripts metrics',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteMetrics.vue'),
          },
          {
            path: 'tariffication/',
            name: 'settings-site-tariffication',
            meta: {
              title: store.state.global.language === 'ru' ? 'Тарификация' : 'Tariffication',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteTariffication.vue'),
          },
          {
            path: 'new-tariffication/',
            name: 'settings-site-new-tariffication',
            meta: {
              title: store.state.global.language === 'ru' ? 'Новая тарификация' : 'New tariffication',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteNewTariffication.vue'),
          },
          {
            path: 'partner/',
            name: 'settings-site-partner',
            meta: {
              title: store.state.global.language === 'ru' ? 'Новая партнерка' : 'New partner',
            },
            component: () => import('@/components/SettingsSite/SettingsSitePartner.vue'),
          },
          {
            path: 'translations/',
            name: 'settings-site-translations',
            meta: {
              title: store.state.global.language === 'ru' ? 'Переводы' : 'Translations',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteTranslations.vue'),
          },
          {
            path: 'channels/',
            name: 'settings-site-channels',
            meta: {
              title: store.state.global.language === 'ru' ? 'Каналы' : 'Channels',
            },
            component: () => import('@/components/SettingsSite/SettingsSiteChannels.vue'),
          },
        ]
      },
      {
        path: 'tariffs/',
        name: 'tariffs',
        component: () => import('@/views/AdmTariffsView.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Тарифы' : 'Tariffs',
        },
        children: [
          {
            path: '',
            name: 'tariffs-table',
            component: () => import('@/components/AdmTariffs/AdmTariffs.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Тарифы' : 'Tariffs',
            },
          },
          {
            path: ':id',
            name: 'tariffs-edit',
            component: () => import('@/components/AdmTariffs/AdmTariffsCard.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Тарифы' : 'Tariffs',
            },
          },
          {
            path: 'creating/',
            name: 'tariffs-create',
            component: () => import('@/components/AdmTariffs/AdmTariffsCard.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Тарифы' : 'Tariffs',
            },
          },
        ]
      },
      {
        path: 'tables/',
        name: 'tables',
        component: () => import('@/views/TablesView.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Таблицы' : 'Tables',
        },
      },
      {
        path: 'tables/:entity',
        name: 'tables-detail',
        component: () => import('@/components/Tables/TableDetail.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Таблица' : 'Table',
        },
      },
      {
        path: 'internal-knowledge/',
        name: 'internal-knowledge',
        component: () => import('@/views/InternalKnowledgeView.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Внутренняя база знаний' : 'Internal knowledge',
        },
      },
      {
        path: 'internal-knowledge/:id',
        name: 'internal-knowledge-article',
        component: () => import('@/views/InternalKnowledgeArticleView.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Внутренняя база знаний' : 'Internal knowledge',
        },
      },
      {
        path: 'reports/',
        name: 'reports',
        component: () => import('@/views/AdmReportsView.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Служебные отчеты' : 'Service reports',
        },
        children: [
          {
            path: 'reports_system/',
            name: 'reportsSystem',
            component: () => import('@/components/AdmReports/ReportsSystem/ReportsSystem.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Сводные отчёты' : 'Summary reports',
            },
          },
          {
            path: 'yc_companies/',
            name: 'ycCompanies',
            component: () => import('@/components/AdmReports/YcCompanies/YcCompanies.vue'),
            meta: {
              title: store.state.global.language === 'ru' ? 'Динамика компаний Yclients' : 'Dynamics of Yclients companies',
            },
          },
        ]
      },
      {
        path: 'reports/reports_system/:type/:id',
        name: 'reportSystemDetail',
        component: () => import('@/components/AdmReports/ReportsSystem/ReportSystemDetail.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'Сводные отчёты' : 'Summary reports',
        },
      },
      {
        path: 'lead_sources/',
        name: 'lead-sources-utm',
        component: () => import('@/views/LeadSourcesUtmView.vue'),
        meta: {
          title: store.state.global.language === 'ru' ? 'UTM источников сделок' : 'UTM of deal sources',
        },
      },
    ]
  })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach( (to, from, next) => {
  if (to.path === '/logout/') {
    authService.logout()
    return
  }

  const url = window.location.href.replace('?status=auth#signUp', '').replace(/\?/g, "&");
  const params = new URLSearchParams(url);

  const utmParams = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_content', 'utm_term'];

  utmParams.forEach(param => {
    const value = params.get(param);
    if (value) {
      setCookie(param, value);
    }
  });

  //необходима авторизация, еще не проверяли авторизацию пользователя
  if (!store.state.auth.checkAuth) {
    authService.getUser(to);
    return;

    //необходима авторизация
  } else if (!to.meta.requiresNoAuth && !store.state.auth.isLoggedIn) {
    //TODO переделать
    //открыт iframe для Yclients, отключаем редирект, показ формы авторизации сейчас берет на себя бэк
    if (to.meta.authRedirectDisable) {
      location.reload();
      return;
    }

    if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/channels')){
      return next({name: "Login", query: {redirectTo: '/app/channels-amo'} });
    }else if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/amo_link_managers')){
      return next({ name: "Login", query: {redirectTo: '/app/link-managers-amo'} });
    }else{
      return next({name: "Login", query: { redirectTo: location.pathname }});
    }

  } else if (to.meta.disableForLoggedIn && store.state.auth.isLoggedIn) {
    if(to.query?.redirectTo === '/app/channels-amo'){
      return next({name: "channels-amo"});
    }else if(to.query?.redirectTo === '/app/link-managers-amo'){
      return next({name: "link-managers-amo"});
    }else{
      return next({name: "home"});
    }
  }
  const pageTitle = to.meta && to.meta.title ? to.meta.title : to.name;
  if (pageTitle) {
    document.title = `${store.state.global.language === 'ru' ? "Панель управления" : "Dashboard"}. ${pageTitle}.`;
  }
  //все ок

  next();
})

export default router
