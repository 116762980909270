import global from "@/api/global"
import partners from "@/api/partners";
import tariff from "@/api/tariff";
import history from "@/api/history";
import clients from "@/api/clients";
import settings from "@/api/settings";
import tools from "@/api/tools";
import home from "@/api/home";
import mailings from "@/api/mailings";
import autoPayment from "@/api/autoPayment";
import help from "@/api/help";
import productFruits from "@/api/productFruits";
import shares from "@/api/shares";
import internalKnowledge from "@/api/internalKnowledge";
import reports from "@/api/reports";
import leadSourcesUtm from "@/api/leadSourcesUtm";
import marketplace from "@/api/marketplace";
import templates from "@/api/templates";
import channels from "@/api/channels";
import auth from "@/api/auth";
import tables from "@/api/admStuff";
import admTariffs from "@/api/admTariffs";
import admSettingsSite from "@/api/admSettingsSite";
import channelsAmo from "@/api/channelsAmo";
import analytics from "@/api/analytics";
import linksManagersAmo from "@/api/linksManagersAmo";
import robots from "@/api/robots";

class Api {
    constructor ( config ) {
        this.config = config
        this.global = global
        this.partners = partners
        this.tariff = tariff
        this.history = history
        this.clients = clients
        this.settings = settings
        this.tools = tools
        this.mailings = mailings
        this.home = home
        this.autoPayment = autoPayment
        this.help = help
        this.productFruits = productFruits
        this.shares = shares
        this.internalKnowledge = internalKnowledge
        this.reports = reports
        this.leadSourcesUtm = leadSourcesUtm
        this.marketplace = marketplace
        this.templates = templates
        this.tables = tables
        this.channels = channels
        this.auth = auth
        this.admTariffs = admTariffs
        this.admSettingsSite = admSettingsSite
        this.channelsAmo = channelsAmo
        this.analytics = analytics
        this.linksManagersAmo = linksManagersAmo
        this.robots = robots
    }
}

export default Api
