<template>
  <modal-window
      :id="modalId"
      :showModal="showModal"
      :title="$t('partners.balanceAmountWithdrawModal.title')"
      @closeModal="$emit('close-modal')"
      :width="!$store.state.global.isMessageHelp ? '1150px' : '470px'"
  >
    <template v-slot:body>
      <div class="withdraw" :class="{mh: $store.state.global.isMessageHelp}">
        <template
            v-if="!$store.state.global.isMessageHelp"
        >
          <div
              class="withdraw__row"
              v-for="(fieldRow, idx) in fields"
              :key="idx"
          >

            <div class="withdraw__input"
                 v-for="(field, idx) in fieldRow"
                 :key="idx">
              <label>
                {{ field.label }}
                <span v-if="field.subtitle"> {{ field.subtitle }} </span>
              </label>
              <vue-input v-model="bankDetails[field.value]" :placeholder="field.placeholder" />
            </div>
          </div>
        </template>


        <template v-if="$store.state.global.isMessageHelp">
          <div class="withdraw__input-row">
            <label>
              {{ $t('partners.balanceAmountWithdrawModal.fields.messageHelp.method') }}
            </label>
            <vue-input v-model="bankDetails['method']" :placeholder="$t('partners.balanceAmountWithdrawModal.fields.messageHelp.methodPlaceholder')"/>
          </div>

          <div class="withdraw__input-row">
            <label>
              {{ $t('partners.balanceAmountWithdrawModal.fields.messageHelp.requisites') }}
            </label>
            <vue-input v-model="bankDetails['requisites']" :placeholder="$t('partners.balanceAmountWithdrawModal.fields.messageHelp.requisitesPlaceholder')"/>
          </div>

          <div class="withdraw__input-row">
            <label>
              {{ $t('partners.balanceAmountWithdrawModal.fields.field4.item1.label') }}
            </label>
            <vue-input v-model="bankDetails['sum']" :placeholder="$t('partners.balanceAmountWithdrawModal.fields.field4.item1.placeholder')"/>
          </div>
        </template>



        <p v-if="!$store.state.global.isMessageHelp" class="withdraw__contract">
          {{ $t('partners.balanceAmountWithdrawModal.with') }}
          <a :href="`https://${host}/oferta_partner/`"> {{ $t('partners.balanceAmountWithdrawModal.agreement') }} </a>
          {{ $t('partners.balanceAmountWithdrawModal.familiarized') }}

          <div
              class="withdraw__checkbox"
              @click="isFamiliarized = !isFamiliarized">
            <img v-if="isFamiliarized" src="@/assets/img/icons/checkbox-active-green.svg" alt="active">
            <img v-else src="@/assets/img/icons/checkbox-disabled.svg" alt="disabled">
          </div>
        </p>
      </div>
    </template>

    <template v-slot:footer>
      <btn v-if="!$store.state.global.isMessageHelp" :disabled="!isFamiliarized" :color="'green'" :size="'medium'" @click="withdraw()">
        {{$t('UI.btn.withdraw')}}
      </btn>
      <btn v-else :disabled="!bankDetails.method || !bankDetails.requisites" :color="'green'" :size="'medium'" @click="withdraw()">
        {{$t('UI.btn.withdraw')}}
      </btn>
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "@/components/UI/ModalWindow.vue";
import {mapState} from "vuex";
import partnersService from "@/services/partnersService";

export default {
  components:{
    ModalWindow
  },
  emits:['close-modal'],
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      modalId: 'withdrawModal',
      bankDetails: {},
      fields: [
        [
          { value: 'contract_number', label: this.$t('partners.balanceAmountWithdrawModal.fields.field1.item1.label'), subtitle: this.$t('partners.balanceAmountWithdrawModal.fields.field1.item1.subtitle'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field1.item1.placeholder') },
          { value: 'payment_purpose', label: this.$t('partners.balanceAmountWithdrawModal.fields.field1.item2.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field1.item2.placeholder') },
          { value: 'corr_check', label: this.$t('partners.balanceAmountWithdrawModal.fields.field1.item3.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field1.item3.placeholder') },
        ],
        [
          { value: 'recipient', label: this.$t('partners.balanceAmountWithdrawModal.fields.field2.item1.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field2.item1.placeholder') },
          { value: 'bik', label: this.$t('partners.balanceAmountWithdrawModal.fields.field2.item2.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field2.item2.placeholder') },
          { value: 'inn', label: this.$t('partners.balanceAmountWithdrawModal.fields.field2.item3.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field2.item3.placeholder') },
        ],
        [
          { value: 'account_number', label: this.$t('partners.balanceAmountWithdrawModal.fields.field3.item1.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field3.item1.placeholder') },
          { value: 'bank', label: this.$t('partners.balanceAmountWithdrawModal.fields.field3.item2.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field3.item2.placeholder') },
          { value: 'kpp', label: this.$t('partners.balanceAmountWithdrawModal.fields.field3.item3.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field3.item3.placeholder') },
        ],
        [
          { value: 'sum', label: this.$t('partners.balanceAmountWithdrawModal.fields.field4.item1.label'), placeholder: this.$t('partners.balanceAmountWithdrawModal.fields.field4.item1.placeholder') },
        ],
      ],
      isFamiliarized: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),

    host() {
      return window.location.hostname;
    }
  },
  methods: {
    async withdraw() {
      await partnersService.withdrawBalance({ ...this.bankDetails });
      this.$store.commit('global/showToast', {isError: false, text: this.$t('partners.withdrawBalanceToast')})
      this.$emit('close-modal');
    }
  },
  mounted() {
    this.bankDetails = { ...this.user.bank_details };
  }
}
</script>

<style lang="scss" scoped>
.withdraw {
  width: 1100px;
  &.mh {
    width: 420px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input {
    width: 32%;
    margin-bottom: 24px;
    &-row {
      width: 100%;
      margin-bottom: 10px;
    }
    label {
      color: var(--default-text);
      font-family: Manrope;
      font-size: 14px;
      font-weight: 600;

      span {
        font-size: 11px;
        color: gray;
      }
    }

    input {
      margin-top: 3px;
    }
  }

  &__contract {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;

    a {
      margin: 0 3px;
      color: #45AD99;
      text-decoration: underline;
    }
  }

  &__checkbox {
    margin: 6px 0 0 6px;
    display: inline;
    cursor: pointer;
  }
}

.btn {
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

</style>