<template>
  <nav class="side-menu-list" :class="{ mini : $store.state.global.miniSb }">
    <ul>
      <template
          v-for="(sideMenuItem) in sideMenuItems"
          :key="sideMenuItem.id"
      >
        <router-link
            @click="onClickLinkHandler"
            v-if="vueLinks[sideMenuItem.href]"
            tag="li"
            :to="{ path: vueLinks[sideMenuItem.href], query: getQuery(sideMenuItem.href) }"
            class="link"
            :class="{'events-none': !isSideOpen && isActiveMiniMenu }">
          <img class="img-svg" :src="images[sideMenuItem.href]" alt="side menu icon">

          <div class="text" v-if="isSideOpen || !isActiveMiniMenu">
            {{ sideMenuItem.title }}
          </div>
        </router-link>

        <a
            v-if="!vueLinks[sideMenuItem.href] && sideMenuItem.title"
            class="link"
            :href="sideMenuItem.child && !sideMenuItem.showAsTabs ? '##' : `${sideMenuItem.href}`"
            @click="showChildElements(sideMenuItem)"
            :class="{'events-none': !isSideOpen && isActiveMiniMenu }">
          <img class="img-svg"
               :src="sideMenuItem.child && !sideMenuItem.showAsTabs && !sideMenuItem.showElements
                      ? images.plus
                      : sideMenuItem.child && !sideMenuItem.showAsTabs && sideMenuItem.showElements
                      ? images.minus
                      : `${sideMenuItem.img}`" alt="side menu icon"
          >
          <div class="text"
               v-if="isSideOpen || !isActiveMiniMenu">
            {{ sideMenuItem.title }}
          </div>
        </a>

        <template v-if="sideMenuItem.showElements">
          <template v-for="(child) in sideMenuItem.child">
            <router-link
                v-if="vueLinks[child.href]"
                :to="vueLinks[child.href]"
                class="link link-child"
                @click="onClickLinkHandler"
            >
              {{ child.title }}
            </router-link>

            <a v-else :href="child.href" class="link link-child">
              {{ child.title }}
            </a>
          </template>
        </template>
      </template>

<!--      <div-->
<!--          class="link link&#45;&#45;theme"-->
<!--          :class="{'events-none': !isSideOpen && isActiveMiniMenu }">-->
<!--        <img class="img-svg" :src="images['darkTheme']" alt="side menu icon">-->

<!--        <div class="text text&#45;&#45;theme"-->
<!--             v-if="isSideOpen || !isActiveMiniMenu">-->
<!--          Темная тема-->
<!--        </div>-->

<!--        <checkbox-switch-->
<!--          v-model="$store.state.global.isDarkTheme"-->
<!--          checkboxId="theme"-->
<!--          size="small"-->
<!--          @on-change="changeTheme"-->
<!--        />-->
<!--      </div>-->

      <router-link
          @click="logout"
          v-if="!$store.state.global.sideMenuItems || !$store.state.global.sideMenuItems.length"
          tag="li"
          :to="vueLinks['logout']"
          class="link"
          :class="{'events-none': !isSideOpen && isActiveMiniMenu }">
        <img class="img-svg" :src="images['logout']" alt="side menu icon">

        <div class="text"
             v-if="isSideOpen || !isActiveMiniMenu">
          {{ $t('UI.btn.exit') }}
        </div>
      </router-link>
    </ul>
  </nav>
</template>

<script>
import {mapState} from "vuex";
import CheckboxSwitch from "@/components/UI/CheckboxSwitch.vue";
import resolutionMixin from "@/mixins/resolutionMixin";
import authService from "@/services/authService";

export default {
  name: "SideMenuList",
  components: {CheckboxSwitch},
  props: {
    isSideOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      images: {
        '/app/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-main.svg`,
        '/app/channels/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-10.svg`,
        '/app/templates/menu': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-1.svg`,
        growth: `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-3.svg`,
        '/app/tariff/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-7.svg`,
        '/app/mailings/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-mailings.svg`,
        '/app/settings/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-settings.svg`,
        '/app/partners/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-6.svg`,
        '/app/help': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-8.svg`,
        plus: `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-plus.svg`,
        minus: `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-minus.svg`,
        logout: `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-9.svg`,
        '/app/clients/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-clients.svg`,
        '/app/history/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-history.svg`,
        darkTheme: `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-night.svg`,
        '/app/tools/': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-3.svg`,
        '/app/security': `${this.$store.state.global.publicPath}side-menu-icons/side-menu-icon-security.svg`
      },
      vueLinks: {
        '/app/': '/',
        '/app/partners/': '/partners',
        '/app/tariff/': '/tariff',
        logout: '/login/',
        '/app/clients/': '/clients/',
        '/app/history/': '/history/',
        '/app/mailings/': '/mailings/',
        '/app/help': '/help/',
        '/app/tools/': '/tools/',
        '/app/security': '/security/',
        '/app/settings/': '/settings/',
        '/app/channels/': '/channels/',
        '/app/templates/menu': '/templates/menu',
        '/app/adm/settings-site/': '/adm/settings-site/',
        '/app/adm/shares': '/adm/shares',
        '/app/adm/internal-knowledge/': '/adm/internal-knowledge/',
        '/app/adm/tables/ai_bots_faq': '/adm/tables/ai_bots_faq',
        '/app/channels-amo/': '/channels-amo/',
        '/app/adm/tariffs/': '/adm/tariffs/',
        '/app/adm/users-current-project': '/adm/users-current-project/',
        '/app/adm/tables/log': '/adm/tables/log/',
        '/app/tariff/?projects=referrals': '/tariff/?projects=referrals'
      },
    }
  },
  mixins: [ resolutionMixin ],
  computed: {
    ...mapState('global', ['sideMenuItems']),

    isActiveMiniMenu(){
      return this.resolution.width <= 1200
    },
  },
  methods: {
    getQuery(href) {
      const queryString = href.split('?')[1];
      if (!queryString) return {};

      return queryString.split('&').reduce((acc, param) => {
        const [key, value] = param.split('=');
        acc[key] = value === undefined ? true : decodeURIComponent(value);
        return acc;
      }, {});
    },
    async logout() {
      await authService.logout();
    },
    onClickLinkHandler() {
      this.$emit('close-side')
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({top: 0})
    },
    showChildElements(sideMenuItem) {
      if (sideMenuItem.child && !sideMenuItem.showAsTabs) {
        sideMenuItem.showElements = !sideMenuItem.showElements
      }
    },
    // changeTheme() {
    //   localStorage.isDarkTheme = this.$store.state.global.isDarkTheme;
    //   document.documentElement.className = this.$store.state.global.isDarkTheme ? 'dark-theme' : '';
    // }
  },
  mounted() {
    // if (localStorage.isDarkTheme) {
    //   localStorage.isDarkTheme = JSON.stringify(false);
    //   this.$store.state.global.isDarkTheme = JSON.parse(localStorage.isDarkTheme);
    //   document.documentElement.className = this.$store.state.global.isDarkTheme ? 'dark-theme' : '';
    // }
  }
}
</script>

<style lang="scss" scoped>
.img-svg {
  width: 20px;
  height: 20px;
  filter: grayscale(1);
}

.events-none {
  pointer-events: none;

}

.side-menu-list {
  margin-top: 16px;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    list-style: none;

    .link, .logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      padding: 14px 0 14px 44px;
      width: 100%;
      min-height: 48px;
      margin-top: 4px;
      border-radius: 12px;

      &--theme {
        cursor: default;
      }

      &.link-child {
        padding-left: 14px;
        padding-right: 14px;
      }

      .text {
        padding-right: 14px;

        &--theme {
          margin-right: 20px;
        }
      }

      &:hover {
        background: var(--grey-background);
      }

      &:first-child {
        margin-top: 0;
      }

      &.router-link-active {
        background: var(--grey-background);
      }

      img {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &.mini {
    ul {
      a {
        padding: 14px 12px;
        width: 44px;
        height: 44px;

        .text {
          display: none;
        }
      }

      li {
        padding: 14px 12px;
        width: 44px;
        height: 44px;

        .text {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .side-menu-list {
    ul {
      width: fit-content;

      a {
        width: fit-content;

      }
    }
  }
}
</style>
