import Api from '@/api/index.js'
import store from "@/store";
import { requestHandler, basePath } from '@/utils/apiHelpers';

class sharesService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getShares = async function (filtersStr) {
            return requestHandler(async () => {
                let response = await api.shares.getShares(filtersStr);
                store.commit('shares/setShares', response.data);
                store.commit('shares/setIsActiveLoader', false);
            })
        }

        this.getConfig = async function () {
            return requestHandler(async () => {
                let response = await api.shares.getConfig();
                store.commit('shares/setConfig', response.config);
            })
        }

        this.createShare = async function (share) {
            return requestHandler(async () => {
                let response = await api.shares.createShare(share);
                store.commit('shares/pushNewShare', response.data);
            })
        }

        this.editShare = async function (share) {
            return requestHandler(async () => {
                let response = await api.shares.editShare(share);
                store.commit('shares/changeShare', response.data);
            })
        }

        this.deleteShare = async function (id) {
            return requestHandler(async () => {
                await api.shares.deleteShare(id);
            })
        }
    }
}

export default new sharesService()