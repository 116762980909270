import Api from '@/api/index.js'
import store from "@/store";
import {basePath, requestHandler} from '@/utils/apiHelpers';

class PartnersService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getPartnerInfo = async function () {
            return requestHandler(async () => {
                return await api.partners.getPartnerInfo();
            })
        }

        this.getPartnersUsers = async function (page, fromObserver, count, isFromTariffs, searchQuery) {
            return requestHandler(async () => {
                let response = await api.partners.getPartnersUsers(page, count, isFromTariffs, searchQuery);

                if (fromObserver) {
                    store.commit('partners/setClients', [ ...store.state.partners.clients, ...response.data ]);
                } else {
                    store.commit('partners/setClients', response.data);
                }

                return response;
            })
        }

        this.addPartnersUser = async function (client) {
            return requestHandler(async () => {
                let response = await api.partners.postPartnersUsers(client);

                store.commit('partners/addClient', { projects: [], ...response.data });

                return response;
            })
        }

        this.editPartnerUser = async function (client) {
            return requestHandler(async () => {
                let response = await api.partners.putPartnersUser(client);

                store.commit('partners/editClient', {
                    projects: client.projects ? client.projects : [],
                    ...response.data
                });

                return response;
            })
        }

        this.getReferralUsers = async function () {
            requestHandler(async () => {
                let response = await api.partners.getReferralUsers();

                store.commit('partners/setReferralUsers', response.data);

                return response;
            })
        }

        this.getReferralCodes = async function () {
            requestHandler(async () => {
                let response = await api.partners.getReferralCodes();

                store.commit('partners/setReferralCodes', response.data);

                return response;
            })
        }

        this.addReferralCode = async function (code) {
            return requestHandler(async () => {
                let response = await api.partners.postReferralCodes(code);

                store.commit('partners/addReferralCode', response.data);

                return response;
            })
        }

        this.editReferralCode = async function (code) {
            return requestHandler(async () => {
                let response = await api.partners.putReferralCodes(code);

                store.commit('partners/editReferralCode', { ...code, ...response.data });

                return response;
            })
        }

        this.deleteReferralCode = async function (id) {
            requestHandler(async () => {
                let response = await api.partners.deleteReferralCode(id);

                store.commit('partners/deleteReferralCode', id);

                return response;
            })
        }

        this.getReferralTransactions = async function () {
            requestHandler(async () => {
                let response = await api.partners.getReferralTransactions();

                store.commit('partners/setTransactions', response.data);

                return response;
            })
        }

        this.getReferralBalance = async function () {
            requestHandler(async () => {
                let response = await api.partners.getReferralBalance();

                store.commit('partners/setBalance', response.data.balance);

                return response;
            })
        }

        this.withdrawBalance = async function (data) {
            requestHandler(async () => {
                //store.commit('partners/setBalance', response.data.balance);

                return await api.partners.postWithdrawBalance(data);
            })
        }

        this.transferBalance = async function () {
            requestHandler(async () => {
                let response = await api.partners.postTransferBalance();

                if (response.success) {
                    store.state.auth.user.balance += store.state.partners.balance;
                }

                await this.getReferralBalance();

                return response;
            })
        }

        this.putProjectInfo = async function (data, id) {
            return await requestHandler(async () => {
                return await api.partners.putProjectInfo(data, id);
            })
        }

        this.getPartnerCodes = async function (query) {
            return await requestHandler(async () => {
                return await api.partners.getPartnerCodes(query);
            })
        }
        this.getPartnerCode = async function (id) {
            return await requestHandler(async () => {
                return await api.partners.getPartnerCode(id);
            })
        }
        this.createPartnerCode = async function (data) {
            return await requestHandler(async () => {
                return await api.partners.createPartnerCode(data);
            })
        }
        this.editPartnerCode = async function (id, data) {
            return await requestHandler(async () => {
                return await api.partners.editPartnerCode(id, data);
            })
        }
        this.deletePartnerCode = async function (id) {
            return await requestHandler(async () => {
                return await api.partners.deletePartnerCode(id);
            })
        }
    }
}

export default new PartnersService()