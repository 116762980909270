import store from "@/store";

import clientsRu from './ru/clients.json';
import historyRu from './ru/history.json';
import loginRu from './ru/login.json';
import mailingsRu from './ru/mailings.json';
import partnersRu from './ru/partners.json';
import settingsRu from './ru/settings.json';
import sideMenuRu from './ru/sideMenu.json';
import tariffRu from './ru/tariff.json';
import toolsRu from './ru/tools.json';
import UIRu from './ru/UI.json';
import homeRu from './ru/home.json'
import createProjectRu from './ru/createProject.json'
import emojiPickerRu from './ru/emojiPicker.json';
import marketplaceRu from './ru/marketplace.json'
import headerFooterRu from './ru/headerFooter.json'
import securityRu from './ru/security.json';
import autoPaymentRu from './ru/autoPayment.json';
import helpRu from './ru/help.json';
import sharesRu from './ru/shares.json';
import templatesRu from './ru/templates.json'
import channelsRu from './ru/channels.json'
import channelsAmoRu from './ru/channelsAmo.json'
import usersRu from './ru/users.json'
import analyticsRu from './ru/analytics.json'
import reportsRu from './ru/reports.json'
import linksManagersAmoRu from './ru/linksManagersAmo.json'
import robotsRu from "./ru/robots.json";

import clientsEn from './en/clients.json';
import historyEn from './en/history.json';
import loginEn from './en/login.json';
import mailingsEn from './en/mailings.json';
import partnersEn from './en/partners.json';
import settingsEn from './en/settings.json';
import sideMenuEn from './en/sideMenu.json';
import tariffEn from './en/tariff.json';
import toolsEn from './en/tools.json';
import UIEn from './en/UI.json';
import homeEn from './en/home.json'
import createProjectEn from './en/createProject.json'
import emojiPickerEn from './en/emojiPicker.json';
import marketplaceEn from './en/marketplace.json'
import headerFooterEn from './en/headerFooter.json'
import securityEn from './en/security.json';
import autoPaymentEn from './en/autoPayment.json';
import helpEn from './en/help.json';
import sharesEn from './en/shares.json';
import templatesEn from './en/templates.json'
import channelsEn from './en/channels.json'
import channelsAmoEn from './en/channelsAmo.json'
import usersEn from './en/users.json'
import analyticsEn from './en/analytics.json';
import reportsEn from './en/reports.json'
import linksManagersAmoEn from './en/linksManagersAmo.json'
import robotsEn from "./en/robots.json";

import clientsPt from './pt/clients.json';
import historyPt from './pt/history.json';
import loginPt from './pt/login.json';
import mailingsPt from './pt/mailings.json';
import partnersPt from './pt/partners.json';
import settingsPt from './pt/settings.json';
import sideMenuPt from './pt/sideMenu.json';
import tariffPt from './pt/tariff.json';
import toolsPt from './pt/tools.json';
import UIPt from './pt/UI.json';
import homePt from './pt/home.json'
import createProjectPt from './pt/createProject.json'
import emojiPickerPt from './pt/emojiPicker.json';
import marketplacePt from './pt/marketplace.json'
import headerFooterPt from './pt/headerFooter.json'
import securityPt from './pt/security.json'
import autoPaymentPt from './pt/autoPayment.json';
import helpPt from './pt/help.json';
import sharesPt from './pt/shares.json';
import templatesPt from './pt/templates.json'
import channelsPt from './pt/channels.json'
import channelsAmoPt from './pt/channelsAmo.json'
import usersPt from './pt/users.json'
import analyticsPt from './pt/analytics.json';
import reportsPt from './pt/reports.json'
import linksManagersAmoPt from './pt/linksManagersAmo.json'
import robotsPt from "./pt/robots.json";

import clientsEs from './es/clients.json';
import historyEs from './es/history.json';
import loginEs from './es/login.json';
import mailingsEs from './es/mailings.json';
import partnersEs from './es/partners.json';
import settingsEs from './es/settings.json';
import sideMenuEs from './es/sideMenu.json';
import tariffEs from './es/tariff.json';
import toolsEs from './es/tools.json';
import UIEs from './es/UI.json';
import homeEs from './es/home.json'
import createProjectEs from './es/createProject.json'
import emojiPickerEs from './es/emojiPicker.json';
import marketplaceEs from './es/marketplace.json'
import headerFooterEs from './es/headerFooter.json'
import securityEs from './es/security.json'
import autoPaymentEs from './es/autoPayment.json';
import helpEs from './es/help.json';
import sharesEs from './es/shares.json';
import templatesEs from './es/templates.json'
import channelsEs from './es/channels.json'
import channelsAmoEs from './es/channelsAmo.json'
import usersEs from './es/users.json'
import analyticsEs from './es/analytics.json';
import reportsEs from './es/reports.json'
import linksManagersAmoEs from './es/linksManagersAmo.json'
import robotsEs from "./es/robots.json";

//Язык по дефолту
export const defaultLocale = store.state.global.language

//Загрузка всех языков из json файлов в translate
export const languages = {
    ru: {
        clients: clientsRu,
        history: historyRu,
        login: loginRu,
        mailings: mailingsRu,
        partners: partnersRu,
        settings: settingsRu,
        sideMenu: sideMenuRu,
        tariff: tariffRu,
        tools: toolsRu,
        UI: UIRu,
        home: homeRu,
        createProject: createProjectRu,
        emojiPicker: emojiPickerRu,
        marketplace: marketplaceRu,
        headerFooter: headerFooterRu,
        security: securityRu,
        autoPayment: autoPaymentRu,
        help: helpRu,
        shares: sharesRu,
        templates: templatesRu,
        channels: channelsRu,
        channelsAmo: channelsAmoRu,
        users: usersRu,
        analytics: analyticsRu,
        reports: reportsRu,
        linksManagersAmo: linksManagersAmoRu,
        robots: robotsRu
    },

    en: {
        clients: clientsEn,
        history: historyEn,
        login: loginEn,
        mailings: mailingsEn,
        partners: partnersEn,
        settings: settingsEn,
        sideMenu: sideMenuEn,
        tariff: tariffEn,
        tools: toolsEn,
        UI: UIEn,
        home: homeEn,
        createProject: createProjectEn,
        emojiPicker: emojiPickerEn,
        marketplace: marketplaceEn,
        headerFooter: headerFooterEn,
        security: securityEn,
        autoPayment: autoPaymentEn,
        help: helpEn,
        shares: sharesEn,
        templates: templatesEn,
        channels: channelsEn,
        channelsAmo: channelsAmoEn,
        users: usersEn,
        analytics: analyticsEn,
        reports: reportsEn,
        linksManagersAmo: linksManagersAmoEn,
        robots: robotsEn
    },

    pt: {
        clients: clientsPt,
        history: historyPt,
        login: loginPt,
        mailings: mailingsPt,
        partners: partnersPt,
        settings: settingsPt,
        sideMenu: sideMenuPt,
        tariff: tariffPt,
        tools: toolsPt,
        UI: UIPt,
        home: homePt,
        createProject: createProjectPt,
        emojiPicker: emojiPickerPt,
        marketplace: marketplacePt,
        headerFooter: headerFooterPt,
        security: securityPt,
        autoPayment: autoPaymentPt,
        help: helpPt,
        shares: sharesPt,
        templates: templatesPt,
        channels: channelsPt,
        channelsAmo: channelsAmoPt,
        users: usersPt,
        analytics: analyticsPt,
        reports: reportsPt,
        linksManagersAmo: linksManagersAmoPt,
        robots: robotsPt
    },
    es: {
        clients: clientsEs,
        history: historyEs,
        login: loginEs,
        mailings: mailingsEs,
        partners: partnersEs,
        settings: settingsEs,
        sideMenu: sideMenuEs,
        tariff: tariffEs,
        tools: toolsEs,
        UI: UIEs,
        home: homeEs,
        createProject: createProjectEs,
        emojiPicker: emojiPickerEs,
        marketplace: marketplaceEs,
        headerFooter: headerFooterEs,
        security: securityEs,
        autoPayment: autoPaymentEs,
        help: helpEs,
        shares: sharesEs,
        templates: templatesEs,
        channels: channelsEs,
        channelsAmo: channelsAmoEs,
        users: usersEs,
        analytics: analyticsEs,
        reports: reportsEs,
        linksManagersAmo: linksManagersAmoEs,
        robots: robotsEs
    },
}