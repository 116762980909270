import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getSites: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/minilanding`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getSiteById: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/minilanding/${id}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getSiteByIdWithoutAuth: async (id) =>
        messCall(`/growth_tools/minilanding/${id}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postSites: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/minilanding`, {method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postSitesFiles: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/minilanding/file`, {method: 'post', format: 'form', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putSites: async (id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/minilanding/${id}`, {method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteSite: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/minilanding/${data.id}`, {method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getWidgets: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/widget`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putWidget: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/widget`, {method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postWidgetsFile: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/widget/file`, {method: 'post', format: 'form', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getLinks: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/whatsapp_links`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postLinks: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/whatsapp_links`, {method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putLinks: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/whatsapp_links/${data.id}`, {method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteLink: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/growth_tools/whatsapp_links/${data.id}`, {method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}
