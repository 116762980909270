<template>
  <span :class="color" class="unread"/>
</template>

<script>
export default {
  name: "unread",
  props: {
    color: {
      type: String,
      default: 'green'
    }
  }
}
</script>

<style lang="scss" scoped>
.unread{
  display: flex;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  &.red {
    background: #F84B4B;
  }
  &.green {
    background: #54C8B1;
  }
}
</style>