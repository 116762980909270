import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getAppConfig: async () =>
        messCall(`/config/`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getSideMenu: async (lang) =>
        messCall(`/sidebar/?LANG=${lang}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/deprecated'),
    postProject: async (data) =>
        messCall(`/projects/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getCountries: async (country) =>
        messCall(`/countries?filter=${country}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getCities: async (city) =>
        messCall(`/cities?filter=${city}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getHeaderFooter: async () =>
        messCall(`/site_template`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    postNpsPoll: async (data) =>
        messCall(`/user/nps_poll`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}