<template>
  <modal-window :id="modalId" :showModal="showModal"
                :title="$t('sideMenu.createProjectModal.title')"
                @closeModal="$emit('close-modal')">
    <template v-slot:body>
      <div class="create-project__input">
        <label> {{ $t('sideMenu.createProjectModal.nameProject') }} </label>
        <vue-input v-model="name" :placeholder="$t('UI.placeholder.nameProject')" />
      </div>
    </template>

    <template v-slot:footer>
      <btn @click="createProject()">{{ $t('UI.btn.create') }}</btn>
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "@/components/UI/ModalWindow.vue";
import {mapMutations, mapState} from "vuex";
import globalService from "@/services/globalService";
import store from "@/store";

export default {
  components:{
    ModalWindow
  },
  emits:['close-modal'],
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      modalId: 'createProjectModal',
      name: ''
    }
  },
  computed: mapState('partners', ['chosenClientId']),
  methods: {
    ...mapMutations('projects', ['setProject', 'addProject']),
    ...mapMutations('partners', ['setChosenClientId', 'addProjectInClient']),

    async createProject() {
      const res = await globalService.createProject({
        name: this.name,
        user_id: this.chosenClientId,
        type: "bot",
        language: this.$store.state.global.isMessageHelp ? this.$store.state.global.language || 'en' : "ru",
        channels: ["whatsapp"]
      });

      this.addProject(res.data);

      this.setProject(res.data);

      if (this.chosenClientId) {
        this.addProjectInClient(res.data);
        this.setChosenClientId(null);
      }

      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.create-project {
  &__input {
    margin-bottom: 24px;

    label {
      display: block;
      margin-bottom: 3px;
      color: var(--default-text);
      font-family: Manrope;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

</style>