export default {
    namespaced: true,
    state: {
        user: {},//данные о пользователе
        isLoggedIn: false,//авторизован, получены данные о пользователе
        token: localStorage.AuthToken && localStorage.AuthTokenExpires > Math.floor(Date.now() / 1000) ? localStorage.AuthToken : false,//токен авторизации, аналогичен COOKIE['profile']
        checkAuth: false,//проводилась проверка на авторизацию. для совместимости со старой авторизацией по куке проверка обязательна
        userMarketplace: null,
        providers: []
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            if(Object.keys(user).length){
                this.commit("auth/setLoggedIn", true);
            }
        },
        setLoggedIn(state, bool) {
            state.isLoggedIn = bool;
            state.checkAuth = true;
            //видимо слетела авторизация, трем токен
            if(!bool){
                this.commit("auth/setToken",{});
            }
        },
        setToken(state, token) {
            localStorage.AuthToken = token.token;
            localStorage.AuthTokenExpires = token.expires;
            state.token = localStorage.AuthToken && localStorage.AuthTokenExpires > Math.floor(Date.now() / 1000) ? localStorage.AuthToken : false
        }
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        getToken(state) {
            return state.token
        },
        hasPermission:  state => permission => {
            return state.user.permissions &&
                (state.user.permissions.indexOf(permission) != -1 || state.user.permissions.indexOf("_ALL_") != -1);
        },
    },
    actions: {}
};