import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getPartnerInfo: async () =>
        messCall(`/partners/info`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getPartnersUsers: async (page, count, isFromTariffs, searchQuery) =>
        messCall(`/partners/users/?page=${page}&count=${count ? count : 50}${searchQuery ? `&filter=${searchQuery}` : ''}&with[]=projects${!isFromTariffs ? '&with[]=projects.last_transaction' : ''}${!isFromTariffs ? '&with[]=projects.partner_total_earned' : ''}${isFromTariffs ? '&with[]=projects.price_partner' : ''}${!isFromTariffs ? '&with[]=projects.partner_pays_sms' : ''}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postPartnersUsers: async (data) =>
        messCall(`/partners/users/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putPartnersUser: async (data) =>
        messCall(`/partners/users/${data.id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    putProjectInfo: async (data, id) =>
        messCall(`/projects/${id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getReferralUsers: async () =>
        messCall(`/referrals/users/?with[]=last_referral_transaction&with[]=total_earned`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getReferralCodes: async () =>
        messCall(`/referrals/refcodes/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postReferralCodes: async (data) =>
        messCall(`/referrals/refcodes/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putReferralCodes: async (data) =>
        messCall(`/referrals/refcodes/${data.id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteReferralCode: async (id) =>
        messCall(`/referrals/refcodes/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getReferralTransactions: async () =>
        messCall(`/referrals/transactions/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getReferralBalance: async () =>
        messCall(`/referrals/balance/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postWithdrawBalance: async (data) =>
        messCall(`/referrals/balance/withdrawal/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postTransferBalance: async () =>
        messCall(`/referrals/balance/transfer_to_main_balance/`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),


    // yclients_partners_codes
    getPartnerCodes: async (query= '') =>
        messCall(`/partners_codes${query}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getPartnerCode: async (id) =>
        messCall(`/partners_codes/${id}/`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    createPartnerCode: async (data) =>
        messCall(`/partners_codes`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    editPartnerCode: async (id, data) =>
        messCall(`/partners_codes/${id}/`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deletePartnerCode: async (id) =>
        messCall(`/partners_codes/${id}/`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}