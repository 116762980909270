import store from "@/store";
import axios from "axios";

const errors = [400, 401, 402, 403, 404];

const isLocalhost = location.hostname === 'localhost';

const basePath = isLocalhost ? 'http://localhost:8081/' : '/';

async function messCall (path, { method = 'get', format, data, params = {} } = {}, headersArg = {}, mainUrl = '') {
    let url = `${isLocalhost ? `http://localhost:8081${mainUrl}`: mainUrl }${path}`;

    if (!store.getters['projects/isNewVersion']) {
        if ( mainUrl === '/adm/chat' ) {
            url = `${isLocalhost ? `http://localhost:8081/adm/chat_new`: '/adm/chat_new' }${path}`;
        }
    }

    let headers = {}

    if ((method === 'post' || method === 'put') && data && format === 'form') {
        let data1= new FormData();

        //headers = {'Content-Type': 'application/x-www-form-urlencoded'}
        headers = {'Content-Type': 'multipart/form-data'}

        Object.entries(data).forEach(([key, value]) => {
            if (value instanceof File) {
                data1.append(key, value, value.name)
            }
            else if (Array.isArray(value)) {
                value.forEach(value => {
                    if (value instanceof File) {
                        data1.append(`${key}[]`, value, value.name);
                    } else {
                        console.log(data1)
                        data1.append(`${key}[]`, value.toString());
                    }
                })
            }
            else if (value instanceof Object) {
                data1.append(key, JSON.stringify(value))
            } else if(value) {
                data1.append(key, value.toString())
            }
        })

        data = data1;
    }

    if (headersArg)   {
        headers = headersArg;
    }

    const response = await axios.request({
        url,
        method,
        data,
        params,
        withCredentials: true,
        headers
    })

    if (response.status !== 200 )
        throw `${response.status} ${path}`

    // typeof response.data !== 'object' ||
    if (response.data && ( response.data.error)) {
        throw {status:response.status, path,error:  response.data.error}
    }

    return response.data
}

function requestHandler(action, errorAction) {
    return new Promise((resolve, reject) => {
        const res = action();
        res.then(data => {
                resolve(data);
            })
            .catch(err => {
                if (errorAction) {
                    errorAction();
                }
                reject(err);
            });
    })
}

export { messCall, requestHandler, isLocalhost, basePath }