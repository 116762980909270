export default {
    namespaced: true,
    state: {
        clients: [],
        editedClient: {},
        chosenClientId: null,

        referralUsers: [],
        referralCodes: [],
        editingCode: {},

        transactions: [],
        balance: null,
    },
    actions: {

    },
    mutations: {
        setClients(state, clients) {
            state.clients = clients;
        },
        addClient(state, client) {
            state.clients.push(client);
        },
        editClient(state, editedClient) {
            const clientIdx = state.clients.findIndex(client => client.id == editedClient.id);

            state.clients[clientIdx] = editedClient;
        },
        setEditedClient(state, editedClient) {
            state.editedClient = editedClient;
        },
        setChosenClientId(state, id) {
          state.chosenClientId = id;
        },
        addProjectInClient(state, project) {
            const clientIdx = state.clients.findIndex(client => client.id == state.chosenClientId);

            state.clients[clientIdx].projects.push({
                ...project,
                last_transaction: [],
                partner_total_earned: 0
            });
        },

        setReferralUsers(state, users) {
            state.referralUsers = users;
        },

        setReferralCodes(state, codes) {
            state.referralCodes = codes;
        },
        addReferralCode(state, code) {
            state.referralCodes.push(code);
        },
        editReferralCode(state, editedCode) {
            const codeIdx = state.referralCodes.findIndex(code => code.id == editedCode.id);

            if(codeIdx !== -1){
                state.referralCodes[codeIdx] = editedCode
            }
        },
        deleteReferralCode(state, id) {
            const codeIdx = state.referralCodes.findIndex(code => code.id == id);

            state.referralCodes.splice(codeIdx, 1);
        },
        setEditingCode(state, code) {
            state.editingCode = code;
        },

        setTransactions(state, transactions) {
            state.transactions = transactions;
        },
        setBalance(state, balance) {
            state.balance = balance;
        },
    },
    getters: {

    },
}