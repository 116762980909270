import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getNotifications: async () =>
        messCall(`/notifications`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getNotificationsConfig: async () =>
        messCall(`/notifications/config`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getNotificationsTitles: async () =>
        messCall(`/notifications/titles`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postNotifications: async (data) =>
        messCall(`/notifications/`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putNotifications: async (data) =>
        messCall(`/notifications/${data.id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putNotificationsSettings: async (data) =>
        messCall(`/notifications/settings`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getNotificationsSettings: async () =>
        messCall(`/notifications/settings`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getGeneralSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/general`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putGeneralSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/general`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getScriptsSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/scripts`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putScriptsSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/scripts`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTemplateVars: async (projectId) =>
        messCall(`/projects/${projectId ? projectId : store.state.projects.currentProjectId}/config/template_vars`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putTemplateVars: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/template_vars`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getCascadeSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/cascade`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putCascadeSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/cascade`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getChatSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/chat`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putChatSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/chat`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTranslationsVarsSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/translation_vars`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putTranslationsVarsSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/translation_vars`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getYclientsSettings: async (projectId = '') =>
        messCall(`/projects/${projectId ? projectId : store.state.projects.currentProjectId}/config/yclients`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putYclientsSettings: async (data) =>
        messCall(`/projects/${data.projectId ? data.projectId : store.state.projects.currentProjectId}/config/yclients`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getBitrixSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/bitrix`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putBitrixSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/bitrix`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getBitrixSmsSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/bitrix/sms`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    createBitrixSmsSettings: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/bitrix/sms/${id}`, { method: 'post' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteBitrixSmsSettings: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/bitrix/sms/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getAmoSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/amo`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putAmoSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/amo`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTasksSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/tasks`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putTasksSettings: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/tasks`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getSberSettings: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/config/sbercrm`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getTasksRegularSettings: async () =>
        messCall(`/tasks/autocreate/${store.state.projects.currentProjectId}`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    createTasksRegularSettings: async (data) =>
        messCall(`/tasks/autocreate/${store.state.projects.currentProjectId}`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    editTasksRegularSettings: async (idTask, data) =>
        messCall(`/tasks/autocreate/${store.state.projects.currentProjectId}/${idTask}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteTasksRegularSettings: async (idTask) =>
        messCall(`/tasks/autocreate/${store.state.projects.currentProjectId}/${idTask}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),


    getProjectUsers: async () =>
        messCall(`/projects/${store.state.projects.currentProjectId}/users`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postProjectUser: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/users`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putProjectUser: async (id, data) =>
      messCall(`/projects/${store.state.projects.currentProjectId}/users/${id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    putProjectUserRole: async (id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/users/${id}/project_role`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteProjectUser: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/users/${id}`, { method: 'delete' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getProjectDepartments: async () =>
      messCall(`/projects/${store.state.projects.currentProjectId}/departments`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    postProjectDepartment: async (data) =>
      messCall(`/projects/${store.state.projects.currentProjectId}/departments`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getYclientsStaff: async (integration) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/staffs`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getClientCategory: async (integration) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/client_categories`, { method: 'get' }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
}