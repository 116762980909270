import Api from '@/api/index.js'
import store from "@/store";
import { requestHandler, basePath } from '@/utils/apiHelpers';

class InternalKnowledgeService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.getCategories = async function () {
            return requestHandler(async () => {
                return await api.internalKnowledge.getCategories();
            })
        }

        this.getCategoryById = async function (id) {
            return requestHandler(async () => {
                return await api.internalKnowledge.getCategoryById(id);
            })
        }

        this.addCategory = async function (data) {
            return requestHandler(async () => {
                return await api.internalKnowledge.postCategory(data);
            })
        }

        this.editCategory = async function (data) {
            return requestHandler(async () => {
                return await api.internalKnowledge.putCategory(data);
            })
        }

        this.deleteCategory = async function (id) {
            return requestHandler(async () => {
                const res = await api.internalKnowledge.deleteCategory(id);

                store.commit('internalKnowledge/deleteCategory', id);
            })
        }

        this.searchArticles = async function (search) {
            return requestHandler(async () => {
                return await api.internalKnowledge.searchArticles(search);
            })
        }

        this.getArticleById = async function (id) {
            return requestHandler(async () => {
                return await api.internalKnowledge.getArticleById(id);
            })
        }

        this.addArticle = async function (data) {
            return requestHandler(async () => {
                const res = await api.internalKnowledge.postArticle(data);

                const addArticle = (categories, article) => {
                    for (let i = 0; i < categories.length; i++) {
                        if (categories[i].id === article.category_id) {
                            if (categories[i].articles) {
                                categories[i].articles.push(article);
                            } else {
                                categories[i].articles = [ article ];
                            }
                        }

                        if (categories[i].categories && categories[i].categories.length) {
                            addArticle(categories[i].categories, article);
                        }
                    }
                }

                addArticle(store.state.internalKnowledge.categories, res.data);

                return res;
            })
        }

        this.editArticle = async function (data) {
            return requestHandler(async () => {
                return await api.internalKnowledge.putArticle(data);
            })
        }

        this.deleteArticle = async function (id) {
            return requestHandler(async () => {
                return await api.internalKnowledge.deleteArticle(id);
            })
        }

        this.addArticleFile = async function (id, data) {
            return requestHandler(async () => {
                return await api.internalKnowledge.postArticleFile(id, data);
            })
        }

        this.deleteArticleFile = async function (id, data) {
            return requestHandler(async () => {
                return await api.internalKnowledge.deleteArticleFile(id, data);
            })
        }

        this.addArticleRating = async function (id, data) {
            return requestHandler(async () => {
                const { data: rating } = await api.internalKnowledge.putArticleRating(id, data);

                if (store.state.internalKnowledge.currentArticle.id) {
                    store.state.internalKnowledge.currentArticle.rating = rating;
                }
            })
        }

        this.getArticleComments = async function (id, data) {
            return requestHandler(async () => {
                return await api.internalKnowledge.getArticleComments(id);
            })
        }

        this.addArticleComment = async function (id, data) {
            return requestHandler(async () => {
                return await api.internalKnowledge.postArticleComment(id, data);
            })
        }
    }
}

export default new InternalKnowledgeService()