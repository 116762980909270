export default {
    namespaced: true,
    state: {
        funnels: [],
        currentFunnel: {},
        currentFunnelStages: [],
        filters: {
            stage_id: [],
            channel_id: [],
            date_from: null,
            date_to: null,
            group_by: 'day',
        },
        chosenEntity: {},
        chartData: {},
        stats: [],
        currentEmployee: {},
    },
}