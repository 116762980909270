import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    getConfigs: async () =>
        messCall(`/`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    getTableOptions: async (entity) =>
        messCall(`/${entity}/config`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    getTableContent: async (entity, page = 1, count = 50, sort = 'id', sort_type = 'desc', filters = '') => {
        const queryString = `/${entity}?page=${page}&count=${count}`
            + (sort ? `&sort=${sort}` : '')
            + (sort_type ? `&sort_type=${sort_type}` : '')
            + (filters ? `&${filters}` : '')

        return messCall(
            queryString,
            { method: 'get' },
            { 'X-Project': store.state.projects.currentProjectId },
            '/api/adm/aux'
        );
    },
    //transactions
    transactionsBot: async (data) =>
        messCall(`transaction_bot`, {method: 'post', format: 'form', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/'),


    getTableRow: async (entity, id, ) =>
        messCall(`/${entity}/${id}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    createTableContent: async (entity, data) =>
        messCall(`/${entity}/`, {method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    editTableContent: async (entity, id, data) =>
        messCall(`/${entity}/${id}`, {method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    uploadFile: async (entity, id, data) =>
        messCall(`/${entity}/${id}/files`, {method: 'post', format: 'form', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    deleteFile: async (entity, id, data) =>
        messCall(`/${entity}/${id}/files`, {method: 'delete', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    removeTableContent: async (entity, id) =>
        messCall(`/${entity}/${id}`, {method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm/aux'),
    getPermissions: async (forUser=false) =>
        messCall(`/config/permissions${forUser ? '?for_user=1' : ''}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),
    changelogApi: async () =>
        messCall(`/changelog`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app/api'),
    integrationsApi: async () =>
        messCall(`/documentation`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app/api'),

    freezeProject: async (id) =>
        messCall(`/projects/${id}/freeze`, {method: 'post'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),

    unfreezeProject: async (id) =>
        messCall(`/projects/${id}/unfreeze`, {method: 'post'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),

    createInstance: async (id, uuid) =>
        messCall(`/projects/${id}/channels/${uuid}/new_instance`, {method: 'post'}, {'X-Project': store.state.projects.currentProjectId}, '/api/adm'),

    tariffRecalc: async (id) =>
        messCall(`/projects/${id}/tarif_recalc`, {method: 'post'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

}


