export default {
    namespaced: true,
    state: {
        filters: {
            search: '',
            type: 'whatsapp',
        },
        mailings: [],
        currentMailing: {
            name: '',
            message: '',
            channel_id: '',
            channel: '',
            file: []
        },
        filesForDelete: [],
        usersLists: [],
        newUsersList: {
            name: '',
            type: 'channel'
        },
        isNewListNameEmpty: false,
        channelError: '',
        filtersUsersLists: {
            filter: '',
            count: 5,
            page: 1,
            lastPage: 0,
        },
        isMailingSaving: false,
        vkOrWabaNotifyTemplates: [],
    },
    actions: {},
    mutations: {
        setMailings(state, mailings) {
            state.mailings = mailings;
        },
    },
    getters: {},
}