import store from "@/store/index";

export default {
    namespaced: true,
    state: {
        balance: 0,
        paymentSum: 0,
        fullProjects: [],
        projectTariff: {},
        chosenPeriod: {
            id: 1,
            discount: 0,
            days: 30,
        },
        chosenProjects: [],
        chosenProject: {},
        connectedServices: [],
        connectedServicesTariffs: [],
        transactions: [],
        partnerProjects: [],
        chosenProjectType: {id: 'my', title: ''},
        connectChannel: null,
    },
    actions: {
        ws_projectUpdated ({state}, data) {
            console.log(data)
        },
        ws_amo_api_authCreated({state}) {
            state.chosenProject.integrations.amocrm = {
                active: true,
                connected: 1
            }
        },
        ws_channelUpdated({commit, state, dispatch}, data){
            const channel = data.payload

            if (channel.uuid === state.connectChannel?.uuid) {
                state.connectChannel.status = channel
            }

            if (channel.uuid === store.state.channels.currentChannel.uuid) {
                store.state.channels.currentChannel = channel;
            }
        }
    },
    mutations: {
        chooseProject({getters, state}, elem){
            store.state.tariff.chosenProject = elem;
            if (elem.channels) {
                store.state.tariff.connectedServices = elem.channels;
                store.state.tariff.connectedServices = store.state.tariff.connectedServices.map(service => {
                    const changedElement = store.getters["tariff/channelsDetail"].find(channel => channel.key === service.type);
                    return { ...service, ...changedElement };
                });
                const servicesToCheck = { ...elem.integrations, ...elem.services };
                const others = store.getters["tariff/otherDetail"];
                Object.entries(servicesToCheck).forEach(([key, value]) => {
                    const needleElem = others.find(o => o.key === key)
                    if (value && key !== 'operators' && key !== 'yclients') {
                        store.state.tariff.connectedServices.push(
                            {...needleElem, active: 1, uuid: key, type: key}
                        );
                    }
                    if (key === 'operators') {
                        store.state.tariff.connectedServices.push({...needleElem, name: store.state.global.language === 'ru' ? 'Оператор' : 'Operator', tariff: store.state.tariff.chosenProject.services.operators, count: value, active: 1, uuid: key, type: key})
                    }
                    if (key === 'yclients') {
                        for(let keyc in value) {
                            let tariffValue = value[keyc].tarif;
                            let nameValue = value[keyc].name;

                            store.state.tariff.connectedServices.push(
                                {
                                    ...value[keyc],
                                    idYC: Number(keyc),
                                    tariff: tariffValue,
                                    name: nameValue ? nameValue : `${store.state.global.language === 'ru' ? 'Филиал' : 'Branch'} ${document.domain === 'wahelp.me' || document.domain === 'message.help' ? 'Altegio' : 'YCLIENTS'} ${keyc}`,
                                    type: `yc_${tariffValue}`
                                }
                            )
                        }
                    }
                });
            }
        },
        setChosenPeriod(state, period) {
            state.chosenPeriod = period;
        },
        addChosenProject(state, project) {
            if(!state.fullProjects.find(item=> item.id === project.id)) {
                state.fullProjects.unshift(project);
            }
            if(!state.chosenProjects.find(item=> item.id === project.id)) {
                state.chosenProjects.unshift(project);
            }
        },
        editProjectInFullProjects(state, project) {
            const projectIdx = state.fullProjects.findIndex(p => p.id == project.id);
            state.fullProjects[projectIdx] = project;
        },
        setTransactions(state, transactions) {
            state.transactions = transactions;
        }
    },
    getters: {
        channelsDetail: () => {

            const ru = {
                whatsapp: 'Серый',
                waba: 'Business API',
                telegram: 'Номерной',
                telegram_bot: 'Бот',
                viber: 'Бот',
                sms: 'Модуль'
            }
            const en = {
                whatsapp: 'Grey',
                waba: 'Business API',
                telegram: 'Numbered',
                telegram_bot: 'Bot',
                viber: 'Bot',
                sms: 'Module'
            }
            const pt = {
                whatsapp: 'Cinza',
                waba: 'Business API',
                telegram: 'Número',
                telegram_bot: 'Bot',
                viber: 'Bot',
                sms: 'Módulo'
            }
            const es = {
                whatsapp: 'Business',
                waba: 'API',
                telegram: 'Número',
                telegram_bot: 'Bot',
                viber: 'Bot',
                sms: 'Módulo'
            }

            const languages = { ru, en, pt, es }
            const currentLanguage = languages[store.state.global.language]

            const keys = ['whatsapp', 'waba', 'telegram', 'telegram_bot', 'viber', 'sms']
            return keys.map(key => ({
                key,
                tariff: currentLanguage[key] || en[key]
            }))
        },
        otherDetail: () => {
            return [
                {name: store.state.tariff.chosenProject.kommo ? 'Kommo' : 'amoCRM', key: 'amocrm'},
                {name: 'Bitrix24', key: 'bitrix24'},
                {name: store.state.global.language === 'ru' ? 'Виджет на сайт' : 'Website widget', key: 'widget'},
                {name: store.state.global.language === 'ru' ? 'Рассылки' : 'Mailings', key: 'mass_sending', tariff: store.state.global.language === 'ru' ? 'Модуль' : 'Module'},
                {name: store.state.global.language === 'ru' ? 'Задачи' : 'Tasks', key: 'tasks', tariff: store.state.global.language === 'ru' ? 'Модуль' : 'Module'},
                {name: store.state.global.isMessageHelp ? store.state.global.language === 'ru' ? 'Message.Help.Воронка' : 'Message.Help.Funnel' : 'Wahelp.Воронка', key: 'crm', tariff: store.state.global.language === 'ru' ? 'Модуль' : 'Module'},
            ];
        }
    },
}