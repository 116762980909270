import { messCall } from "@/utils/apiHelpers";
import store from "@/store/index";

export default {
    //waba
    getTemplatesWaba: async (projectId, uuid, queryStr) =>
        messCall(`/projects/${projectId}/channels/${uuid}/waba_templates${queryStr}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTemplateWaba: async (projectId, uuid, id) =>
        messCall(`/projects/${projectId}/channels/${uuid}/waba_templates/${id}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteTemplateWaba: async (projectId, uuid, id) =>
        messCall(`/projects/${projectId}/channels/${uuid}/waba_templates/${id}`, {method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    createTemplateWaba: async (projectId, uuid, data, format) =>
        messCall(`/projects/${projectId}/channels/${uuid}/waba_templates`, { method: 'post', format: format, data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    editTemplateWaba: async (projectId, uuid, id, data) =>
        messCall(`/projects/${projectId}/channels/${uuid}/waba_templates/${id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTemplatesForImportWaba: async (projectId, uuid) =>
        messCall(`/projects/${projectId}/channels/${uuid}/waba_templates/import`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    importTemplateWaba: async (projectId, uuid, data) =>
        messCall(`/projects/${projectId}/channels/${uuid}/waba_templates/import`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),


    //menu
    getTemplatesMenu: async (projectId, uuid, queryStr) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages${queryStr}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTemplatesMenuTree: async (projectId, uuid, parent_id = '') =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages/tree?parent_id=${parent_id}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTemplateMenu: async (projectId, uuid, id) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages/${id}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getFunctionsMenu: async (projectId, uuid) =>
        messCall(`/projects/${projectId ? projectId : store.state.projects.currentProjectId}/channels/${uuid}/bot_messages/functions`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getParentsMenu: async (projectId, uuid) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages/parent_list`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteTemplateMenu: async (projectId, uuid, id) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages/${id}`, {method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    createTemplateMenu: async (projectId, uuid, data) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages`, { method: 'post', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    editTemplateMenu: async (projectId, uuid, id, data) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages/${id}`, { method: 'put', data }, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteFileTemplateMenu: async (projectId, uuid, id, data) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages/${id}/files`, {method: 'delete', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    addFileTemplateMenu: async (projectId, uuid, id, data) =>
        messCall(`/projects/${projectId}/channels/${uuid}/bot_messages/${id}/files`, {method: 'post', format: 'form',  data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),



    //YC/AG, SBER
    getTemplatesWithType: async (integration, type, queryStr) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/templates/${type}${queryStr}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTemplateWithType: async (data) =>
        messCall(`/projects/${data.projectId}/integrations/${data.integration}/templates/${data.type}/${data.id}`, { method: 'get'}, {'X-Project': data.projectId}, '/api/app'),

    createTemplateWithType: async (integration, type, data, projectId) =>
        messCall(`/projects/${projectId}/integrations/${integration}/templates/${type}`, { method: 'post', data}, {'X-Project': projectId}, '/api/app'),
    deleteTemplateWithType: async (integration, type, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/integrations/${integration}/templates/${type}/${id}`, { method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    editTemplateWithType: async (integration, type, id, data, projectId = '') =>
        messCall(`/projects/${projectId ? projectId : store.state.projects.currentProjectId}/integrations/${integration}/templates/${type}/${id}`, { method: 'put', data}, {'X-Project': projectId ? projectId : store.state.projects.currentProjectId}, '/api/app'),

    addFileToTemplateWithType: async (integration, type, id, data, projectId) =>
        messCall(`/projects/${projectId}/integrations/${integration}/templates/${type}/${id}/files`, { method: 'post', format: 'form', data}, {'X-Project': projectId}, '/api/app'),
    removeFileFromTemplateWithType: async (data) =>
        messCall(`/projects/${data.projectId}/integrations/${data.integration}/templates/${data.type}/${data.id}/files`, { method: 'delete', data: data.data}, {'X-Project': data.projectId}, '/api/app'),

    getStaffFromTemplateWithType: async (data) =>
        messCall(`/projects/${data.projectId ? data.projectId : store.state.projects.currentProjectId}/integrations/${data.integration}/staffs`, { method: 'get', }, {'X-Project': data.projectId ? data.projectId : store.state.projects.currentProjectId}, '/api/app'),
    getClientCategoriesFromTemplateWithType: async (data) =>
        messCall(`/projects/${data.projectId ? data.projectId : store.state.projects.currentProjectId}/integrations/${data.integration}/client_categories`, { method: 'get', }, {'X-Project': data.projectId ? data.projectId : store.state.projects.currentProjectId}, '/api/app'),
    getServicesFromTemplateWithType: async (data) =>
        messCall(`/projects/${data.projectId ? data.projectId : store.state.projects.currentProjectId}/integrations/${data.integration}/${data.title}`, { method: 'get', }, {'X-Project': data.projectId ? data.projectId : store.state.projects.currentProjectId}, '/api/app'),

    sendTestMessage: async (projectId, data) =>
        messCall(`/projects/${projectId}/integrations/yclients/test_sending`, { method: 'post', data}, {'X-Project': projectId}, '/api/app'),


    //Кросс рассылки
    getCrossSalesList: async (projectId, filters = '') =>
        messCall(`/projects/${projectId}/mass_mailings/cross_sales/${filters}`, { method: 'get'}, {'X-Project': projectId}, '/api/app'),
    getCrossSaleTemplate: async (projectId, id) =>
        messCall(`/projects/${projectId}/mass_mailings/cross_sales/${id}/`, { method: 'get'}, {'X-Project': projectId}, '/api/app'),
    editCrossSaleTemplate: async (id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/${id}/`, { method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    previewCrossSaleTemplate: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/actions/${id}/preview/`, { method: 'put'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getCrossSaleTemplateConfig: async (id, index, condition= '') =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/config/${id}/?index=${index}&condition=${condition}`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    createCrossSale: async (data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/`, { method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteCrossSale: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/${id}/`, { method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    startCrossSale: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/actions/${id}/send/`, { method: 'put'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    stopCrossSale: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/actions/${id}/cancel/`, { method: 'put'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    restartCrossSale: async (id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/mass_mailings/cross_sales/actions/${id}/restartPreview/`, { method: 'put'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),



    //Vk
    getTemplatesVkNotify: async (uuid, queryStr) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/vk_notify${queryStr}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    getTemplateVkNotify: async (uuid, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/vk_notify/${id}`, {method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    deleteTemplateVkNotify: async (uuid, id) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/vk_notify/${id}`, {method: 'delete'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    createTemplateVkNotify: async (uuid, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/vk_notify`, {method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),
    editTemplateVkNotify: async (uuid, id, data) =>
        messCall(`/projects/${store.state.projects.currentProjectId}/channels/${uuid}/vk_notify/${id}`, {method: 'put', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    //copy templates
    getFullTemplates: async (project, type) =>
        messCall(`/projects/${project}/integrations/yclients/templates/${type}/full/`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    getFullCrossTemplates: async (project) =>
        messCall(`/projects/${project}/mass_mailings/cross_sales/full/`, { method: 'get'}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    copyTemplates: async (data) =>
        messCall(`/copy_templates`, { method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),

    copyTemplatesChannels: async (data) =>
        messCall(`/copy_templates_channels`, { method: 'post', data}, {'X-Project': store.state.projects.currentProjectId}, '/api/app'),


}
