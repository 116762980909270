<template>
  <div :style="{ width: width }">

    <div class="input__title-wrapper" v-if="label || explanation">
      <label v-if="label" class="label" :class="{disabled}">
        {{ label }}
      </label>

      <tooltip-btn v-if="explanation" :text="explanation" :width="widthExplanation" hover>
        <img :style="{width: '16px', height: '16px', padding: '0'}" src="@/assets/img/icons/question-outlined.svg" alt="question mark">
      </tooltip-btn>
    </div>

    <div
        class="input"
        :class="{'search': search}"
        @click.stop.prevent>
      <svg class="svg-loupe" v-if="search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
           fill="none">
        <path
            d="M15.7574 14.7219L11.568 10.5324C12.4851 9.38606 12.954 8.04168 12.9749 6.49928C12.9332 4.66508 12.2975 3.13311 11.0677 1.90337C9.83797 0.673618 8.306 0.0379021 6.4718 -0.00378418C4.6376 0.0379021 3.10563 0.673618 1.87588 1.90337C0.666981 3.13311 0.0416863 4.66508 0 6.49928C0.0416863 8.33348 0.677403 9.86545 1.90715 11.0952C3.11605 12.3249 4.6376 12.9607 6.4718 13.0023C8.03504 12.9815 9.37942 12.5125 10.505 11.5954L14.6944 15.7849C14.882 15.9308 15.0696 16.0038 15.2572 16.0038C15.4656 16.0038 15.6428 15.9308 15.7887 15.7849C16.0805 15.4306 16.0701 15.0762 15.7574 14.7219ZM1.50071 6.49928C1.54239 5.08195 2.03221 3.90431 2.97015 2.96637C3.90809 2.02842 5.08573 1.53861 6.50307 1.49692C7.9204 1.53861 9.09804 2.02842 10.036 2.96637C10.9739 3.90431 11.4637 5.08195 11.5054 6.49928C11.4637 7.91662 10.9739 9.09426 10.036 10.0322C9.09804 10.9701 7.9204 11.46 6.50307 11.5016C5.08573 11.46 3.90809 10.9701 2.97015 10.0322C2.03221 9.09426 1.54239 7.91662 1.50071 6.49928Z"
            fill="#C2C2C2"/>
      </svg>

      <input
          :value="modelValue || value"
          @input="updateInput($event.target.value)"
          @change="onChange"
          @focus="onFocus"
          @blur="onBlur"
          @keyup.enter="onKeyupEnter"
          :type="type"
          :name="name"
          :id="id"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :maxlength="maxlength"
          :placeholder="placeholder"
          :autofocus="autofocus"
          :required="required"
          :class="[size, error ? 'error' : '']"
      >

      <svg class="svg-cross" v-if="!disabled && search && modelValue && modelValue.length" @click="updateInput('')"
           xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M13.8125 13.8125C13.5375 14.0625 13.2625 14.0625 12.9875 13.8125L8 8.8625L3.0125 13.8125C2.7375 14.0625 2.4625 14.0625 2.1875 13.8125C1.9375 13.5375 1.9375 13.2625 2.1875 12.9875L7.1375 8L2.1875 3.0125C1.9375 2.7375 1.9375 2.4625 2.1875 2.1875C2.4625 1.9375 2.7375 1.9375 3.0125 2.1875L8 7.1375L12.9875 2.1875C13.2625 1.9375 13.5375 1.9375 13.8125 2.1875C14.0625 2.4625 14.0625 2.7375 13.8125 3.0125L8.8625 8L13.8125 12.9875C14.0625 13.2625 14.0625 13.5375 13.8125 13.8125Z"
            fill="#C2C2C2"/>
      </svg>
    </div>

    <span v-if="error && typeof error !== 'boolean'" class="str-error">{{ error }}</span>
  </div>
</template>

<script>
import TooltipBtn from "@/components/UI/TooltipBtn.vue";

export default {
  name: "VueInput",
  components: {TooltipBtn},
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    autocomplete: {
      type: String,
    },
    id: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'text'
    },
    maxlength: {
      type: Number,
      default: 524288
    },
    search: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    explanation:{
      type: String,
    },
    widthExplanation:{
      type: String,
    },
    //size: small, medium, large
    size: {
      type: String,
      default: "medium"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, Boolean],
    },
    value: {
      type: [String, Number],
    }
  },
  emits: [
    'update:modelValue', 'on-change', 'on-input', 'clear', 'on-focus', 'on-blur',
    'on-keyup-enter',
  ],
  methods: {
    onChange(e) {
      this.$emit('on-change', e.target.value)
    },
    onFocus() {
      this.$emit('on-focus');
    },
    onBlur(){
      this.$emit('on-blur')
    },
    onKeyupEnter(){
      this.$emit('on-keyup-enter')
    },
    updateInput(value) {
      this.$emit('update:modelValue', value);
      this.$emit('on-input', value);

      if (!value) {
        this.$emit('clear');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.label, .str-error {
  color: var(--default-text);
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;

  &.disabled {
    color: #969696;
  }
}

.str-error {
  margin-top: 4px;
  color: #EE4C4C;
}

.input{
  &__title-wrapper{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
  }

  &__title{
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  &__img-mark{
    margin-top: 3px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

input {
  border: 1px solid #DBDBDB;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Manrope';
  overflow: hidden;
  color: var(--default-text);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  background: var(--main-background);
  transition: border-color 0.2s ease-out;

  &[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
  }

  &:hover {
    outline: none;
    border: 1px solid var(--brand);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--brand);
  }

  &.error {
    border: 1px solid #F29C9C;
  }

  &[disabled] {
    color: #969696;
    background: var(--grey-background);
    cursor: default;
    border: 1px solid #DBDBDB;
  }
}

.small {
  border-radius: 10px;
  padding: 8px 10px;
}

.medium {
  border-radius: 12px;
  padding: 10px 12px;
}

.large {
  border-radius: 14px;
  padding: 14px 16px;
}

.input {
  width: 100%;
}

.search {
  position: relative;

  input {
    width: 100%;
    padding-left: 40px;
    padding-right: 30px
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .svg-loupe {
    left: 14px;
  }

  .svg-cross {
    right: 14px;
    cursor: pointer;
  }
}
</style>