import Api from '@/api/index.js'
import store from "@/store";
import { requestHandler, basePath } from '@/utils/apiHelpers';
import axios from "axios";
import router from "@/router";

class AuthService {
    constructor(array, iteratee) {
        const api = new Api({ basePath });

        this.login = async function (data) {
            return requestHandler(async () => {
                const res = await api.auth.login(data);

                store.commit('auth/setToken', res.data.token);
            })
        }

        this.restorePassword = async function (data) {
            return requestHandler(async () => {
                return await api.auth.restorePassword(data);
            })
        }

        this.register = async function (data) {
            return requestHandler(async () => {
                return await api.auth.register(data);
            })
        }

        this.getUser = async function (route) {
            return requestHandler(
                async () => {
                    const response = await api.auth.getUser();

                    store.commit('auth/setUser', response.data);

                    if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/channels')){
                        router.push({name: 'channels-amo'})
                    }else if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/amo_link_managers')){
                        router.push({name: 'link-managers-amo'})
                    }else if (route) {
                        router.push(route)
                    }
                },
                async () => {
                    store.commit('auth/setLoggedIn', false);

                    if (route && route.meta.requiresNoAuth) {
                        router.push(route);
                    } else {
                        if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/channels')){
                            router.push({ name: "Login", query: {redirectTo: '/app/channels-amo'} });
                        }else if(store.state.global.isFramed && location.pathname?.includes('amo_iframe/amo_link_managers')){
                            router.push({ name: "Login", query: {redirectTo: '/app/link-managers-amo'} });
                        }else{
                            router.push({ name: "Login", query: { redirectTo: location.pathname } });
                        }
                    }
                }
            )
        }

        this.logout = async function () {
            return requestHandler(async () => {
                store.state.tariff.isActiveLoaderLoadingProjects = true

                await api.auth.logout();

                store.state.auth.userMarketplace = null
                localStorage.removeItem('query_from_marketplace');

                store.commit('auth/setUser', {});
                store.commit('auth/setLoggedIn', false);

                store.commit('projects/clearProject');
                store.commit('projects/clearAllProjects');

                window.location.href = `${location.origin}/app/login/`;
            })
        }

        this.confirmPhoneOrEmail = async function (value) {
            return requestHandler(async () => {
                const key = Object.keys(value)[0];
                const val = value[key];

                return await api.auth.confirmPhoneOrEmail(key, val);
            })
        }

        this.changePopupStatus = async function () {
            return requestHandler(async () => {
                return await api.auth.changePopupStatus();
            })
        }

        this.checkCode = async function (data) {
            return requestHandler(async () => {
                return await api.auth.checkCode(data);
            })
        }

        this.autoRegister = async function (data) {
            return requestHandler(async () => {
                const res = await api.auth.autoRegister(data);

                store.commit('auth/setUser', {
                    data: res.data.user,
                    meta: null,
                    success: true,
                });

                store.commit('auth/setToken', res.data.token.token);
            })
        }

        this.changeUserLocation = async function (data) {
            return requestHandler(async () => {
                return await api.auth.changeUserLocation(data);
            })
        }

        this.getUserLocation = async function () {
            return requestHandler(async () => {
                return await api.auth.getUserLocation();
            })
        }

        this.getUserDataFromMarketplace = async function (data) {
            return requestHandler(async () => {
                return await api.auth.getUserDataFromMarketplace(data);
            })
        }

        this.connectBranchToProject = async function (data) {
            return requestHandler(async () => {
                return await api.auth.connectBranchToProject(data);
            })
        }

        this.changeUser = async function (data) {
            return requestHandler(async () => {
                const res = await api.auth.changeUser(data);

                store.commit('auth/setUser', {
                    data: res.data,
                    meta: null,
                    success: true,
                });
            })
        }

        this.changePassword = async function (data) {
            return requestHandler(async () => {
                return await api.auth.changePassword(data);
            })
        }

        this.getUserSessions = async function (data) {
            return requestHandler(async () => {
                return await api.auth.getUserSessions(data);
            })
        }

        this.deleteUserSession = async function (data) {
            return requestHandler(async () => {
                return await api.auth.deleteUserSession(data);
            })
        }

        this.getOpenIdUrl = async function (data) {
            return requestHandler(async () => {
                return await api.auth.getOpenIdUrl(data);
            })
        }

        this.openIdRegistration = async function (data) {
            return requestHandler(async () => {
                return await api.auth.openIdRegistration(data);
            })
        }

        this.openIdLogin = async function (data) {
            return requestHandler(async () => {
                return await api.auth.openIdLogin(data);
            })
        }

        this.getOpenIdProviders = async function (data) {
            return requestHandler(async () => {
                return await api.auth.getOpenIdProviders(data);
            })
        }

        this.deleteOpenIdProvider = async function (data) {
            return requestHandler(async () => {
                return await api.auth.deleteOpenIdProvider(data);
            })
        }

    }
}

export default new AuthService()