import store from "@/store";

let webSocketsService = {};
let ws = null; // Глобальная ссылка на WebSocket-соединение

document.cookie = 'Authorization=' + store.getters["auth/getToken"] + '; path=/';

webSocketsService.install = function (Vue, options) {
    Vue.config.globalProperties.$webSocketsConnect = () => {

        if (!ws || ws.readyState === WebSocket.CLOSED) {
            ws = new WebSocket(process.env.VUE_APP_WSS_URL);

            ws.onopen = () => {
                ws.send(
                    JSON.stringify({
                        action: "auth",
                        token: localStorage.getItem("AuthToken"),
                    })
                );
                ws.send(
                    JSON.stringify({
                        action: "chat_init",
                        chat_id: localStorage.getItem("Project"),
                    })
                );
            };

            ws.addEventListener('message', handleNotification)

            //ws.onmessage = (event) => {
            //    handleNotification(event);
            //};

            ws.onclose = () => {
                Vue.config.globalProperties.$webSocketsConnect();
            };

            ws.onerror = (error) => {
                console.error("WebSocket error:", error);
                ws.close()
            };
        }
    };

    Vue.config.globalProperties.$webSocketsDisconnect = () => {
        if (ws) {
            console.log("Closing WebSocket connection...");
            ws.close();
            ws = null;
        }
    };

    Vue.config.globalProperties.$webSocketsSend = (data) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(data));
        } else {
            console.error("WebSocket is not open. Cannot send data.");
        }
    };

    function handleNotification(event) {
        try {
            const data = JSON.parse(event.data);
            if (typeof data !== "object" || !data.action) throw "No action";
            // console.log("Notification received:", data);

            const actionName = data.action.indexOf(".") !== -1
                ? `tariff/ws_${data.action.split(".")[0]}${
                    data.action.split(".")[1].charAt(0).toUpperCase() +
                    data.action.split(".")[1].slice(1)
                }`
                : `tariff/ws_${data.action}`;

            if (options.store._actions[actionName]) {
                options.store.dispatch(actionName, data);
            }
        } catch (e) {
            console.error(e);
        }
    }
};

export { ws }; // Экспортируем WebSocket для использования в других компонентах
export default webSocketsService;
