<template>
  <div class="tooltip">
    <Popper
        class="popper-wrapper"
        :class="[placement, iconPosition, error ? 'error' : '']"
        :hover="hover"
        :placement="placement"
        offsetDistance="5"
        open-delay="1000"
    >
      <slot></slot>

      <template v-if="!disabled && (!$store.state.global.isMobile || $store.state.global.isFramed)" #content>
        <div class="tooltip-sm" :style="{width: width}">
          <span> {{ text }} </span>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script>
import Popper from "vue3-popper";

export default {
  name: "tooltip-btn",
  components: { Popper },
  props: {
    width: String,
    hover: {
      type: Boolean,
      required: false,
    },
    text: {
      required: false,
    },
    position: {
      type: String,
      required: false,
    },
    iconPosition: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    error: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    placement() {
      if (this.position) {
        return this.position;
      } else if (this.$store.state.global.isMobile) {
        return "top-end";
      }

      return "bottom-start";
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  display: flex;
  cursor: pointer;

  &-sm {
    max-width: 500px;
    background: var(--main-background);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 8px 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--default-text);
    cursor: pointer;

    span {
      color: var(--default-text) !important;
      white-space: break-spaces;
    }
  }
}

div .popper-wrapper {
  margin: 0 !important;
  border: none !important;
  width: 100% !important;

  &.error {
    cursor: not-allowed;
  }

  &.center {
    gap: 4px;
    width: fit-content !important;
    margin: 0 auto !important;
  }

  div {
    display: flex;
    align-items: center;
    @media (max-width: 300px) {
      justify-content: center;
    }
  }
}
</style>
